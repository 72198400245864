<div *ngIf="report" class="flex-row justify-between hidden mb-2 bg-white border-b rounded-lg md:flex border-light-grey">
	<div class="flex flex-row w-full px-1 py-3">
		<div class="flex flex-row justify-between">
			<div class="flex px-3 flex-column w-28 md:w-48 lg:w-56" [ngClass]="{'text-button-color': report?.type === 'yearly-report'}">
				<span class="text-sm" translate="reports.type.title"></span>
				<span class="text-sm font-semibold">
					<span *ngIf="report?.type === 'monthly-report'" translate="reports.monthly"></span>
					<span *ngIf="report?.type === 'yearly-report'" translate="reports.yearly"></span>
				</span>
			</div>
		</div>
		<div class="flex flex-row justify-between">
			<div class="flex w-20 px-3 flex-column md:w-48 lg:w-56" *ngIf="report?.type === 'monthly-report'">
				<span class="text-sm" translate="reports.month"></span>
				<span class="text-sm font-semibold">{{ report?.properties?.ReportStartDate | localDate : 'MMMM' }}</span>
			</div>
		</div>
		<div class="flex flex-row justify-between">
			<div class="flex w-full px-3 flex-column lg:w-56" [ngClass]="{'text-button-color': report?.type === 'yearly-report'}">
				<span class="text-sm" translate="reports.year"></span>
				<span class="text-sm font-semibold">{{ report?.properties?.ReportStartDate | localDate : 'yyyy' }}</span>
			</div>
		</div>
		<div class="flex flex-row justify-end w-full text-right">
			<div class="flex justify-center px-3 flex-column w-30">
				<div (click)="getReport($event, report.id)">
					<app-svg-icon class="cursor-pointer text-button-color hover:text-button-color-hover" [iconName]="'file-download.svg'" [iconId]="'file-download'"></app-svg-icon>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="report" class="flex flex-col md:hidden text-almost-black" [@expandRows]="report.expanded ? 'expanded' : 'collapsed'">
	<div (click)="report.expanded = !report.expanded" [@fadeInOut]>
		<div class="flex flex-row justify-between text-almost-black">
			<p class="w-full text-sm" [ngClass]="{'text-button-color': report?.type === 'yearly-report'}">{{ report?.properties?.ReportStartDate | localDate : 'dd MMM yyyy'}}</p>
			<div class="flex flex-row justify-end w-full text-right">
				<div (click)="getReport($event, report.id)">
					<app-svg-icon class="text-button-color hover:text-button-color-hover" [iconName]="'file-download.svg'" [iconId]="'file-download'"></app-svg-icon>
				</div>
			</div>
		</div>
		<div class="flex flex-row justify-between mt-2 text-almost-black">
			<span class="font-bold">
				<span *ngIf="report?.type === 'monthly-report'" translate="reports.monthly"></span>
				<span *ngIf="report?.type === 'yearly-report'" class="text-button-color" translate="reports.yearly"></span>
			</span>
		</div>
		<div *ngIf="report.expanded  && report?.type === 'monthly-report'" class="flex flex-row justify-between mt-2 text-almost-black expanded-row">
			<span class="font-bold" translate="reports.month"></span>
			<span class="text-sm text-right">
				<span>{{ report?.properties?.ReportStartDate | localDate : 'MMMM' }}</span>
			</span>
		</div>
		<div *ngIf="report.expanded" class="flex flex-row justify-between mt-2 text-almost-black expanded-row">
			<span class="font-bold" translate="reports.year"></span>
			<span class="text-sm text-right">
				<span>{{ report?.properties?.ReportStartDate | localDate : 'yyyy' }}</span>
			</span>
		</div>
	</div>
</div>
