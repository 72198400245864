import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

export const ssnDanishValidator: ValidatorFn[] = [Validators.required, Validators.minLength(10), danishValidator];

export function danishValidator(control: AbstractControl): Record<string, boolean> | null {
	if (environment.production && !isValid(control.value)) {
		return { distinct: true };
	}
	return null;
}

function isValid(cpr: string): boolean {
	if (!cpr) return false;
	cpr = cpr.replace(/[^\d]/g, '');
	if (cpr.length !== 10) return false;

	return modulo11(cpr) === 0;
}

const multipliers = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];

function modulo11(cpr) {
	return sumProduct(cpr.split(''), multipliers) % 11;
}

function sumProduct(a, b) {
	return a.reduce(function (sum, digit, i) {
		return sum + digit * b[i];
	}, 0);
}
