<div class="flex flex-col items-center justify-center w-full max-w-sm px-6 py-6 mb-6 rounded-lg bg-secondary-100">
	<div class="text-lg font-bold text-center text-primary-700" translate="onboarding.contact.title"></div>
	<a
		class="underline link"
		href="tel:+46850131100"
		angularticsCategory="contact-action"
		angularticsAction="onboarding.phone"
		angularticsLabel="+46850131100"
		angulartics2On="click">
		<span class="text-primary-700">{{ 'contact-card.contact.telephone' | translate : { phone: supportPhoneNumber } }}</span>
	</a>
	<a
		class="underline link"
		href="mailto:support@froda.se"
		angularticsCategory="contact-action"
		angularticsAction="onboarding.email"
		angularticsLabel="support@froda.se"
		angulartics2On="click">
		<span class="text-primary-700">{{ 'contact-card.contact.email' | translate : { email: 'support@froda.se' } }}</span>
	</a>
</div>
