<div *ngIf="reports?.length > 0" class="flex flex-col flex-1 lg:px-3 min-h-128">
	<div class="hidden pb-4 md:flex lg:items-center lg:justify-between tabular-nums">
		<div class="flex-1 mb-4 overflow-y-auto">
			<ng-container *ngFor="let report of reports; let i = index">
				<app-report-item [report]="report"></app-report-item>
			</ng-container>
		</div>
	</div>
	<div class="flex-1 w-full md:hidden">
		<div *ngFor="let report of reports; let i = index" class="flex flex-col border-b border-light-grey">
			<div class="flex flex-col w-full p-4 cursor-pointer">
				<app-report-item [report]="report"></app-report-item>
			</div>
		</div>
	</div>
</div>
<div *ngIf="reports?.length === 0" class="flex-1 h-64 bg-white lg:rounded-lg lg:h-128 lg:shadow lg:border-none">
	<div class="flex flex-col items-center justify-center w-full h-full max-w-xs mx-auto text-center">
		<p class="mb-2 font-semibold" translate="reports.empty.title"></p>
		<p translate="reports.empty.pre"></p>
	</div>
</div>

<div *ngIf="loading" class="flex-1 h-64 lg:h-128">
	<div class="flex flex-col items-center justify-center w-full h-full max-w-xs mx-auto text-center">
		<app-loader></app-loader>
	</div>
</div>
