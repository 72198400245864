import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { AnalyticsService } from 'app/services/analytics.service';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnDestroy {
	@ViewChild('buttonId', { static: true }) buttonEl: ElementRef;
	@Input() type?: 'primary' | 'secondary' | 'outline' | 'small' = 'primary';
	@Input() outlineVersion?: 'light' | 'dark' = 'dark';
	@Input() disabled?: boolean;
	@Input() loading?: boolean = false;
	@Input() contentPosition?: 'center' | 'stretched' | 'center-right' | 'left' = 'center';
	// iconId is used only for icons from assets/vectors/ path,
	// otherwise use app-svg-icon directly in button content or move the icon to assets/vectors/ and use iconId
	@Input() iconId?: string = '';
	@Input() iconWidth?: string = '24';
	@Input() iconHeight?: string = '24';
	@Input() trackAction?: string = '';
	@Input() trackCat?: string = '';

	@Output() action = new EventEmitter<void>();

	private buttonClicked = new Subject<void>();
	protected readonly destroy$ = new Subject();

	@ViewChild('contentWrapper') content: ElementRef;
	@HostListener('click') onClick() {
		if (!this.disabled) {
			this.buttonClicked.next();
		}
	}

	constructor(private analyticsService: AnalyticsService) {}

	ngOnInit() {
		this.buttonClicked.pipe(throttleTime(200), takeUntil(this.destroy$)).subscribe(() => {
			const content = this.content.nativeElement.textContent;
			if (this.trackAction || this.trackCat)
				this.analyticsService.registerEvent(this.trackCat, this.trackAction, content);
			this.action.emit();
		});
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
