import { Component, OnInit } from '@angular/core';
import { SUPPORT_PHONE_NUMBER } from 'app/my-froda/my-froda.constants';

@Component({
	selector: 'froda-splash-layout',
	templateUrl: './splash-layout.component.html',
	styleUrls: ['./splash-layout.component.scss'],
})
export class SplashLayoutComponent implements OnInit {
	supportPhoneNumber = SUPPORT_PHONE_NUMBER;

	constructor() {}

	ngOnInit() {}
}
