import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WhiteLabelClientSettingsService } from '@services';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { colorify } from 'lottie-colorify';
import Loading_Big from '../../../../assets/animations/loader/Loading_Big.json';
import Loading_Mid from '../../../../assets/animations/loader/Loading_Mid.json';
import Loading_Small from '../../../../assets/animations/loader/Loading_Small.json';
import { TailwindThemeService } from 'app/tailwind-theme-config/tailwind-theme.service';

@Component({
	selector: 'app-splash-loader',
	templateUrl: './splash-loader.component.html',
	styleUrls: ['./splash-loader.component.scss'],
})
export class SplashLoaderComponent implements OnInit {
	@Input() size = 'sm';
	options: AnimationOptions;
	@Output() animationCreated = new EventEmitter();

	constructor(
		private tailwindThemeService: TailwindThemeService,
		private clientSettingsService: WhiteLabelClientSettingsService
	) {}

	ngOnInit(): void {
		const key = this.clientSettingsService.getSettings().key;
		const theme = this.tailwindThemeService.getThemeByName(key);

		switch (this.size) {
			case 'sm':
				this.options = {
					path: `/assets/animations/loader/Loading_Small.json`,
					animationData: colorify(theme['small-loader'], Loading_Small),
				};
				break;
			case 'md':
				this.options = {
					path: `/assets/animations/loader/Loading_Mid.json`,
					animationData: colorify(theme['small-loader'], Loading_Mid),
				};
				break;
			case 'lg':
				this.options = {
					path: `/assets/animations/loader/Loading_Big.json`,
					animationData: colorify(theme['big-loader'], Loading_Big),
				};
				break;
			default:
				this.options = {
					path: `/assets/animations/loader/Loading_Small.json`,
					animationData: colorify(theme['small-loader'], Loading_Small),
				};
				break;
		}
	}

	onAnimate(animationItem: AnimationItem): void {
		this.animationCreated.emit(animationItem);
	}
}
