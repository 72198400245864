import { Injectable } from '@angular/core';
import { SniOption } from 'app/models/sniOption';

@Injectable({
	providedIn: 'root',
})
export class SniCodeTranslationService {
	private industryCodeToKeyMapping: Record<string, string> = {
		'0000': 'other',
		'0001': 'agriculture',
		'0102': 'other-services',
		'0103': 'cleaning',
		'0104': 'salon',
		'0205': 'construction',
		'0306': 'consulting',
		'0407': 'food-and-beverage',
		'0408': 'hotels',
		'0509': 'healthcare',
		'0610': 'manufacturing',
		'0711': 'repair-and-workshop',
		'0812': 'e-commerce',
		'0813': 'everyday-commodity-trade',
		'0814': 'retail-trade',
		'0815': 'sale-of-motor-vehicles',
		'0816': 'wholesale-trade',
		'0917': 'taxi',
		'0918': 'transportation',
	};

	private subIndustryCodeToKeyMapping: Record<string, string> = {
		'000000': 'other',
		'000001': 'mining-and-quarrying',
		'000002': 'energy-and-water',
		'000003': 'finance-and-insurance',
		'000004': 'trade-with-own-real-estate',
		'000005': 'public-sector',
		'000006': 'education',
		'000007': 'art-and-culture',
		'000008': 'gambling-and-betting',
		'000009': 'sport-amusement-and-entertainment',
		'000010': 'membership-organisations',
		'000111': 'agriculture',
		'000112': 'forestry',
		'000113': 'fishing-and-aquaculture',
		'010214': 'other-services',
		'010215': 'warehousing-and-transportation-support-services',
		'010216': 'media-and-publishing',
		'010217': 'telecommunications',
		'010218': 'it-services',
		'010219': 'support-services-for-finance-and-insurance',
		'010220': 'property-management',
		'010221': 'renting-and-leasing',
		'010222': 'employment-services',
		'010223': 'travel-services',
		'010224': 'other-consumer-services',
		'010325': 'cleaning-and-facility-services',
		'010426': 'salon',
		'020527': 'construction-of-buildings',
		'020528': 'construction-of-infrastructure',
		'020529': 'specialised-construction',
		'030630': 'consultancy-and-similar-services',
		'030631': 'it-consultancy',
		'040732': 'restaurant',
		'040733': 'catering',
		'040834': 'hotels-and-similar-accommodation',
		'050935': 'healthcare',
		'050936': 'care-and-nursing-home',
		'050937': 'other-health-and-social-services',
		'061038': 'manufacture-of-food-products',
		'061039': 'other-manufacturing',
		'071140': 'repair-and-workshop',
		'081241': 'e-commerce',
		'081342': 'grocery-store',
		'081343': 'minimarket',
		'081344': 'gas-station',
		'081445': 'retail-trade',
		'081546': 'sale-of-motor-vehicles',
		'081647': 'wholesale-trade',
		'091748': 'taxi',
		'091849': 'other-transportation',
		'091850': 'road-transportation',
	};

	getKeyFromIndustryCode(parentCode: string, industryCode: string): SniOption {
		const fullCode = `${parentCode}${industryCode}`;
		const specificKey = this.industryCodeToKeyMapping[fullCode] || 'unknown';
		return { code: fullCode, translationKey: `sni-selector.industry.${specificKey}` };
	}

	getKeyFromSubIndustryCode(parentCode: string, industryCode: string, subIndustryCode: string): SniOption {
		const fullCode = `${parentCode}${industryCode}${subIndustryCode}`;
		const specificKey = this.subIndustryCodeToKeyMapping[fullCode] || 'unknown';
		return { code: fullCode, translationKey: `sni-selector.sub-industry.${specificKey}` };
	}

	getKeyFromFullCode(fullCode: string): SniOption {
		const specificKey = this.subIndustryCodeToKeyMapping[fullCode] || 'unknown';
		return { code: fullCode, translationKey: `sni-selector.sub-industry.${specificKey}` };
	}
}
