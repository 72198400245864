import { Component, OnDestroy, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-enable-banking-success-modal',
	templateUrl: './enable-banking-success-modal.component.html',
	styleUrls: ['./enable-banking-success-modal.component.scss'],
})
export class EnableBankingSuccessModalComponent implements OnInit, OnDestroy {
	ngOnInit(): void {
		window.document.body.style.setProperty('overflow', 'hidden');
	}

	ngOnDestroy(): void {
		window.document.body.style.setProperty('overflow', 'auto');
	}
}
