<div *ngIf="event" class="flex flex-row justify-between mb-2 bg-white border-b rounded-lg border-light-grey">
	<div *ngFor="let field of event.fields; let fieldIndex = index" class="w-full px-1 py-3">
		<div *ngIf="fieldIndex === 0" class="flex w-48 px-3 lg:w-56 flex-column">
			<span class="text-sm text-almost-black">{{event.eventDateFormatted}}</span>
			<span class="text-sm lg:text-base" [ngClass]="field.classes || 'text-secondary-400'">{{field.label | translate}}</span>
		</div>
		<div class="flex flex-row justify-between">
			<div *ngIf="fieldIndex !== 0" class="flex flex-column">
				<span class="text-sm text-almost-black">{{field.label | translate}}</span>
				<span [ngClass]="field.classes || 'text-secondary-400'" class="font-semibold">
					<span *ngIf="!field.formatNumber" class="text-sm lg:text-base" [ngClass]="fieldIndex === 1 && event.failedEvent ? 'text-system-red': field.classes">
						{{field.value}}
					</span>
					<span *ngIf="field.formatNumber" class="text-sm lg:text-base">{{field.value | number:'1.2-2'}}{{field.additionalInfo}}</span>
				</span>
			</div>
		</div>
	</div>
</div>
