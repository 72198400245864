<app-modal-card [visible]="visible" [closable]="false">
	<h1 class="mb-6 text-xl text-center" translate="home.fetch-credit-safe.title"></h1>
	<div class="flex flex-col items-center">
		<ng-container *ngIf="!errorMessage">
			<app-loader></app-loader>
			<p class="mt-2 mb-6 text-sm font-medium text-almost-black" translate="home.fetch-credit-safe.loading"></p>
		</ng-container>
		<ng-container *ngIf="errorMessage">
			<p class="mb-6">{{ errorMessage | translate }}</p>
			<div class="flex justify-center w-full">
				<contact-card></contact-card>
			</div>
		</ng-container>
		<div *ngIf="errorMessage" class="flex flex-col items-center mt-4">
			<a class="mb-2 link" type="secondary" (click)="close.emit()">{{ 'fetch-credit-safe.error-page.cancel' | translate }}</a>
		</div>
	</div>
</app-modal-card>
