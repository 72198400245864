export const billyVariables = {
	'font-higlight': 'Aeonik',
	'font-header': 'Aeonik',
	'font-body': 'Aeonik',
	'primary-100': '#FAFAFA',
	'primary-200': '#0A1E63',
	'primary-400': '#ffcb33',
	'primary-500': '#0A1E63',
	'primary-700': '#e99b15',
	'secondary-100': '#FAFAFA',
	'secondary-200': '#0A1E63',
	'secondary-400': '#0000F9',
	'secondary-500': '#0A1E63',
	'secondary-900': '#0F2D32',

	'checkbox-100': '#0F2D32',
	'checkbox-300': '#0F2D32',
	'checkbox-500': '#0F2D32',
	'radio-100': '#01FF94',
	'radio-500': '#0F2D32',

	'rounded-box': '16px',
	'rounded-notification': '16px',

	'min-width': 'fit-content',

	'highlight-light': '#FFFFFF',
	'highlight-border': '#EEEEEE',
	'slider-color': '#FFFFFF',
	'slider-border-color': '#0F2D32',

	//Box card styles
	'box-background': '#0F2D32',
	'box-info': '#01FF94',
	'box-border': '#0F2D32',

	//Box card styles - secondary
	'box-background-light': '#F9F9F9',
	'box-info-light': '#0F2D32',
	'box-border-light': '#F9F9F9',

	separator: '#CEC1E8',

	'input-phone-prefix': '#EDECEE',
	spinner: '#01FF94',

	highlighted: '#0F2D32',
	title: '#0F2D32',
	paragraph: '#0F2D32',

	dark: '#0F2D32',
	brand: '#01FF94',

	'footer-background': '#FAFAFA',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#0F2D32',
	'button-color-hover': '#0F2D32',
	'button-text': '#FFFFFF',
	'button-text-hover': '#01FF94',
	'rounded-button': '100px',
	'padding-button': '12px 24px',
	'border-button-color': '#0F2D32',

	// secondary button
	'button-color-secondary': '#FFFFFF',
	'button-text-secondary': '#0F2D32',
	'button-text-secondary-hover': '#0F2D32',
	'rounded-button-secondary': '100px',
	'padding-button-secondary-outlined': '10px 24px',
	'button-border-secondary-outlined': '#0F2D32',
	'button-border-secondary-hover': '#01FF94',

	// --------------------------------------- //

	// loading animations
	'big-loader': [
		'#01FF94',
		'#01FF94',
		'#01FF94',
		'#01FF94',
		'#AEAEAE',
		'#AEAEAE',
		'#AEAEAE',
		'#AEAEAE',
		'#01FF94',
		'#01FF94',
		'#01FF94',
		'#01FF94',
		'#AEAEAE',
		'#AEAEAE',
		'#AEAEAE',
		'#AEAEAE',
		'#01FF94',
	],
	'small-loader': ['#01FF94', '#01FF94', '#01FF94', '#AEAEAE', '#AEAEAE', '#AEAEAE'],
};
