<form *ngIf="signatoriesForm" class="w-full" [formGroup]="signatoriesForm" class="flex flex-col mx-auto">
	<div class="flex flex-col bg-white">
		<div>
			<app-notification *ngIf="(signatoriesCtrl.dirty || signatoriesCtrl.touched) && signatoriesCtrl.errors?.atLeastOne" type="error">
				{{ 'kyc.at-least-one' | translate }}
			</app-notification>
			<app-notification *ngIf="(signatoriesCtrl.dirty || signatoriesCtrl.touched) && signatoriesCtrl.errors?.signatoryAsyncValidator && validationAttempt" type="error">
				{{ signatoriesCtrl.errors?.signatoryAsyncValidator.validation_response_code | translate }}
			</app-notification>
		</div>
		<div class="flex flex-col">
			<div formArrayName="signatories" *ngFor="let signatory of signatoriesCtrl.controls; let i = index">
				<div [formGroupName]="i" class="py-1">
					<app-checkbox formControlName="included" [attr.data-testid]="'checkbox-signatory-' + i" [collapsible]="true" type="list">
						<span text>
							{{
								signatory.value.name || signatory.value.first_name + ' ' +
								signatory.value.last_name
							}}
						</span>
						<div body *ngIf="signatory.value.included" class="flex flex-wrap max-w-sm">
							<div class="w-full">
								<app-input
									[label]="'white-label.label.phone' | translate"
									name="phone"
									type="tel"
									formControlName="phone"
									[prefix]="phonePrefix"
									[placeholder]="'phone.placeholder' | translate : {placeholder: phonePlaceholder}"
									[attr.data-testid]="'input-signatory-phone-' + i"></app-input>
							</div>
							<div class="w-full">
								<app-input
									[label]="'white-label.label.email' | translate"
									name="email"
									type="email"
									formControlName="email"
									[placeholder]="'email.placeholder' | translate"
									[attr.data-testid]="'input-signatory-email-' + i"></app-input>
							</div>
						</div>
					</app-checkbox>
				</div>
			</div>
		</div>
	</div>
</form>
