import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import cacheBusting from '../../../i18n-cache-busting/i18n-cache-busting.json';

@Injectable({
	providedIn: 'root',
})
export class TranslateLoaderService implements TranslateLoader {
	constructor(private http: HttpClient) {}

	getTranslation(lang: string) {
		const path = `/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`;
		return this.http.get(path);
	}
}
