/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OnboardingSessionType = 'standard' | 'broker' | 'campaign' | 'referral' | 'referralPartnerPortal';

export const OnboardingSessionType = {
    Standard: 'standard' as OnboardingSessionType,
    Broker: 'broker' as OnboardingSessionType,
    Campaign: 'campaign' as OnboardingSessionType,
    Referral: 'referral' as OnboardingSessionType,
    ReferralPartnerPortal: 'referralPartnerPortal' as OnboardingSessionType
};

