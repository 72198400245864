import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PrefixTranslationKeysService {
	private prefix = null;
	private partnerPrefix = null;

	constructor() {}

	getPrefix() {
		return this.prefix;
	}

	//If there is no translation for key example.title,
	//MissingTranslationHandler will search for a key with prefix - prefix.example.title
	setPrefix(prefix: string) {
		this.prefix = prefix;
	}

	//If there is no translation for key example.title,
	//but there is a partner prefix set
	//MissingTranslationHandler will search for a key with prefix - partnerPrefix.example.title
	getPartnerPrefix() {
		return this.partnerPrefix;
	}

	setPartnerPrefix(prefix: string) {
		this.partnerPrefix = prefix;
	}
}
