<app-modal-card [visible]="visible" [closable]="closable" (close)="close.emit()">
	<form *ngIf="contactForm" class="flex flex-col w-full" [formGroup]="contactForm">
		<h3 class="mb-4" translate="contact-modal.title"></h3>
		<p class="mb-6" [innerHTML]="'contact-modal.pre' | translate"></p>
		<div class="flex flex-col mb-6 sm:-mx-3">
			<div class="w-full sm:px-3">
				<app-input [label]="'contact-modal.email.label' | translate" [placeholder]="'email.placeholder' | translate" name="email" type="email" formControlName="email"></app-input>
			</div>
			<div class="w-full sm:px-3">
				<app-input
					[label]="'contact-modal.phone.label' | translate"
					name="phone"
					type="tel"
					formControlName="phone"
					[placeholder]="'phone.placeholder' | translate : {placeholder: phonePlaceholder}"></app-input>
			</div>
		</div>
		<div class="flex flex-col items-center">
			<app-button class="w-full mb-2" type="primary" (action)="addContact()">{{ 'contact-modal.continue' | translate }}</app-button>
		</div>
	</form>
</app-modal-card>
