<app-modal-card *ngIf="scrapingSession$ | async as scrapingSession" [visible]="visible" [closable]="false">
	<div [smoothHeight]="scrapingSession.hint_code">
		<h1 class="mb-6 text-xl text-center">{{ 'bankid-modal.heading' | translate }}</h1>

		<div *ngIf="scrapingSession.hint_code === 'OPEN_BANKDID'" class="flex flex-col items-center mb-6">
			<img src="assets/shared/icons/phone-bankid-open.svg" alt="BankID open app" class="h-20 mb-4" />
			<h4>{{ 'bankid-modal.title.OPEN_BANKDID' | translate }}</h4>
		</div>
		<div *ngIf="scrapingSession.hint_code === 'SCAN_QR'" class="flex flex-col items-center mb-6">
			<img src="assets/shared/icons/phone-bankid-qr.svg" alt="BankID scan QR" class="h-20 mb-4" />
			<h4 class="mb-4">{{ 'bankid-modal.title.SCAN_QR' | translate }}</h4>
			<div class="flex items-center justify-center border-dark-grey border-dashed border-5">
				<qrcode style="margin: 0 auto" [qrdata]="qrUrl"></qrcode>
			</div>
		</div>
		<div *ngIf="scrapingSession.hint_code === 'SIGN'" class="flex flex-col items-center mb-6">
			<img src="assets/shared/icons/phone-bankid-pin.svg" alt="BankID enter PIN" class="h-20 mb-4" />
			<h4>{{ 'bankid-modal.title.SIGN' | translate }}</h4>
		</div>
		<div *ngIf="scrapingSession.hint_code === 'SELECT_COMPANY'">
			<app-company-selector [companies]="(availableOrganizations$ | async)" (companySelected)="companySelected($event)"></app-company-selector>
		</div>
		<div *ngIf="scrapingSession.status === 'FAILED'" class="flex flex-col items-center my-6">
			<app-notification type="error">{{ 'bankid-modal.hint.' + scrapingSession.hint_code | translate }}</app-notification>
		</div>
		<div *ngIf="scrapingSession.status === 'PENDING'" class="flex flex-col items-center my-6">
			<app-loader></app-loader>
			<p class="max-w-sm mt-2 text-center">{{ 'bankid-modal.hint.' + scrapingSession.hint_code | translate }}</p>
		</div>
		<div *ngIf="scrapingSession.status === 'COMPLETE'" class="flex flex-col items-center my-6">
			<app-notification type="success">{{ 'bankid-modal.status.' + scrapingSession.status | translate }}</app-notification>
		</div>
		<div class="flex flex-col items-center">
			<app-button *ngIf="scrapingSession.hint_code === 'OPEN_BANKDID'" class="mb-4" (action)="openBankIDApp()">{{ 'bankid-modal.button.start-bankid' | translate }}</app-button>
			<a *ngIf="scrapingSession.hint_code === 'OPEN_BANKDID'" class="mb-4 link" (click)="switchDevice()">{{ 'bankid-modal.button.bankid-other-device' | translate }}</a>
			<a *ngIf="scrapingSession.hint_code === 'SCAN_QR'" class="mb-4 link" (click)="switchDevice()">{{ 'bankid-modal.button.bankid-this-device' | translate }}</a>
			<app-button *ngIf="scrapingSession.status === 'FAILED'" class="mb-4" (action)="retryBankID()">{{ 'bankid-modal.button.retry' | translate }}</app-button>
			<a *ngIf="scrapingSession.status !== 'COMPLETE'" class="mb-4 link" (click)="closeModal()">{{ 'bankid-modal.button.cancel' | translate }}</a>
		</div>
	</div>
</app-modal-card>
