<div
	class="min-h-12 w-full flex justify-between items-center border-b min-w-[100px] pr-4 md:pr-0 lg:hover:border-black cursor-pointer {{ isChecked() ? (collapsible ? 'border-none' : 'border-black-6') : 'border-black-6' }}"
	[ngClass]="{
		'border-b' : type === 'list'
	}">
	<label class="flex items-center gap-2 cursor-pointer">
		<div class="relative flex items-center p-0.5 w-5 h-5">
			<input
				type="checkbox"
				[ngModel]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				class="transition-colors duration-300 rounded outline-none border-medium-grey border-1 focus:ring-white checked:bg-black form-radio text-radio-500 checked:focus:bg-black checked:focus:border-1 checked:focus:border-black checked:hover:bg-black checked:hover:border-1 checked:hover:border-black hover:cursor-pointer checked:border-black "
				[disabled]="disabled"
				data-testid="input" />
		</div>
		<span class="font-medium leading-none text-almost-black">
			<ng-content select="[text]"></ng-content>
		</span>
	</label>
	<div *ngIf="description" class="pr-4 lg:pr-0 {{ isChecked() ? 'text-black' : 'text-medium-grey'}}">{{ description }}</div>
</div>
<div *ngIf="isChecked() && collapsible" class="border-b pl-7 border-black-6">
	<div class="pt-2 mr-7 lg:mr-0">
		<ng-content select="[body]"></ng-content>
	</div>
</div>
<div *ngIf="hasErrors() && control.errors?.required" class="flex my-2 leading-tight text-system-red">
	<app-svg-icon [iconName]="'arrow-up-left.svg'" [iconId]="'arrow-up-left'"></app-svg-icon>
	<div class="mt-2 ml-2 text-sm leading-none">
		<ng-content select="[error]"></ng-content>
	</div>
</div>
