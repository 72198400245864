<form *ngIf="bankInfoForm" class="flex flex-col mx-auto mb-20 md:pl-0" [formGroup]="bankInfoForm">
	<ng-container>
		<app-input
			class="w-screen max-w-full pt-4 sm:max-w-sm"
			name="search"
			formControlName="search"
			[placeholder]="'search.placeholder' | translate"
			[prefixIcon]="'search.svg'"
			[prefixIconId]="'search'"
			[autocomplete]="'off'"
			(handleOnFocus)="handleSearchOnFocus()"
			(handleOnBlur)="handleSearchOnBlur()"
			data-testid="input-bank-search"></app-input>

		<div *ngIf="isSearchActive" class="flex flex-col" [@fadeInOut]>
			<div *ngFor="let bankOption of (searchedBankOptions)" [@itemAnimation]>
				<app-search-bank-item
					[listItem]="bankOption"
					[isSelected]="bankOption.value === selectedBank.value?.identifier"
					(optionClick)="onBankClicked(bankOption)"
					data-testid="bank-search-result"></app-search-bank-item>
			</div>
			<div *ngIf="(searchedBankOptions)?.length === 0" class="flex items-center justify-center w-full pt-8" [@fadeInOut]>
				<p translate="enable-banking.search.no-result"></p>
			</div>
		</div>
		<div *ngIf="!isSearchActive">
			<p class="mt-1 mb-4 text-base text-black sm:mt-3" translate="onboarding.application.bank-information.common-banks"></p>
			<div class="grid grid-cols-2 gap-4 mb-10 sm:grid-cols-3 sm:gap-2" [@fadeInOut]>
				<app-grid-icon-item
					*ngFor="let bankOption of popularBankOptions"
					[iconUrl]="bankOption?.logo"
					[iconAlt]="bankOption?.name"
					[item]="bankOption"
					(optionClick)="onBankClicked(bankOption)"></app-grid-icon-item>
			</div>
		</div>
	</ng-container>
</form>
