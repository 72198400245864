const appendPrefersExternalParam = (url: string) => {
	const urlObj = new URL(url);

	if (!urlObj.searchParams.has('prefersExternal')) {
		urlObj.searchParams.append('prefersExternal', 'true');
	}

	return urlObj.toString();
};

export const openFileLink = (fileUrl: string, partnerKey: string | null = null) => {
	/*
        This is the long term fix for our partner apps to handle the pdf file download.
        They will have to add logic to their apps to intercept any link with the "prefersExternal" url param and open it in a browser.
        Lunar will be using this URL param right away to fix their broken PDF links. 
        Ageras will get some time to change it for Kontist and Billy and let their users update to the latest app version.
     */
	const updatedFileUrl = appendPrefersExternalParam(fileUrl);

	// Load the file in a new tab if the partnerKey is empty (web browsers native flow) or 'billy' (iframe in web app)
	if (partnerKey === null || partnerKey === 'billy') {
		// If included in a web browser, open the file in a new tab
		const a = document.createElement('a');
		a.href = updatedFileUrl;
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	} else {
		// Use a redirect and rely on the partner apps intercepting the URL
		window.location.href = updatedFileUrl;
	}
};
