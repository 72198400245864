<div class="flex pt-common" *ngIf="hasPreviousLoan">
	<div class="w-2/3 truncate {{className}}" translate="application-summary.new-loan"></div>
	<div class="w-1/3 font-bold text-right {{className}}">{{ isValidLoanRequest ? ((amount$ | async) | asMoney:currency:0) : '-'  }}</div>
</div>

<div class="flex pt-common" *ngIf="hasPreviousLoan">
	<div class="w-2/3 truncate {{className}}" translate="application-summary.existing-loan"></div>
	<div class="w-1/3 font-bold text-right {{className}}">{{ isValidLoanRequest ?  (credit?.credit_used | money:0 ) : '-'}}</div>
</div>

<div class="flex pt-common" *ngIf="!hasPreviousLoan">
	<div class="w-2/3 truncate {{className}}" translate="application-summary.loan-amount"></div>
	<div class="w-1/3 font-bold text-right {{className}}">{{ isValidLoanRequest ? ((amount$ | async) | asMoney:currency:0) : '-'  }}</div>
</div>

<div class="flex pt-common" *ngIf="hasPreviousLoan">
	<div class="w-2/3 truncate {{className}}" translate="application-summary.new-total-loan-amount"></div>
	<div class="w-1/3 font-bold text-right {{className}}">{{ isValidLoanRequest ? (credit?.credit_used.amount + (amount$ | async) | asMoney:currency:0) : '-'  }}</div>
</div>

<div *ngIf="!(isFlexLoan$ | async)" class="flex pt-common">
	<div class="w-2/3 truncate {{className}}" translate="new-loan.summary.duration"></div>
	<div class="w-1/3 font-bold text-right {{className}}">{{ isValidLoanRequest ? (duration$ | async) + ' ' + ('input.suffix.months' | translate) : '-' }}</div>
</div>

<div *ngIf="!(isFlexLoan$ | async)" class="flex pt-common">
	<div *ngIf="!hasPreviousLoan" class="w-2/3">
		<div translate="new-loan.summary.fee" class="truncate"></div>
	</div>
	<div *ngIf="hasPreviousLoan" class="w-2/3">
		<div translate="topup.summary.total-cost" class="truncate"></div>
		<small class="text-dark-grey" translate="topup.summary.total-cost-info"></small>
	</div>
	<div *ngIf="!(offerStale$ | async); else loadingTemplate" class="w-1/3 font-bold text-right {{className}}">
		{{ isValidLoanRequest ? ((offer$ | async)?.cost | money:0) : '-'}}
	</div>
</div>
<div *ngIf="showFixedInterest" class="flex pt-common">
	<div translate="new-loan.summary.interest" class="w-2/3 truncate"></div>
	<div *ngIf="!(offerStale$ | async); else loadingTemplate" class="w-1/3 font-bold text-right {{className}}">
		{{ isValidLoanRequest ? (((offer$ | async)?.price_point.interestPercentage || 0) + '%') : '-' }}
	</div>
</div>

<div *ngIf="(isFlexLoan$ | async)" class="flex pt-common">
	<div class="w-2/3 truncate {{className}}" translate="new-loan.summary.interest-rate"></div>
	<div class="w-1/3 font-bold text-right {{className}}">
		{{ isValidLoanRequest ? (monthlyInterestRate$ | async | percent:'1.0-2')  : '-'}}
	</div>
</div>
<div *ngIf="!(isFlexLoan$ | async) && isPartnerIntegration" class="flex justify-between border-t pt-common mt-common border-dark-grey">
	<p class="w-1/3" translate="new-loan.summary.payment"></p>
	<p *ngIf="!(offerStale$ | async); else loadingTemplate" class="w-2/3 text-xl font-bold leading-none text-right text-highlighted">
		<ng-container *ngIf="isValidLoanRequest; else dashTemplate">
			{{ ((offer$ | async)?.standard_payment | money:0) }}
			<span *ngIf="paysWeekly$ | async" class="text-base">{{ ('new-loan.summary.payment-interval.weekly' | translate) }}</span>
			<span *ngIf="paysDaily$ | async" class="text-base">{{ ('new-loan.summary.payment-interval.daily' | translate) }}</span>
			<span *ngIf="paysMonthly$ | async" class="text-base">{{ ('new-loan.summary.payment-interval.monthly' | translate) }}</span>
		</ng-container>
	</p>
</div>
<div *ngIf="!(isFlexLoan$ | async)" class="flex pt-common" [ngClass]="{'border-t border-dark-grey mt-common': !isPartnerIntegration }">
	<div *ngIf="!hasPreviousLoan" class="w-2/3 truncate" translate="new-loan.summary.total"></div>
	<div *ngIf="hasPreviousLoan" class="w-2/3 truncate" translate="topup.summary.total"></div>
	<div *ngIf="!(offerStale$ | async); else loadingTemplate" class="w-1/3 font-bold text-right">
		{{ isValidLoanRequest ? ((offer$ | async)?.total_amount | money:0)  : '-'}}
	</div>
</div>
<div *ngIf="(isFlexLoan$ | async)" class="flex border-t pt-common mt-common border-dark-grey">
	<div *ngIf="!hasPreviousLoan" class="w-2/3 truncate" translate="new-loan.summary.monthly-cost"></div>
	<div *ngIf="hasPreviousLoan" class="w-2/3 truncate" translate="topup.summary.monthly-cost"></div>
	<div class="w-1/3 font-bold text-right">
		{{ isValidLoanRequest ? ((offer$ | async)?.total_cost_per_month | money:0) : '-'}}
	</div>
</div>

<ng-template #loadingTemplate>
	<div class="w-1/3 text-right animate-pulse">
		<div class="w-full h-6 bg-medium-grey"></div>
	</div>
</ng-template>

<ng-template #dashTemplate>
	<span class="text-base">-</span>
</ng-template>
