/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ExtraAmortizationPaymentInterval = 'monthly' | 'quarterly' | 'semiannually' | 'yearly' | 'moreSeldom';

export const ExtraAmortizationPaymentInterval = {
    Monthly: 'monthly' as ExtraAmortizationPaymentInterval,
    Quarterly: 'quarterly' as ExtraAmortizationPaymentInterval,
    Semiannually: 'semiannually' as ExtraAmortizationPaymentInterval,
    Yearly: 'yearly' as ExtraAmortizationPaymentInterval,
    MoreSeldom: 'moreSeldom' as ExtraAmortizationPaymentInterval
};

