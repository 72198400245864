import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-grid-icon-item',
	templateUrl: './grid-icon-item.component.html',
	styleUrls: ['./grid-icon-item.component.scss'],
})
export class GridIconItemComponent implements OnInit {
	@Input() iconUrl: string;
	@Input() iconAlt: string;
	@Input() item: any;

	@Output() optionClick = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}
}
