import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const smoothHeight = trigger('grow', [
	transition('void <=> *', []),
	transition('* <=> *', [style({ height: '{{startHeight}}px', opacity: 0 }), animate('300ms ease-in-out')], {
		params: { startHeight: 0 },
	}),
]);

export const smoothOpenClose = trigger('openClose', [
	state('*', style({ overflow: 'hidden ' })),
	transition(':enter', [
		style({ height: '0px', paddingTop: 0, paddingBottom: 0 }),
		animate(
			'300ms ease-in-out',
			style({ height: '*', paddingTop: '*', paddingBottom: '*', marginTop: '*', marginBottom: '*', opacity: 1 })
		),
	]),
	transition(':leave', [
		animate(
			'300ms ease-in-out',
			style({ height: '0px', opacity: 0, paddingTop: '0', marginTop: '0', marginBottom: '0', paddingBottom: '0' })
		),
	]),
]);

export const slideLeft = trigger('slideLeft', [
	state('closed', style({ transform: 'translateX(100%)' })),
	transition('closed => opened', [animate('300ms ease-out', style({ transform: 'translateX(0%)' }))]),
	transition('opened => closed', [animate('300ms ease-out', style({ transform: 'translateX(100%)' }))]),
]);

export const slideRight = trigger('slideRight', [
	state('closed', style({ transform: 'translateX(-100%)' })),
	transition('closed => opened', [animate('300ms ease-out', style({ transform: 'translateX(0%)' }))]),
	transition('opened => closed', [animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))]),
]);

export const rotate90deg = trigger('rotate90deg', [
	state('false', style({ transform: 'rotate(0deg)' })),
	state('true', style({ transform: 'rotate(90deg)' })),
	transition('false <=> true', animate('300ms ease-in-out')),
]);

export const rotate180deg = trigger('rotate180deg', [
	state('false', style({ transform: 'rotate(0deg)' })),
	state('true', style({ transform: 'rotate(-180deg)' })),
	transition('false <=> true', animate('300ms ease-in-out')),
]);

export const rotate270deg = trigger('rotate270deg', [
	state('false', style({ transform: 'rotate(0deg)' })),
	state('true', style({ transform: 'rotate(-90deg)' })),
	transition('false <=> true', animate('300ms ease-in-out')),
]);

export const fadeInOut = trigger('fadeInOut', [
	transition(':leave', [style({ opacity: 1 }), animate('300ms ease-in', style({ opacity: 0 }))]),
	transition(':enter', [style({ opacity: 0, overflow: 'hidden' }), animate('300ms ease-out', style({ opacity: 1 }))]),
	transition('enter => leave', [style({ opacity: 1 }), animate('300ms ease-in', style({ opacity: 0 }))]),
	transition('leave => enter', [
		style({ opacity: 0, overflow: 'hidden' }),
		animate('300ms ease-out', style({ opacity: 1 })),
	]),
]);

export const translateYAnimation = trigger('translateYAnimation', [
	transition('void => up', [
		style({ transform: 'translateY(40px)', opacity: 0 }),
		animate('250ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
	]),
	transition('void => down', [
		style({ transform: 'translateY(-40px)', opacity: 0 }),
		animate('250ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
	]),
	transition('down => void', [
		style({ transform: 'translateY(0)', opacity: 1 }),
		animate('250ms ease-out', style({ transform: 'translateY(40px)', opacity: 0 })),
	]),
	transition('up => void', [
		style({ transform: 'translateY(0)', opacity: 1 }),
		animate('250ms ease-out', style({ transform: 'translateY(-40px)', opacity: 0 })),
	]),
]);

export const fade = trigger('fade', [
	state(
		'visible',
		style({
			opacity: 1,
			visibility: 'visible',
		})
	),
	state(
		'hidden',
		style({
			opacity: 0,
			visibility: 'hidden',
		})
	),
	transition('visible => hidden', [animate('300ms ease-out')]),
	transition('hidden => visible', [animate('300ms ease-in')]),
]);

export const expandRows = trigger('expandRows', [
	state('*', style({ overflow: 'hidden ' })),
	transition('collapsed => expanded', [
		query(
			'.expanded-row',
			style({ height: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, opacity: 0 }),
			{ optional: true }
		),
		query(
			'.expanded-row',
			stagger('100ms', [
				animate(
					'100ms',
					style({ height: '*', paddingTop: '*', paddingBottom: '*', marginTop: '*', marginBottom: '*', opacity: 1 })
				),
			]),
			{ optional: true }
		),
	]),
	transition('expanded => collapsed', [
		query(
			'.expanded-row',
			style({ height: '*', paddingTop: '*', paddingBottom: '*', marginTop: '*', marginBottom: '*', opacity: 1 }),
			{ optional: true }
		),
		query(
			'.expanded-row',
			stagger('-100ms', [
				animate(
					'100ms',
					style({ height: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, opacity: 0 })
				),
			]),
			{ optional: true }
		),
	]),
]);
