export const kontistVariables = {
	'font-higlight': 'Brix Sans',
	'font-header': 'Brix Sans',
	'font-body': 'Brix Sans',
	'primary-100': '#FAFAFA',
	'primary-200': '#0A1E63',
	'primary-400': '#ffcb33',
	'primary-500': '#0A1E63',
	'primary-700': '#e99b15',
	'secondary-100': '#FAFAFA',
	'secondary-200': '#0A1E63',
	'secondary-400': '#0000F9',
	'secondary-500': '#0A1E63',
	'secondary-900': '#DF910B',

	'checkbox-100': '#5A2FB1',
	'checkbox-300': '#CEC1E8',
	'checkbox-500': '#5A2FB1',
	'radio-100': '#CEC1E8',
	'radio-500': '#5A2FB1',

	'rounded-box': '4px',
	'rounded-notification': '4px',

	'min-width': 'fit-content',

	'highlight-light': '#FFFFFF',
	'highlight-border': '#EEEEEE',
	'slider-color': '#FFFFFF',
	'slider-border-color': '#5A2FB1',

	//Box card styles
	'box-background': '#5A2FB1',
	'box-info': '#FFFFFF',
	'box-border': '#5A2FB1',

	//Box card styles - secondary
	'box-background-light': '#EBE7F7',
	'box-info-light': '#000000',
	'box-border-light': '#EBE7F7',

	separator: '#CEC1E8',

	'input-phone-prefix': '#EDECEE',
	spinner: '#23085C',

	highlighted: '#5A2FB1',
	title: '#24163A',
	paragraph: '#24163A',

	dark: '#5A2FB1',
	brand: '#EBE7F7',

	'footer-background': '#FAFAFA',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#5A2FB1',
	'button-color-hover': '#5A2FB1',
	'button-text': '#FFFFFF',
	'button-text-hover': '#FFFFFF',
	'rounded-button': '4px',
	'padding-button': '12px 24px',
	'border-button-color': '#5A2FB1',

	// secondary button
	'button-color-secondary': '#EBE7F7',
	'button-text-secondary': '#5A2FB1',
	'button-text-secondary-hover': '#5A2FB1',
	'rounded-button-secondary': '4px',
	'padding-button-secondary-outlined': '10px 24px',
	'button-border-secondary-outlined': '#EBE7F7',
	'button-border-secondary-hover': '#EBE7F7',
	// --------------------------------------- //

	// loading animations
	'big-loader': [
		'#5A2FB1',
		'#5A2FB1',
		'#5A2FB1',
		'#5A2FB1',
		'#EBE7F7',
		'#EBE7F7',
		'#EBE7F7',
		'#EBE7F7',
		'#5A2FB1',
		'#5A2FB1',
		'#5A2FB1',
		'#5A2FB1',
		'#EBE7F7',
		'#EBE7F7',
		'#EBE7F7',
		'#EBE7F7',
		'#5A2FB1',
	],
	'small-loader': ['#5A2FB1', '#5A2FB1', '#5A2FB1', '#EBE7F7', '#EBE7F7', '#EBE7F7'],
};
