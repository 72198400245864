import { Component, EventEmitter, Input, Output } from '@angular/core';
import { smoothOpenClose } from 'app/animations/smooth.animations';
import { LoanPurposeAnswerDto, LoanPurposeDto } from 'app/api';

export interface LoanPurposeItemStyleType {
	itemBgColor?: string;
	itemSelectedBgColor?: string;
	itemBorderColor?: string;
	itemSelectedBorderColor: string;
	itemTextColor?: string;
	itemSelectedTextColor?: string;
	itemBorderRadius?: string;
	border?: string;
}

interface ListItemType {
	code?: string | null;
	text?: string | null;
	image?: string | null;
	answers?: LoanPurposeAnswerDto[] | null;
}

@Component({
	selector: 'app-list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.scss'],
	animations: [smoothOpenClose],
})
export class ListItemComponent {
	@Input() imageSourceUrl: string;
	@Input() showDescription = true;
	@Input() isSelected = false;
	@Input() listItem: ListItemType;
	@Input() itemStyle: LoanPurposeItemStyleType;
	@Input() iconSize?: string = '24';

	@Output() optionClick = new EventEmitter<any>();
}
