import { Injectable } from '@angular/core';
import { MoneyDto, PricePointDto } from 'app/api';

@Injectable({
	providedIn: 'root',
})
export class DraftBuilderService {
	constructor() {}
	private draft: Draft = this.getDefaultDraft();

	public setAmount(amount: MoneyDto) {
		this.draft.amount = amount;
	}

	public setTotalWithdrawalAmount(withdrawalAmount: MoneyDto) {
		this.draft.totalWithdrawalAmount = withdrawalAmount;
	}
	public setRate(newRate: DraftRate) {
		this.draft.rate = newRate;
	}
	public isEmpty(): boolean {
		return this.draft.rate.name === 'none';
	}
	public getFullDraft(): Draft {
		return this.draft;
	}

	public clearDraft(): Draft {
		return (this.draft = this.getDefaultDraft());
	}

	private getDefaultDraft(currency?: string): Draft {
		if (!currency) currency = null;
		return {
			amount: { amount: 0, currency: currency },
			totalWithdrawalAmount: { amount: 0, currency: currency },
			ownership: {
				persons: [],
				mode: null,
			},
			signatories: {
				persons: [],
				mode: null,
			},
			rate: {
				name: 'none',
				durationInMonths: 0,
				total: { amount: 0, currency: currency },
				periodAmountRevenueBased: 0,
				periodAmount: { amount: 0, currency: currency },
				cost: { amount: 0, currency: currency },
				isPercentageOfRevenue: false,
				pricePoint: {
					pricePoint: 0,
				},
			},
			purpose: null,
		};
	}
}
export class Draft {
	public amount: MoneyDto;
	public totalWithdrawalAmount: MoneyDto;
	public ownership: DraftOwnership;
	public signatories: DraftSignatories;
	public rate: DraftRate;
	public purpose: DraftPurpose;
}

class DraftOwnership {
	public mode: string;
	public persons: DraftOwnershipPerson[];
}

class DraftOwnershipPerson {
	public name: string;
	public ssn: string;
}

class DraftSignatories {
	public mode: string;
	public persons: DraftSignatoryPerson[];
}

class DraftSignatoryPerson {
	public phone: string;
	public email: string;
	public id: string;
	public name: string;
}

class DraftRate {
	public name: string;
	public durationInMonths: number;
	public total: MoneyDto;
	public periodAmountRevenueBased: number;
	public periodAmount: MoneyDto;
	public isPercentageOfRevenue: boolean;
	public cost: MoneyDto;
	public pricePoint: PricePointDto;
}

class DraftPurpose {
	public code: string;
	public text: string;
	public answers: any[];
	public questions: any[];
}
