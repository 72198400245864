/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BankAccountStatus = 'notVerified' | 'verified' | 'deactivated' | 'pending' | 'cancelPending';

export const BankAccountStatus = {
    NotVerified: 'notVerified' as BankAccountStatus,
    Verified: 'verified' as BankAccountStatus,
    Deactivated: 'deactivated' as BankAccountStatus,
    Pending: 'pending' as BankAccountStatus,
    CancelPending: 'cancelPending' as BankAccountStatus
};

