import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganizationDto } from 'app/api';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-company-selector',
	templateUrl: './company-selector.component.html',
	styleUrls: ['./company-selector.component.scss'],
})
export class CompanySelectorComponent implements OnInit {
	@Input() companies: OrganizationDto[];
	@Input() loading: boolean;
	@Output() companySelected = new EventEmitter<string>();
	selectedIdentifier: string;
	form: FormGroup;
	partner: string | null = null;

	constructor(private formBuilder: FormBuilder, private route: ActivatedRoute) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe(params => {
			this.partner = params.get('partner');
		});

		this.form = this.formBuilder.group({
			companyOrg: [''],
		});

		this.companyOrg()
			.valueChanges.pipe(distinctUntilChanged())
			.subscribe(value => {
				this.selectedIdentifier = value;
			});
	}

	companyOrg(): AbstractControl {
		return this.form.get('companyOrg');
	}
}
