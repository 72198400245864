import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../bootstrapping/appconfig';

@Injectable({
	providedIn: 'root'
})
export class BankIdService {

	constructor(private router: Router, private config: AppConfig) { }

	openBankId(startCommand: StartBankIdCommand): Promise<void> {

		const clientBaseUrl = this.getClientBaseUrl();

		const bankIdUrl = this.createLaunchUrl(clientBaseUrl, startCommand);
		console.log(bankIdUrl);
		return new Promise<void>( resolve => {
			const self = this;

				if (navigator.userAgent.match(/iPhone/i) ||
					navigator.userAgent.match(/Android/i)) {
				document.location.href = bankIdUrl;
			}
			setTimeout(function () {
				if (navigator.userAgent.match(/iPhone/i) ||
					navigator.userAgent.match(/Android/i)) {
					alert('Öppna Mobilt Bank-Id appen på den enhet där du har den installerad');
				}
				if(startCommand.pollingUrl)
					self.router.navigate([startCommand.pollingUrl], { queryParams: { returnUrl: startCommand.returnUrl } });
				resolve();
			}, 10);
		});
	}

	public createLaunchUrl(clientBaseUrl: string, command: StartBankIdCommand): string {
		let baseUrl = clientBaseUrl + command.pollingUrl;
		if(command.returnUrl != null)
			baseUrl += '?returnUrl=' + encodeURI(command.returnUrl);

		const redirectUrl = encodeURIComponent(baseUrl);

		let autoStartToken = '';
		if(command.autoStartToken)
			autoStartToken = `autostarttoken=${command.autoStartToken}&`;
		return `bankid:///?${autoStartToken}redirect=${redirectUrl}`;
	}

	public getClientBaseUrl() {
		let url = window.location.protocol + '//'
			+ window.location.hostname;
		if(window.location.port !== '' && window.location.port !== '80')
			url += ':' + window.location.port;
		return url;
	}
}
export class StartBankIdCommand {
	public pollingUrl?: string;
	public returnUrl: string;
	public autoStartToken?: string;
}
