
<div class="backdrop">
	
	<div class="vertical-center spinner-container" [ngClass]="{'hide':!isLoading}">
		<div class="spinner">
			<div class="spinner-left"></div>
			<div class="spinner-right"></div>
		</div>
	</div>

	<div class="vertical-center text-center loader-message fg-contrast" style="margin-top: 60px" [ngClass]="{'hide':!isLoading}">
		{{ message }}
	</div>

	<div [ngClass]="{'hide':isLoading}">

		<ng-content></ng-content>
		
	</div>
</div>
