import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SessionCacheService {
	constructor() {}

	public remove(key: string): void {
		window.sessionStorage.removeItem(key);
	}

	public set(key: string, value: string) {
		window.sessionStorage.setItem(key, value);
	}

	public get(key: string) {
		return window.sessionStorage.getItem(key);
	}
}
