<div class="relative flex items-center gap-x-6 bg-system-yellow px-6 py-2.5 sm:px-3.5 sm:before:flex-1 mb-2 z-50">
	<div class="max-w-5xl text-sm leading-6 md:text-center">
		<div class="text-almost-black">{{ errorMessage }}</div>
	</div>
	<div class="flex justify-end flex-1">
		<button type="button" class="-m-6 sm:-m-3 p-3 focus-visible:outline-offset-[-4px]" (click)="closeBanner()">
			<svg class="w-5 h-5 text-almost-black" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
				<path
					d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
			</svg>
		</button>
	</div>
</div>
