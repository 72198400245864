import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreditProductHttpService } from 'app/api';
import { WithdrawalRequestTermsDto } from 'app/api/model/withdrawalRequestTermsDto';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OrganizationService } from './organization.service';

@Injectable()
export class CreditTermsService {
	private creditTermsSource$ = new BehaviorSubject<WithdrawalRequestTermsDto>(null);

	constructor(
		private creditProductService: CreditProductHttpService,
		private organizationService: OrganizationService
	) {}

	runTerms(creditIntent = false): Observable<WithdrawalRequestTermsDto | HttpErrorResponse> {
		if (!this.creditTermsSource$.value) {
			if(creditIntent)
				this.runTermsWihCreditIntent();
			else
			this.runTermsWithoutCreditIntent();
		}
		return this.creditTermsSource$.asObservable().pipe(filter(terms => !!terms));
	}

	runTermsWihCreditIntent() {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		this.creditProductService.runTermsForCreditWithCreditIntent(organizationId, creditId).subscribe(
			terms => {
				this.creditTermsSource$.next(terms);
			},
			error => {
				this.creditTermsSource$.next(error);
			}
		);
	}

	runTermsWithoutCreditIntent() {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		this.creditProductService.runTermsForCredit(organizationId, creditId).subscribe(
			terms => {
				this.creditTermsSource$.next(terms);
			},
			error => {
				this.creditTermsSource$.next(error);
			}
		);
	}

	clearTerms(): void {
		this.creditTermsSource$.next(null);
	}
}
