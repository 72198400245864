import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ClientAppGuard {
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return (
			environment.bootstrapper.appConfig.clientBaseUrl.includes(location.origin) ||
			environment.bootstrapper.appConfig.clientBaseUrlAlt.includes(location.origin) ||
			location.origin.includes('localhost')
		);
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return (
			environment.bootstrapper.appConfig.clientBaseUrl.includes(location.origin) ||
			environment.bootstrapper.appConfig.clientBaseUrlAlt.includes(location.origin) ||
			location.origin.includes('localhost')
		);
	}
}
