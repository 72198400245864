<div class="flex flex-col justify-between h-full lg:bg-white lg:rounded-2xl">
	<div class="flex flex-col items-start justify-start w-full lg:min-h-80 lg:mx-auto lg:grow-0">
		<div class="flex flex-col items-start justify-start w-full pt-6 lg:mx-auto lg:grow-0">
			<h2 class="pl-4 mb-2" translate="events.title"></h2>
			<div *ngIf="mappedEvents?.length > 0" class="flex flex-col items-center flex-1 w-full">
				<div class="flex-1 w-full" enterViewportNotifier [percentVisible]="25" (visibilityChange)="visibilityChangeHandler($event)">
					<div *ngFor="let event of mappedEvents; let i = index" class="flex flex-col border-b border-light-grey">
						<div (click)="event.expanded = !event.expanded" class="flex flex-col w-full p-4 cursor-pointer">
							<div>
								<div class="flex flex-col text-almost-black" [@expandRows]="event.expanded ? 'expanded' : 'collapsed'">
									<ng-container *ngFor="let field of event.fields; let fieldIndex = index;">
										<div
											class="flex flex-row justify-between "
											*ngIf="(fieldIndex === 0 || event.expanded) && !field.hiddenOnCard"
											[ngClass]="fieldIndex === 0 ? 'text-secondary-400' : 'text-almost-black expanded-row mt-2'">
											<span class="font-bold" [ngClass]="field.classes">{{field.label | translate}}</span>
											<span
												class="text-sm text-right"
												[ngClass]="event.failedEvent && fieldIndex === 0 && event.eventName !== 'CounterOfferEvent' ? 'text-system-red' : field.classes">
												<span *ngIf="!field.formatNumber">
													{{field.value}}
												</span>
												<span *ngIf="field.formatNumber">{{field.value | number:'1.2-2' }}{{field.additionalInfo}}</span>
											</span>
										</div>
										<div *ngIf="fieldIndex === 0" class="flex">
											<p class="text-sm">{{event.eventDateFormatted}}</p>
											<span [@fadeInOut] *ngIf="event.expanded" class="ml-1 text-sm text-medium-grey">• {{event.eventDate | localDate }}</span>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="mappedEvents?.length === 0 && events !== null" class="flex-1 p-common">
				<p translate="events-card.empty.pre"></p>
			</div>
		</div>
		<div *ngIf="events === null" class="flex items-center justify-center flex-1 w-full p-common">
			<app-loader></app-loader>
		</div>
	</div>
	<div class="flex justify-start w-full p-common" *ngIf="mappedEvents?.length > 0">
		<app-button *ngIf="!hideLoadMoreButton" [iconId]="'plus-icon'" [type]="'small'" (action)="clickedMore.emit()" trackCat="navigation-action" trackAction="home.more-events">
			{{ 'events.load-more-button' | translate }}
		</app-button>
		<div *ngIf="hideLoadMoreButton" class="flex justify-center w-full py-4 mb-2 border-light-grey lg:pb-0 lg:border-t">
			<a *ngIf="!loading" [class.pointer-events-none]="!canLoadMore" [class.opacity-50]="!canLoadMore" class="text-lg link" (click)="load.emit()" translate="transactions.more"></a>
			<app-loader *ngIf="loading"></app-loader>
		</div>
	</div>
</div>
