import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
	@Input() type: 'error' | 'alert' | 'info' | 'default' | 'success' = 'info';
	@Input() closable = false;
	@Input() showIcon = true;
	@Output() close = new EventEmitter<void>();
	visible = true;

	constructor() {}

	ngOnInit() {}

	closeNotification() {
		this.visible = false;
		this.close.emit();
	}
}
