import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';
import smartlookClient from 'smartlook-client';

@Injectable()
export class ErrorHandlerService extends SentryErrorHandler {
	constructor() {
		super({ showDialog: false });
	}

	handleError(error: any): void {
		super.handleError(error);
		this.handleErrorInSmartlook(error);
		this.handleChunkLoadError(error);
		console.error(error);
	}

	handleErrorInSmartlook(error: any): void {
		if (smartlookClient.initialized()) smartlookClient.error(JSON.stringify(error));
	}

	handleChunkLoadError(error: any): void {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error.message)) {
			const urlParams = new URLSearchParams(window.location.search);
			if (urlParams.has('reload')) return;
			urlParams.set('reload', 'true');
			window.location.search = urlParams.toString();
			window.location.reload();
		}
	}
}
