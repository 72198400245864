<div *ngIf="contracts?.length > 0" class="w-full min-h-screen bg-white lg:py-4 lg:rounded-lg lg:shadow min-h-128">
	<div *ngFor="let contract of contracts; let i = index" class="flex items-center justify-between px-6 py-4 lg:py-2 lg:border-none lg:tabular-nums">
		<div class="flex items-center">
			<div class="flex items-center justify-center w-8 h-8 mr-2 rounded-full shrink-0 bg-system-green">
				<app-svg-icon class="text-white" width="16" height="16" [iconName]="'file.svg'" [iconId]="'file'"></app-svg-icon>
			</div>
			<div class="flex flex-col lg:flex-row">
				<p class="font-semibold text-almost-black lg:mr-6">{{ contract.date | localDate }}</p>
				<p class="text-almost-black">{{ getContractTitle(contract) }}</p>
			</div>
		</div>
		<button *ngIf="!isContractLoading(contract.id)" class="flex items-center cursor-pointer text-dark-grey hover:text-button-color" (click)="getContract(contract.id)">
			<app-svg-icon class="mr-2" [iconName]="'file-download.svg'" [iconId]="'file-download'"></app-svg-icon>
		</button>
		<app-loader *ngIf="isContractLoading(contract.id)" class="mr-2"></app-loader>
	</div>
</div>
