import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SessionCacheService } from './session-cache.service';
import { LocalStorageService } from './local-storage.service';

export class Facade {
	public isRevenueBased: boolean;
	public name: string;
	public isPartnership: boolean;
	public isIntegratedPartnership: boolean;
	constructor(name: string, isRevenueBased: boolean, isPartnership: boolean, isIntegratedPartnership: boolean) {
		this.isRevenueBased = isRevenueBased;
		this.name = name;
		this.isPartnership = isPartnership;
		this.isIntegratedPartnership = isIntegratedPartnership;
	}
}

@Injectable({
	providedIn: 'root',
})
export class FacadeService {
	private facades = [new Facade('bambora', true, true, true), new Facade('default', false, false, false)];
	private currentFacade: Facade = this.facades[2];
	facadeChanged$ = new BehaviorSubject<Facade>(this.facades[2]);

	constructor(private sessionCache: LocalStorageService) {
		this.loadStoredFacade();
	}

	loadStoredFacade() {
		const previousFacadeName = this.sessionCache.get('$$facade');
		if (this.isValidFacadeName(previousFacadeName)) this.setCurrentFacade(previousFacadeName);
	}

	isDefault(): boolean {
		return this.currentFacade.name === 'default';
	}

	setCurrentFacade(facadeName: string) {
		this.currentFacade = this.getFacadeByName(facadeName);
		this.sessionCache.set('$$facade', facadeName);

		let themeClass = 'theme-' + this.currentFacade.name;
		if (this.currentFacade.isPartnership) themeClass += ' theme-partner';
		window.document.body.className = themeClass;
		this.facadeChanged$.next(this.currentFacade);
	}

	getCurrentFacade(): Facade {
		return this.currentFacade;
	}

	getFacadeByName(name: string): Facade {
		return this.facades.find(item => item.name === name) ?? new Facade(name, false, false, false);
	}

	getFacadePath(): string {
		const facade = this.getCurrentFacade();
		return facade.name + '/'; // Add ending slash to be consistent
	}

	listFacades(): Facade[] {
		return this.facades;
	}

	isValidFacadeName(facadeName: string): boolean {
		return this.getFacadeByName(facadeName) !== undefined;
	}
}
