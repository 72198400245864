<div class="fixed inset-0 z-20 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
	<div
		class="w-full max-w-sm bg-white rounded-lg shadow-2xl pointer-events-auto"
		[ngClass]="show ? 'transform ease-out duration-300 transition translate-y-0 opacity-100 sm:translate-x-0' : 'translate-y-2 sm:translate-y-0 sm:translate-x-2 transition ease-in duration-100 opacity-0'">
		<div class="p-4 overflow-hidden">
			<div class="flex items-start">
				<app-svg-icon *ngIf="type === 'error'" class="mr-2 text-system-yellow" width="18" height="18" [iconName]="'alert-circle.svg'" [iconId]="'alert-circle'"></app-svg-icon>

				<app-svg-icon *ngIf="type === 'success'" class="mr-2 text-system-green" width="18" height="18" [iconName]="'check-circle.svg'" [iconId]="'check-circle'"></app-svg-icon>

				<p class="flex-1 w-0 font-medium leading-5 text-almost-black">
					{{ content | translate }}
				</p>
				<div class="flex ml-4 shrink-0">
					<button (click)="close.emit()" class="inline-flex text-medium-grey transition duration-150 ease-in-out focus:outline-none focus:text-medium-grey">
						<app-svg-icon width="18" height="18" [iconName]="'close.svg'" [iconId]="'close'"></app-svg-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
