import { AsyncValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { CreditProductHttpService } from 'app/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function signatoryAsyncValidator(
	creditProductService: CreditProductHttpService,
	organizationId: string
): AsyncValidatorFn {
	return (group: FormGroup): Observable<ValidationErrors | null> => {
		const allSignatories = group.value;
		const signatories = allSignatories
			.filter(s => s.included)
			.map(s => {
				return {
					board_member_id: s.id || s.board_member_id,
					name: s.name || s.first_name + ' ' + s.last_name,
					email: s.email,
					phone: s.phone,
				};
			});

		return creditProductService.validateSignatories(organizationId, signatories).pipe(
			map(response =>
				response.is_valid
					? null
					: {
							signatoryAsyncValidator: {
								signatory_structure: response.signatory_structure,
								validation_response_code: `kyc.signatories.error.${response.validation_response_code}`,
							},
					  }
			)
		);
	};
}
