import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

export const ssnNorwegianValidator : ValidatorFn[] = [Validators.required, norwegianValidator]

export function norwegianValidator(control: AbstractControl): Record<string, boolean> | null {
	if (validateNorwegianIdNumber(control.value)) {
		return { 'distinct': true };
	}
	return null;
}

export function validateNorwegianIdNumber(value: string): boolean {
    value = value.replace(/\D/g, "");
    if(value.length !== 11) return true
    if (/[^0-9-\s]+/.test(value)) return true;

    const dateOfBirth = getDateOfBirthFromSsn(value)
    const individualDigits = Number(value.slice(6, 9))

    if(!validateDateOfBirth(dateOfBirth)) return true;

    return false
}

export const getDateOfBirthFromSsn = (value: string): Date => {
    // D-number support
    let dayOfBirth = Number(value.slice(0, 2))
    if(dayOfBirth > 31) dayOfBirth -= 40

    // H-number support
    let monthOfBirth = Number(value.slice(2, 4))
    if(monthOfBirth > 40) monthOfBirth -= 40

    let yearOfBirth = Number(value.slice(4, 6))
    yearOfBirth += yearOfBirth > 50 ? 1900 : 2000

    return new Date(`${yearOfBirth}-${monthOfBirth.toString().padStart(2, '0')}-${dayOfBirth.toString().padStart(2, '0')}T00:00:00.000Z`)
}

export const validateDateOfBirth = (dateOfBirth: Date): boolean => dateOfBirth instanceof Date && !isNaN(dateOfBirth.getTime())
