<div class="fixed inset-0 z-50 overflow-y-auto" (click)="closable ? close.emit() : null">
	<div
		class="flex items-center justify-center min-h-screen text-center sm:block sm:p-0"
		[ngClass]="{ 'px-4 pt-4 pb-20' : !fullsizeMobile, 'p-0 sm:px-4 sm:pt-4 sm:pb-20' : fullsizeMobile }">
		<div class="fixed inset-0 transition-opacity" aria-hidden="true" [@fadeInOut]>
			<div class="absolute inset-0 bg-black opacity-25"></div>
		</div>

		<!-- This element is to trick the browser into centering the modal contents. -->
		<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

		<div
			role="dialog"
			aria-modal="true"
			aria-labelledby="modal-headline"
			class="inline-block w-full px-4 pt-5 pb-4 my-8 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:align-middle sm:p-6"
			[ngClass]="{
				'max-w-xl': size === 'xl',
				'max-w-md': size === 'md',
				'opacity-100 translate-y-0 sm:scale-100 ease-out duration-30' : visible,
				'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 ease-in duration-200' : !visible,
				'min-h-screen sm:min-h-fit rounded-none sm:rounded-lg' : fullsizeMobile,
				'rounded-lg' : !fullsizeMobile
			}"
			(click)="$event.stopPropagation()">
			<div *ngIf="closable" class="absolute top-0 right-0 pt-4 pr-4">
				<button type="button" (click)="close.emit()" class="bg-white rounded-md text-medium-grey hover:text-medium-grey focus:outline-none">
					<span class="sr-only">Close</span>
					<app-svg-icon [iconName]="'close.svg'" [iconId]="'close'"></app-svg-icon>
				</button>
			</div>
			<ng-content></ng-content>
		</div>
	</div>
</div>
