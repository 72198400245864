<div [@fadeInOut] class="absolute inset-0 flex items-center justify-center w-screen h-screen">
	<div class="flex flex-col items-center justify-center max-h-[600px] bg-transparent w-full p-4">
		<p class="text-center">
			<span>{{ 'enable-banking.loader.title' | translate }}</span>
			<span class="block font-bold text-almost-black">{{ bankName }}</span>
		</p>

		<div class="w-full max-w-xs h-6 bg-light-grey rounded-[7px] mt-10">
			<div class="h-6 bg-system-blue rounded-[7px] bank-connect-progress"></div>
		</div>
		<div class="absolute bottom-32">
			<app-button class="w-fit" [type]="'small'" [iconId]="'close'" [contentPosition]="'left'" (action)="close.emit()">
				{{ 'enable-banking.loader.cancel' | translate }}
			</app-button>
		</div>
	</div>
</div>
