<div class="px-0 py-4">
	<h2 class="mt-6 mb-2 text-left" translate="onboarding.customer-satisfaction.title"></h2>
	<form *ngIf="!feedbackSubmitted" [formGroup]="feedbackForm">
		<div class="grid grid-cols-1 gap-2 py-4" *ngIf="!ratingCtrl?.value">
			<div *ngFor="let item of feedbackOptions; let i = index">
				<app-list-item
					*ngIf="!ratingCtrl?.value || (item === ratingCtrl?.value)"
					[listItem]="item"
					[isSelected]="item === ratingCtrl?.value"
					[itemStyle]="itemStyle"
					[showDescription]="false"
					(optionClick)="selectOption($event)">
					<div class="w-8 h-8 font-bold leading-7 text-center border-2 bg-light-grey rounded-button border-almost-black">{{ item.rating }}</div>
				</app-list-item>
			</div>
		</div>

		<div class="flex flex-col gap-5 py-4" *ngIf="ratingCtrl?.value">
			<app-list-item class="flex-1" [listItem]="ratingCtrl?.value" [isSelected]="true" [itemStyle]="itemStyle" [showDescription]="false" (optionClick)="unselectOption($event)">
				<div class="w-8 h-8 font-bold leading-7 text-center border-2 bg-dark-grey rounded-button border-light-grey">{{ ratingCtrl?.value.rating }}</div>
			</app-list-item>
		</div>

		<app-textarea
			class="text-left"
			*ngIf="ratingCtrl?.value"
			[placeholder]="'onboarding.customer-satisfaction.comment.placeholder' | translate"
			[rows]="7"
			formControlName="comment"></app-textarea>

		<div *ngIf="ratingCtrl?.value" class="flex mb-2 sm:justify-end">
			<app-button class="w-full sm:w-fit" type="primary" (action)="submitForm()">{{ "onboarding.customer-satisfaction.send-feedback" | translate }}</app-button>
		</div>
	</form>

	<p *ngIf="feedbackSubmitted" class="mt-4 mb-6 text-base text-left text-almost-black" translate="onboarding.customer-satisfaction.feedback-received"></p>
</div>
