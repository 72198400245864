<h2 *ngIf="lenders?.length > 0" class="mb-4 text-xl" translate="lender-list.title"></h2>
<div *ngFor="let lender of lenders; let i = index">
	<div class="flex flex-col">
		<div class="font-bold ">{{lender.value.lender}}</div>
		<div class="flex items-center">
			<div class="flex flex-row items-center w-full p-common">
				<div class="flex flex-col w-1/2">
					<div class="text-sm">{{'lender.how-much' | translate}}</div>
					<div class="pb-1 font-semibold">{{ lender.value.remainingAmount | asMoney:currency:0 }}</div>
				</div>

				<div class="flex flex-col w-1/2">
					<div class="text-sm">{{'lender.remaining-duration' | translate}}</div>
					<div class="pb-1 font-semibold">{{ lender.value.remainingDurationInMonths | months }}</div>
				</div>
			</div>
			<div (click)="removeLender.emit(lender.value)" class="cursor-pointer text-almost-black hover:opacity-70">
				<app-svg-icon [iconName]="'delete-circle.svg'" [iconId]="'delete-circle'"></app-svg-icon>
			</div>
		</div>
	</div>
</div>
