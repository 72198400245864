export const ProductionBootstrapper = {
	appConfig: {
		apiBaseUrl: 'https://api.froda.se',
		authBaseUrl: 'https://auth.froda.se',
		clientBaseUrl: 'https://home.froda.se',
		clientBaseUrlAlt: 'https://my.froda.se',
		contractAppUrl: 'https://contract.froda.se',
		scrapingBaseUrl: 'https://scraping-api.froda.se',
		identityBaseUrl: 'https://identity.froda.se',
		unleashBaseUrl: 'https://eu.app.unleash-hosted.com/euee0006/api/frontend',
		unleashClientKey: '[]:production.e82a3f6f5727fdafe8eb73913063200687825be95331e41619946fbf',
		smartlookClientKey: '7e1c2401d488485e870d669b56401076bf1a232c',
		checkoutPublicKey: 'pk_4v7j2yvns3wek3ftww76dqegja=',
		mockDelay: 0,
		redirectUrl: 'home.froda.se/',
		identityClientId: '44b01022-4147-4c7f-8723-c056df604bf0',
	},
	providers: [],
};
