import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ClearingNumberInterval } from 'app/models/clearingNumberInterval';

const intervals: Record<string, ClearingNumberInterval[]> = {
	swedbank: [
		{ min: 7000, max: 7999 },
		{ min: 80000, max: 89999 },
	],
	seb: [
		{ min: 5000, max: 5999 },
		{ min: 9120, max: 9124 },
		{ min: 9130, max: 9149 },
	],
	handelsbanken: [
		{
			min: 6000,
			max: 6999,
		},
	],
	nordea: [
		{ min: 1100, max: 1199 },
		{ min: 1400, max: 2099 },
		{ min: 3000, max: 3399 },
		{ min: 3410, max: 4999 },
		{ min: 9500, max: 9549 },
		{ min: 9960, max: 9969 },
	],
	sparbanken: [
		{ min: 7000, max: 7999 },
		{ min: 9570, max: 9579 },
		{ min: 80000, max: 89999 },
	],
	danske: [
		{ min: 1200, max: 1399 },
		{ min: 2400, max: 2499 },
		{ min: 9180, max: 9189 },
	],
	forex: [{ min: 9400, max: 9449 }],
	ica: [{ min: 9270, max: 9279 }],
	lans: [
		{ min: 9020, max: 9029 },
		{ min: 3400, max: 3409 },
		{ min: 9060, max: 9069 },
	],
	marginalen: [{ min: 9230, max: 9239 }],
	skandia: [{ min: 9150, max: 9169 }],
	svea: [{ min: 9660, max: 9669 }],
	alands: [{ min: 2300, max: 2399 }],
	dnb: [{ min: 9190, max: 9199 }],
};

export function clearingNumberValidator(identifier: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const clearingNumberIntervals = intervals[identifier.split('-')[0]];

		if (control.value == '') {
			return { required: true };
		}
		const clearingNumber: number = parseInt(control.value);
		if (isNaN(clearingNumber)) {
			return { 'clearingNumber.numeric': true };
		}
		if (control.value.length < 4) {
			return { minlength: true };
		}
		if (!clearingNumberIntervals || clearingNumberIntervals.length === 0) {
			return null;
		}
		const inInterval =
			clearingNumberIntervals.filter(x => clearingNumber >= x.min && clearingNumber <= x.max).length > 0;
		if (!inInterval) {
			return { 'clearingNumber.bank.mismatch': true };
		}
		return null;
	};
}
