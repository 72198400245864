<div
	*ngIf="visible"
	class="border p-common mb-common rounded-notification bg-almost-white"
	[ngClass]="{
  'border-system-red' : type === 'error',
  'border-system-yellow' : type === 'alert',
  'bg-white' : type === 'default'
}">
	<div class="flex items-center justify-center">
		<ng-container *ngIf="showIcon">
			<app-svg-icon *ngIf="type === 'error'" class="mr-1 text-system-red" [iconName]="'alert.svg'" [iconId]="'alert'"></app-svg-icon>

			<div *ngIf="type === 'alert'" class="shrink-0">
				<app-svg-icon class="text-system-yellow" [iconName]="'alert.svg'" [iconId]="'alert'"></app-svg-icon>
			</div>
			<div *ngIf="type === 'info'" class="shrink-0">
				<app-svg-icon class="text-dark-grey" width="16" height="16" [iconName]="'info.svg'" [iconId]="'info'"></app-svg-icon>
			</div>
			<div *ngIf="type === 'success'" class="shrink-0">
				<app-svg-icon class="text-system-green" width="20" height="20" [iconName]="'check-circle.svg'" [iconId]="'check-circle'"></app-svg-icon>
			</div>
		</ng-container>
		<div class="ml-3 text-left">
			<div
				class="text-sm font-medium leading-5"
				[ngClass]="{
          'text-system-red' : type === 'error',
          'text-almost-black' : type === 'alert',
          'text-almost-black' : type === 'info',
          'text-almost-black' : type === 'success',
          'text-almost-black' : type === 'default'}">
				<ng-content></ng-content>
			</div>
		</div>
		<div *ngIf="closable" class="self-start pl-3 ml-auto">
			<div class="-mx-1.5 -my-1.5">
				<button
					(click)="closeNotification()"
					class="inline-flex rounded-md p-1.5 focus:outline-none transition ease-in-out duration-150 text-medium-grey hover:bg-light-grey focus:bg-light-grey">
					<app-svg-icon [iconName]="'close.svg'" [iconId]="'close'"></app-svg-icon>
				</button>
			</div>
		</div>
	</div>
</div>
