<div [ngClass]="{'lg:bg-white': !partner, 'bg-white': partner}" class="flex flex-col justify-between h-full rounded-2xl">
	<div class="flex flex-col items-start justify-start w-full lg:mx-auto lg:grow-0" [ngClass]="{'lg:min-h-80': fixedHeight}">
		<div class="flex flex-col items-start justify-start w-full pt-6 lg:mx-auto lg:grow-0">
			<h2 class="mb-2" [ngClass]="{'pl-common': !partner }" translate="inline-reports.title"></h2>
			<div *ngIf="!hideReports" class="flex-1 w-full">
				<div *ngIf="reports?.length > 0" class="flex-1 w-full">
					<ng-container *ngFor="let report of reports | slice:0:5; let i = index">
						<div
							class="flex items-center justify-between border-b cursor-pointer border-light-grey"
							[ngClass]="{'p-common': !partner, 'py-common': partner}"
							(click)="clickedReport.emit(report.id)">
							<div *ngIf="report.type === 'monthly-report'">
								<p class="text-almost-black">{{'inline-reports.type.monthly' | translate}}</p>
								<p class="text-sm">{{ report?.properties?.ReportStartDate | localDate : 'LLLL yyyy'  }}</p>
							</div>
							<div *ngIf="report.type === 'yearly-report'">
								<p class="text-almost-black">{{'inline-reports.type.yearly' | translate}}</p>
								<p class="text-sm">{{ report?.properties?.ReportStartDate | localDate : 'yyyy'  }}</p>
							</div>
							<app-svg-icon class="text-button-color hover:text-button-color-hover" [iconName]="'file-download.svg'" [iconId]="'file-download'"></app-svg-icon>
						</div>
					</ng-container>
				</div>

				<div *ngIf="reports?.length === 0" [ngClass]="{'p-common': !partner }">
					<p translate="inline-reports.empty.pre"></p>
				</div>
			</div>

			<div *ngIf="hideReports" class="flex flex-col items-start flex-1 max-w-xs" [ngClass]="{'p-common': !partner, 'py-common md:p-common': partner}">
				<p translate="inline-reports.hide.pre"></p>
			</div>
		</div>
		<div *ngIf="!reports" class="flex items-center justify-center flex-1 w-full p-common">
			<app-loader></app-loader>
		</div>
	</div>
	<div *ngIf="reports?.length > 0" class="w-fit" [ngClass]="{'p-common': !partner, 'py-common': partner}">
		<app-button [disabled]="hideReports" [iconId]="'plus-icon'" [type]="'small'" (action)="clickedMore.emit()">{{ 'inline-reports.more' | translate }}</app-button>
	</div>
</div>
