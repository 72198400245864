import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOut } from 'app/animations/smooth.animations';

@Component({
	selector: 'app-enable-banking-loader',
	templateUrl: './enable-banking-loader.component.html',
	styleUrls: ['./enable-banking-loader.component.scss'],
	animations: [fadeInOut],
})
export class EnableBankingLoaderComponent implements OnInit {
	@Input() bankName: string;
	@Output() close = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}
}
