import { ValidatorFn, Validators } from '@angular/forms';

//Phone validator for mobile phone number that is entered through inputs
//Validates Danish mobile phone numbers
//eg. +45 77 22 33 44

export const danishMobilePhoneValidator: ValidatorFn[] = [
	Validators.required,
	Validators.pattern(/^((\(?\+45\)?)?)(\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2})$/),
];
