import { Injectable } from '@angular/core';
import { CountryISOCode } from 'app/api';
import {
	DANISH_PHONE_PREFIX,
	GERMAN_PHONE_PREFIX,
	SWEDISH_PHONE_PREFIX,
} from 'app/white-label-client/white-label-client.constants';

@Injectable({
	providedIn: 'root',
})
export class SanitationService {
	constructor() {}

	//eg. from 070 123 4567 to +46701234567
	sanetizePhoneNumber(phoneNumber: string, prefix: string = SWEDISH_PHONE_PREFIX) {
		if (!phoneNumber) return null;

		if (phoneNumber.charAt(0) === '+') return phoneNumber;

		phoneNumber = phoneNumber.replace(/\D/g, '');

		if (phoneNumber.startsWith('0')) phoneNumber = phoneNumber.substring(1);

		return prefix + phoneNumber;
	}

	//adds spaces between digits in phone number
	maskPhoneNumber(phone: string, prefix: string = SWEDISH_PHONE_PREFIX) {
		if (!phone) return null;
		// for Swedish number
		else if (prefix === SWEDISH_PHONE_PREFIX) {
			let newVal = phone.replace(/\D/g, '');
			if (newVal.length === 0) {
				newVal = '';
			} else if (newVal.length <= 3) {
				newVal = newVal.replace(/^(\d{0,3})/, '$1');
			} else if (newVal.length <= 5) {
				newVal = newVal.replace(/^(\d{0,3})(\d{0,2})/, '$1 $2');
			} else if (newVal.length <= 7) {
				newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 $2 $3');
			} else if (newVal.length <= 9) {
				newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			} else if (newVal.length === 10 && /^07\d{8}$/.test(newVal)) {
				newVal = newVal.substring(1, 10);
				newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			} else if (newVal.length === 11 && /^467\d{8}$/.test(newVal)) {
				newVal = newVal.substring(2, 11);
				newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			} else {
				newVal = newVal.substring(0, 9);
				newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			}
			return newVal;
			// for Danish number
		} else if (prefix === DANISH_PHONE_PREFIX) {
			let newVal = phone.replace(/\D/g, '');
			if (newVal.length === 0) {
				newVal = '';
			} else if (newVal.length <= 2) {
				newVal = newVal.replace(/^(\d{0,2})/, '$1');
			} else if (newVal.length <= 4) {
				newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1 $2');
			} else if (newVal.length <= 6) {
				newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3');
			} else if (newVal.length <= 8) {
				newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			} else if (newVal.length === 9) {
				newVal = newVal.substring(1, 9);
				newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			} else if (newVal.length === 10) {
				newVal = newVal.substring(2, 10);
				newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			} else {
				newVal = newVal.substring(0, 8);
				newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4');
			}
			return newVal;
			// for German number
		} else if (prefix === GERMAN_PHONE_PREFIX) {
			let newVal = phone.replace(/\D/g, '');
			if (newVal.length === 0) {
				newVal = '';
			} else if (newVal.length <= 2) {
				newVal = newVal.replace(/^(\d{0,2})/, '$1');
			} else if (newVal.length <= 5) {
				newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1 $2');
			} else if (newVal.length <= 8) {
				newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})/, '$1 $2 $3');
			} else if (newVal.length <= 10) {
				newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1 $2 $3 $4');
			} else if (newVal.length === 11) {
				newVal = newVal.substring(0, 10);
				newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1 $2 $3 $4');
			} else {
				newVal = newVal.substring(0, 10);
				newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1 $2 $3 $4');
			}
			return newVal;
		}
	}
}
