import { Component, Input, OnInit } from '@angular/core';
import { OrganizationEventDto } from 'app/api';

@Component({
	selector: 'app-event-item',
	templateUrl: './event-item.component.html',
	styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnInit {
	@Input() event: OrganizationEventDto;

	constructor() {}

	ngOnInit(): void {}
}
