import { Injectable } from '@angular/core';
import { CreditProductHttpService, PaymentPlanChangeRequestDto, RequestType } from 'app/api';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay } from 'rxjs/operators';
import { OrganizationService } from '.';

@Injectable()
export class PpcrService {
	pendingPause$: Observable<PaymentPlanChangeRequestDto>;
	enactedPause$: Observable<PaymentPlanChangeRequestDto>;
	enactedPauseStartDate$: Observable<string>;
	enactedPauseEndDate$: Observable<string>;
	rejectedPause$: Observable<PaymentPlanChangeRequestDto>;
	rejectedPauseStartDate$: Observable<string>;
	rejectedPauseEndDate$: Observable<string>;
	pendingRateChange$: Observable<PaymentPlanChangeRequestDto>;
	enactedRateChange$: Observable<PaymentPlanChangeRequestDto>;
	rejectedRateChange$: Observable<PaymentPlanChangeRequestDto>;

	constructor(
		private creditProductService: CreditProductHttpService,
		private organizationService: OrganizationService
	) {
		const id = this.organizationService.getOrganizationId();

		const pauseRequest$ = this.creditProductService.getPaymentPlanChangeRequest(id, RequestType.AmortizationPause).pipe(
			catchError(() => of(null)),
			filter(request => !!request),
			shareReplay(1)
		);

		this.pendingPause$ = pauseRequest$.pipe(
			filter(
				request => request.status === 'approved' || request.status === 'awaiting_review' || request.status === 'draft'
			)
		);

		this.enactedPause$ = pauseRequest$.pipe(
			filter(request => request.status === 'enacted'),
			filter(request => new Date().getTime() < new Date(request.start_date).getTime())
		);

		this.enactedPauseStartDate$ = this.enactedPause$.pipe(
			map(
				enactedPause =>
					enactedPause.payment_schedule.fixed_periods[enactedPause.payment_schedule.fixed_periods.length - 1].start_date
			)
		);

		this.enactedPauseEndDate$ = this.enactedPause$.pipe(
			map(
				enactedPause =>
					enactedPause.payment_schedule.fixed_periods[enactedPause.payment_schedule.fixed_periods.length - 1].end_date
			)
		);

		this.rejectedPause$ = pauseRequest$.pipe(
			filter(request => request.status === 'rejected_by_froda'),
			filter(request => this.lessThanNWeeksAgo(request.created_date, 2)),
			filter(request => new Date().getTime() < new Date(request.start_date).getTime()),
			filter(request => window.localStorage.getItem('pause-dismissed') !== request.id)
		);

		this.rejectedPauseStartDate$ = this.rejectedPause$.pipe(
			map(
				rejectedPause =>
					rejectedPause.payment_schedule.fixed_periods[rejectedPause.payment_schedule.fixed_periods.length - 1]
						.start_date
			)
		);

		this.rejectedPauseEndDate$ = this.rejectedPause$.pipe(
			map(
				rejectedPause =>
					rejectedPause.payment_schedule.fixed_periods[rejectedPause.payment_schedule.fixed_periods.length - 1].end_date
			)
		);

		const rateChangeRequest$ = this.creditProductService
			.getPaymentPlanChangeRequest(id, RequestType.RepaymentRateChange)
			.pipe(
				catchError(() => of(null)),
				filter(request => !!request),
				shareReplay(1)
			);

		this.pendingRateChange$ = rateChangeRequest$.pipe(
			filter(
				request => request.status === 'approved' || request.status === 'awaiting_review' || request.status === 'draft'
			)
		);

		this.enactedRateChange$ = rateChangeRequest$.pipe(
			filter(request => request.status === 'enacted'),
			filter(request => this.lessThanNWeeksAgo(request.start_date, 1)),
			filter(request => window.localStorage.getItem('rate-change-dismissed') !== request.id)
		);

		this.rejectedRateChange$ = rateChangeRequest$.pipe(
			filter(request => request.status === 'rejected_by_froda'),
			filter(request => this.lessThanNWeeksAgo(request.created_date, 2)),
			filter(request => window.localStorage.getItem('rate-change-dismissed') !== request.id)
		);
	}

	private lessThanNWeeksAgo(date: string, n: number): boolean {
		const todayDate = new Date();
		const requestDate = new Date(date);
		const daysBetween = (todayDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60 * 24);
		return daysBetween <= 7 * n;
	}
}
