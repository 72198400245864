import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoanPurposeDto, LoanPurposeOptionDto, PurposesHttpService } from 'app/api';
import { freeTextValidator } from 'app/shared/validators';
import { Observable, Subject, from } from 'rxjs';
import { map, shareReplay, switchMap, toArray } from 'rxjs/operators';

@Component({
	selector: 'app-loan-purpose-dialog',
	templateUrl: './loan-purpose-dialog.component.html',
	styleUrls: ['./loan-purpose-dialog.component.scss'],
})
export class LoanPurposeDialogComponent implements OnInit, OnDestroy {
	purposeDialogForm: FormGroup;
	purposes$: Observable<LoanPurposeOptionDto[]>;
	protected readonly destroy$ = new Subject();
	@Input() purpose: LoanPurposeDto;
	@Output() handleButtonClicked = new EventEmitter<{ isFormValid: boolean; data: LoanPurposeDto }>();

	constructor(
		private formBuilder: FormBuilder,
		private translateService: TranslateService,
		private purposeHttpService: PurposesHttpService
	) {}

	ngOnInit(): void {
		this.loadData();
	}

	loadData() {
		this.purposes$ = this.purposeHttpService.getLoanPurposes().pipe(
			switchMap(purposes =>
				from(purposes).pipe(
					map(purpose => {
						return {
							...purpose,
							text: 'purpose.' + purpose.code,
							description: 'loan-purpose.description.' + 'purpose.' + purpose.code,
						};
					}),
					toArray()
				)
			),
			shareReplay()
		);

		this.initializeForm();
	}

	initializeForm() {
		this.purposeDialogForm = this.formBuilder.group({
			freeText: [this.purpose.answers[0]?.answer ?? '', { validators: [freeTextValidator()], updateOn: 'blur' }],
		});
	}

	get freeText(): AbstractControl {
		return this.purposeDialogForm.get('freeText');
	}

	validateForm(): boolean {
		this.purposeDialogForm.markAllAsTouched();
		return this.purposeDialogForm.valid;
	}

	returnData(): LoanPurposeDto {
		const loanPurpose = {
			code: 'free-text',
			text: this.translateService.instant('purpose.question.free-text'),
			answers: [
				{
					question_code: 'free-text',
					question_text: this.translateService.instant('purpose.question.free-text'),
					answer: this.freeText.value,
				},
			],
		};

		return loanPurpose;
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
