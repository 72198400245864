export const DANISH_PHONE_PREFIX = '+45';
export const GERMAN_PHONE_PREFIX = '+49';
export const SWEDISH_PHONE_PREFIX = '+46';
export const KONTIST_PARTNER_KEY = 'kontist';
export const VISMA_INVOICE_PARTNER_KEY = 'visma-invoice';
export const VISMA_INVOICE_PARTNER_ID = 'visma-invoice-financing';

//Unleash feature flags
export const WL_APP_ERROR = 'WhiteLabelApplicationError';
export const WL_HIDE_REPORTS = 'WhiteLabelHideReports';
export const WL_HIDE_TOPUPS = 'WhiteLabelHideTopups';
export const WL_HIDE_SIGNED_CONTRACTS = 'WhiteLabelHideSignedContracts';
export const WL_HIDE_TRANSACTIONS = 'WhiteLabelHideTransactions';
export const ADD_CHANGE_CARD = 'AddChangeCard';
export const CARD_BASED_LENDING = 'CardBasedLending';
export const NATIVE_WL_PAYOUT_ACCOUNTS = 'NativeWLPayoutAccounts';
export const NEW_WHITELABEL_SELFSERVICE = 'NewWhitelabelSelfservice';
