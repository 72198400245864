import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureVariant } from 'app/models/featureVariant';
import { FeatureFlagsService } from 'app/services/feature-flags.service';

@Directive({
	selector: '[featureVariant]',
})
export class FeatureVariantDirective {
	constructor(
		private vcRef: ViewContainerRef,
		private templateRef: TemplateRef<any>,
		private featureFlagsService: FeatureFlagsService
	) {}

	@Input() set featureVariant(feature: FeatureVariant) {
		if (
			this.featureFlagsService.flagsLoaded() &&
			this.featureFlagsService.isFlagEnabled(feature.flagName) &&
			this.featureFlagsService.getVariant(feature.flagName).name === feature.variantName
		) {
			this.vcRef.createEmbeddedView(this.templateRef);
		} else if (
			(!this.featureFlagsService.flagsLoaded() || !this.featureFlagsService.isFlagLoaded(feature.flagName)) &&
			feature.fallbackVersion
		) {
			this.vcRef.createEmbeddedView(this.templateRef);
		}
	}
}
