export enum EventName {
	payment = 'PaymentEvent',
	finalPayment = 'FinalPayment',
	extraPayment = 'ExtraPayment',
	amortizationPause = 'PausePayment',
	increasePayment = 'IncreasePayment',
	decreasePayment = 'DecreasePayment',
	increaseLoan = 'IncreaseLoan',
	newLoan = 'NewLoan',
	counterOffer = 'CounterOfferEvent',
}
