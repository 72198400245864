import { Injectable, Injector } from '@angular/core';
import { SentryService } from '@services';
import { WhiteLabelClientSettings } from 'app/models/whiteLabelClientSettings';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { defaultIfEmpty, filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class WhiteLabelClientSettingsService {
	constructor(private injector: Injector, private sentryService: SentryService) {}
	partnerSettings$ = new BehaviorSubject<WhiteLabelClientSettings>(null);

	//used in APP_INITIALIZER token to load partner settings when app is bootstrapped
	//can be used in other places with partnerKey parameter to load partner settings (for example login-impersonate component)
	public loadPartnerSettings(partnerKey?: string): Observable<WhiteLabelClientSettings> {
		//load partner key from url if not provided
		const partnerKeyFromUrl = window.location.pathname.split('/')[2];
		const isWhiteLabelRoute = window.location.pathname.split('/')[1] === 'p';
		if (isWhiteLabelRoute && !partnerKeyFromUrl) {
			console.log('PartnerSetting null issue', window.location.pathname);
			this.sentryService.captureMessage(`PartnerSetting null issue ${partnerKeyFromUrl}`, 'warning');
		}
		if (!partnerKey && isWhiteLabelRoute && !!partnerKeyFromUrl) {
			partnerKey = partnerKeyFromUrl;
		}
		if (partnerKey) {
			return from(
				import(`app/white-label-client-settings/${partnerKey}-client-settings.service`).catch(error => {
					this.sentryService.addBreadcrumb('PartnerSetting import issue', 'white-label-client-settings', error.message);
					this.sentryService.captureMessage('PartnerSetting import issue for partnerKey: ' + partnerKey);
					return null;
				})
			).pipe(
				filter(m => !!m),
				map(m => m.default),
				map(lazyService => {
					const service = this.injector.get(lazyService);
					const settings = service.getSettings();
					this.partnerSettings$.next(settings);
					return settings;
				}),
				defaultIfEmpty(null)
			);
		} else {
			return of(null);
		}
	}

	public getSettings(): WhiteLabelClientSettings {
		return this.partnerSettings$.getValue();
	}
}
