/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PepType = 'notPep' | 'pep' | 'pepRelative' | 'pepCloseAssociate';

export const PepType = {
    NotPep: 'notPep' as PepType,
    Pep: 'pep' as PepType,
    PepRelative: 'pepRelative' as PepType,
    PepCloseAssociate: 'pepCloseAssociate' as PepType
};

