import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MoreSniOptions } from 'app/models/moreSniOptions';
import { SniOption } from 'app/models/sniOption';
import { Subject } from 'rxjs';
@Component({
	selector: 'app-sni-options-modal',
	templateUrl: './sni-options-modal.component.html',
	styleUrl: './sni-options-modal.component.scss',
})
export class SniOptionsModalComponent implements OnInit, OnDestroy {
	@Input() visible = false;
	@Input() sniOptions: MoreSniOptions[];
	@Output() closeModal = new EventEmitter<void>();
	@Output() addNewSniOption = new EventEmitter<SniOption>();

	protected readonly destroy$ = new Subject();
	selectSubIndustryForm: FormGroup;
	selectedIndustrySubIndustries: SniOption[] = [];
	industrySelected = false;

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.initializeForm();
	}

	initializeForm() {
		this.selectSubIndustryForm = this.formBuilder.group({
			selectedSubIndustry: ['', { validators: [Validators.required] }],
		});
	}

	onSelectIndustry(industryIndex: number) {
		this.sniOptions[industryIndex].subIndustries.forEach(subIndustry => {
			this.selectedIndustrySubIndustries.push(subIndustry);
		});
		this.industrySelected = true;
	}

	addSNIOption() {
		this.selectSubIndustryForm.markAllAsTouched();
		if (this.selectSubIndustryForm.get('selectedSubIndustry').value) {
			this.addNewSniOption.emit(this.selectSubIndustryForm.get('selectedSubIndustry').value);
			this.close();
		}
	}

	close() {
		this.selectedIndustrySubIndustries = [];
		this.industrySelected = false;
		this.closeModal.emit();
	}

	back() {
		this.selectedIndustrySubIndustries = [];
		this.industrySelected = false;
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
