/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CountryISOCode = 'NONE' | 'AF' | 'AL' | 'AQ' | 'DZ' | 'AS' | 'AD' | 'AO' | 'AG' | 'AZ' | 'AR' | 'AU' | 'AT' | 'BS' | 'BH' | 'BD' | 'AM' | 'BB' | 'BE' | 'BM' | 'BT' | 'BO' | 'BA' | 'BW' | 'BV' | 'BR' | 'BZ' | 'IO' | 'SB' | 'VG' | 'BN' | 'BG' | 'MM' | 'BI' | 'BY' | 'KH' | 'CM' | 'CA' | 'CV' | 'KY' | 'CF' | 'LK' | 'TD' | 'CL' | 'CN' | 'TW' | 'CX' | 'CC' | 'CO' | 'KM' | 'YT' | 'CG' | 'CD' | 'CK' | 'CR' | 'HR' | 'CU' | 'CY' | 'CZ' | 'BJ' | 'DK' | 'DM' | 'DO' | 'EC' | 'SV' | 'GQ' | 'ET' | 'ER' | 'EE' | 'FO' | 'FK' | 'GS' | 'FJ' | 'FI' | 'AX' | 'FR' | 'GF' | 'PF' | 'TF' | 'DJ' | 'GA' | 'GE' | 'GM' | 'PS' | 'DE' | 'GH' | 'GI' | 'KI' | 'GR' | 'GL' | 'GD' | 'GP' | 'GU' | 'GT' | 'GN' | 'GY' | 'HT' | 'HM' | 'VA' | 'HN' | 'HK' | 'HU' | 'IS' | 'IN' | 'ID' | 'IR' | 'IQ' | 'IE' | 'IL' | 'IT' | 'CI' | 'JM' | 'JP' | 'KZ' | 'JO' | 'KE' | 'KP' | 'KR' | 'KW' | 'KG' | 'LA' | 'LB' | 'LS' | 'LV' | 'LR' | 'LY' | 'LI' | 'LT' | 'LU' | 'MO' | 'MG' | 'MW' | 'MY' | 'MV' | 'ML' | 'MT' | 'MQ' | 'MR' | 'MU' | 'MX' | 'MC' | 'MN' | 'MD' | 'ME' | 'MS' | 'MA' | 'MZ' | 'OM' | 'NA' | 'NR' | 'NP' | 'NL' | 'CW' | 'AW' | 'SX' | 'BQ' | 'NC' | 'VU' | 'NZ' | 'NI' | 'NE' | 'NG' | 'NU' | 'NF' | 'NO' | 'MP' | 'UM' | 'FM' | 'MH' | 'PW' | 'PK' | 'PA' | 'PG' | 'PY' | 'PE' | 'PH' | 'PN' | 'PL' | 'PT' | 'GW' | 'TL' | 'PR' | 'QA' | 'RE' | 'RO' | 'RU' | 'RW' | 'BL' | 'SH' | 'KN' | 'AI' | 'LC' | 'MF' | 'PM' | 'VC' | 'SM' | 'ST' | 'SA' | 'SN' | 'RS' | 'SC' | 'SL' | 'SG' | 'SK' | 'VN' | 'SI' | 'SO' | 'ZA' | 'ZW' | 'ES' | 'SS' | 'SD' | 'EH' | 'SR' | 'SJ' | 'SZ' | 'SE' | 'CH' | 'SY' | 'TJ' | 'TH' | 'TG' | 'TK' | 'TO' | 'TT' | 'AE' | 'TN' | 'TR' | 'TM' | 'TC' | 'TV' | 'UG' | 'UA' | 'MK' | 'EG' | 'GB' | 'GG' | 'JE' | 'IM' | 'TZ' | 'US' | 'VI' | 'BF' | 'UY' | 'UZ' | 'VE' | 'WF' | 'WS' | 'YE' | 'ZM';

export const CountryISOCode = {
    NONE: 'NONE' as CountryISOCode,
    AF: 'AF' as CountryISOCode,
    AL: 'AL' as CountryISOCode,
    AQ: 'AQ' as CountryISOCode,
    DZ: 'DZ' as CountryISOCode,
    AS: 'AS' as CountryISOCode,
    AD: 'AD' as CountryISOCode,
    AO: 'AO' as CountryISOCode,
    AG: 'AG' as CountryISOCode,
    AZ: 'AZ' as CountryISOCode,
    AR: 'AR' as CountryISOCode,
    AU: 'AU' as CountryISOCode,
    AT: 'AT' as CountryISOCode,
    BS: 'BS' as CountryISOCode,
    BH: 'BH' as CountryISOCode,
    BD: 'BD' as CountryISOCode,
    AM: 'AM' as CountryISOCode,
    BB: 'BB' as CountryISOCode,
    BE: 'BE' as CountryISOCode,
    BM: 'BM' as CountryISOCode,
    BT: 'BT' as CountryISOCode,
    BO: 'BO' as CountryISOCode,
    BA: 'BA' as CountryISOCode,
    BW: 'BW' as CountryISOCode,
    BV: 'BV' as CountryISOCode,
    BR: 'BR' as CountryISOCode,
    BZ: 'BZ' as CountryISOCode,
    IO: 'IO' as CountryISOCode,
    SB: 'SB' as CountryISOCode,
    VG: 'VG' as CountryISOCode,
    BN: 'BN' as CountryISOCode,
    BG: 'BG' as CountryISOCode,
    MM: 'MM' as CountryISOCode,
    BI: 'BI' as CountryISOCode,
    BY: 'BY' as CountryISOCode,
    KH: 'KH' as CountryISOCode,
    CM: 'CM' as CountryISOCode,
    CA: 'CA' as CountryISOCode,
    CV: 'CV' as CountryISOCode,
    KY: 'KY' as CountryISOCode,
    CF: 'CF' as CountryISOCode,
    LK: 'LK' as CountryISOCode,
    TD: 'TD' as CountryISOCode,
    CL: 'CL' as CountryISOCode,
    CN: 'CN' as CountryISOCode,
    TW: 'TW' as CountryISOCode,
    CX: 'CX' as CountryISOCode,
    CC: 'CC' as CountryISOCode,
    CO: 'CO' as CountryISOCode,
    KM: 'KM' as CountryISOCode,
    YT: 'YT' as CountryISOCode,
    CG: 'CG' as CountryISOCode,
    CD: 'CD' as CountryISOCode,
    CK: 'CK' as CountryISOCode,
    CR: 'CR' as CountryISOCode,
    HR: 'HR' as CountryISOCode,
    CU: 'CU' as CountryISOCode,
    CY: 'CY' as CountryISOCode,
    CZ: 'CZ' as CountryISOCode,
    BJ: 'BJ' as CountryISOCode,
    DK: 'DK' as CountryISOCode,
    DM: 'DM' as CountryISOCode,
    DO: 'DO' as CountryISOCode,
    EC: 'EC' as CountryISOCode,
    SV: 'SV' as CountryISOCode,
    GQ: 'GQ' as CountryISOCode,
    ET: 'ET' as CountryISOCode,
    ER: 'ER' as CountryISOCode,
    EE: 'EE' as CountryISOCode,
    FO: 'FO' as CountryISOCode,
    FK: 'FK' as CountryISOCode,
    GS: 'GS' as CountryISOCode,
    FJ: 'FJ' as CountryISOCode,
    FI: 'FI' as CountryISOCode,
    AX: 'AX' as CountryISOCode,
    FR: 'FR' as CountryISOCode,
    GF: 'GF' as CountryISOCode,
    PF: 'PF' as CountryISOCode,
    TF: 'TF' as CountryISOCode,
    DJ: 'DJ' as CountryISOCode,
    GA: 'GA' as CountryISOCode,
    GE: 'GE' as CountryISOCode,
    GM: 'GM' as CountryISOCode,
    PS: 'PS' as CountryISOCode,
    DE: 'DE' as CountryISOCode,
    GH: 'GH' as CountryISOCode,
    GI: 'GI' as CountryISOCode,
    KI: 'KI' as CountryISOCode,
    GR: 'GR' as CountryISOCode,
    GL: 'GL' as CountryISOCode,
    GD: 'GD' as CountryISOCode,
    GP: 'GP' as CountryISOCode,
    GU: 'GU' as CountryISOCode,
    GT: 'GT' as CountryISOCode,
    GN: 'GN' as CountryISOCode,
    GY: 'GY' as CountryISOCode,
    HT: 'HT' as CountryISOCode,
    HM: 'HM' as CountryISOCode,
    VA: 'VA' as CountryISOCode,
    HN: 'HN' as CountryISOCode,
    HK: 'HK' as CountryISOCode,
    HU: 'HU' as CountryISOCode,
    IS: 'IS' as CountryISOCode,
    IN: 'IN' as CountryISOCode,
    ID: 'ID' as CountryISOCode,
    IR: 'IR' as CountryISOCode,
    IQ: 'IQ' as CountryISOCode,
    IE: 'IE' as CountryISOCode,
    IL: 'IL' as CountryISOCode,
    IT: 'IT' as CountryISOCode,
    CI: 'CI' as CountryISOCode,
    JM: 'JM' as CountryISOCode,
    JP: 'JP' as CountryISOCode,
    KZ: 'KZ' as CountryISOCode,
    JO: 'JO' as CountryISOCode,
    KE: 'KE' as CountryISOCode,
    KP: 'KP' as CountryISOCode,
    KR: 'KR' as CountryISOCode,
    KW: 'KW' as CountryISOCode,
    KG: 'KG' as CountryISOCode,
    LA: 'LA' as CountryISOCode,
    LB: 'LB' as CountryISOCode,
    LS: 'LS' as CountryISOCode,
    LV: 'LV' as CountryISOCode,
    LR: 'LR' as CountryISOCode,
    LY: 'LY' as CountryISOCode,
    LI: 'LI' as CountryISOCode,
    LT: 'LT' as CountryISOCode,
    LU: 'LU' as CountryISOCode,
    MO: 'MO' as CountryISOCode,
    MG: 'MG' as CountryISOCode,
    MW: 'MW' as CountryISOCode,
    MY: 'MY' as CountryISOCode,
    MV: 'MV' as CountryISOCode,
    ML: 'ML' as CountryISOCode,
    MT: 'MT' as CountryISOCode,
    MQ: 'MQ' as CountryISOCode,
    MR: 'MR' as CountryISOCode,
    MU: 'MU' as CountryISOCode,
    MX: 'MX' as CountryISOCode,
    MC: 'MC' as CountryISOCode,
    MN: 'MN' as CountryISOCode,
    MD: 'MD' as CountryISOCode,
    ME: 'ME' as CountryISOCode,
    MS: 'MS' as CountryISOCode,
    MA: 'MA' as CountryISOCode,
    MZ: 'MZ' as CountryISOCode,
    OM: 'OM' as CountryISOCode,
    NA: 'NA' as CountryISOCode,
    NR: 'NR' as CountryISOCode,
    NP: 'NP' as CountryISOCode,
    NL: 'NL' as CountryISOCode,
    CW: 'CW' as CountryISOCode,
    AW: 'AW' as CountryISOCode,
    SX: 'SX' as CountryISOCode,
    BQ: 'BQ' as CountryISOCode,
    NC: 'NC' as CountryISOCode,
    VU: 'VU' as CountryISOCode,
    NZ: 'NZ' as CountryISOCode,
    NI: 'NI' as CountryISOCode,
    NE: 'NE' as CountryISOCode,
    NG: 'NG' as CountryISOCode,
    NU: 'NU' as CountryISOCode,
    NF: 'NF' as CountryISOCode,
    NO: 'NO' as CountryISOCode,
    MP: 'MP' as CountryISOCode,
    UM: 'UM' as CountryISOCode,
    FM: 'FM' as CountryISOCode,
    MH: 'MH' as CountryISOCode,
    PW: 'PW' as CountryISOCode,
    PK: 'PK' as CountryISOCode,
    PA: 'PA' as CountryISOCode,
    PG: 'PG' as CountryISOCode,
    PY: 'PY' as CountryISOCode,
    PE: 'PE' as CountryISOCode,
    PH: 'PH' as CountryISOCode,
    PN: 'PN' as CountryISOCode,
    PL: 'PL' as CountryISOCode,
    PT: 'PT' as CountryISOCode,
    GW: 'GW' as CountryISOCode,
    TL: 'TL' as CountryISOCode,
    PR: 'PR' as CountryISOCode,
    QA: 'QA' as CountryISOCode,
    RE: 'RE' as CountryISOCode,
    RO: 'RO' as CountryISOCode,
    RU: 'RU' as CountryISOCode,
    RW: 'RW' as CountryISOCode,
    BL: 'BL' as CountryISOCode,
    SH: 'SH' as CountryISOCode,
    KN: 'KN' as CountryISOCode,
    AI: 'AI' as CountryISOCode,
    LC: 'LC' as CountryISOCode,
    MF: 'MF' as CountryISOCode,
    PM: 'PM' as CountryISOCode,
    VC: 'VC' as CountryISOCode,
    SM: 'SM' as CountryISOCode,
    ST: 'ST' as CountryISOCode,
    SA: 'SA' as CountryISOCode,
    SN: 'SN' as CountryISOCode,
    RS: 'RS' as CountryISOCode,
    SC: 'SC' as CountryISOCode,
    SL: 'SL' as CountryISOCode,
    SG: 'SG' as CountryISOCode,
    SK: 'SK' as CountryISOCode,
    VN: 'VN' as CountryISOCode,
    SI: 'SI' as CountryISOCode,
    SO: 'SO' as CountryISOCode,
    ZA: 'ZA' as CountryISOCode,
    ZW: 'ZW' as CountryISOCode,
    ES: 'ES' as CountryISOCode,
    SS: 'SS' as CountryISOCode,
    SD: 'SD' as CountryISOCode,
    EH: 'EH' as CountryISOCode,
    SR: 'SR' as CountryISOCode,
    SJ: 'SJ' as CountryISOCode,
    SZ: 'SZ' as CountryISOCode,
    SE: 'SE' as CountryISOCode,
    CH: 'CH' as CountryISOCode,
    SY: 'SY' as CountryISOCode,
    TJ: 'TJ' as CountryISOCode,
    TH: 'TH' as CountryISOCode,
    TG: 'TG' as CountryISOCode,
    TK: 'TK' as CountryISOCode,
    TO: 'TO' as CountryISOCode,
    TT: 'TT' as CountryISOCode,
    AE: 'AE' as CountryISOCode,
    TN: 'TN' as CountryISOCode,
    TR: 'TR' as CountryISOCode,
    TM: 'TM' as CountryISOCode,
    TC: 'TC' as CountryISOCode,
    TV: 'TV' as CountryISOCode,
    UG: 'UG' as CountryISOCode,
    UA: 'UA' as CountryISOCode,
    MK: 'MK' as CountryISOCode,
    EG: 'EG' as CountryISOCode,
    GB: 'GB' as CountryISOCode,
    GG: 'GG' as CountryISOCode,
    JE: 'JE' as CountryISOCode,
    IM: 'IM' as CountryISOCode,
    TZ: 'TZ' as CountryISOCode,
    US: 'US' as CountryISOCode,
    VI: 'VI' as CountryISOCode,
    BF: 'BF' as CountryISOCode,
    UY: 'UY' as CountryISOCode,
    UZ: 'UZ' as CountryISOCode,
    VE: 'VE' as CountryISOCode,
    WF: 'WF' as CountryISOCode,
    WS: 'WS' as CountryISOCode,
    YE: 'YE' as CountryISOCode,
    ZM: 'ZM' as CountryISOCode
};

