<div class="flex flex-col max-w-3xl mx-auto">
	<div class="flex flex-col justify-between flex-1 bg-white sm:rounded-box">
		<div>
			<div class="flex flex-col gap-common py-common">
				<h1 translate="application.loan-purpose-dialog.title"></h1>
				<p translate="application.loan-purpose-dialog.pre"></p>
			</div>

			<form *ngIf="purposeDialogForm" [formGroup]="purposeDialogForm">
				<app-textarea formControlName="freeText" [rows]="5"
					[placeholder]="'application.loan-purpose-dialog.free-text.placeholder' | translate"></app-textarea>
			</form>
		</div>
	</div>
</div>