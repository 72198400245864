/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Channel = 'credit' | 'partnerBambora' | 'partner' | 'referral' | 'loanApplication' | 'default' | 'factoring' | 'campaign' | 'manual' | 'onboarding' | 'frodaAccount' | 'frodaApplication' | 'frodaOffer' | 'faasObjectFinancing' | 'faasMerchantCashAdvance' | 'faasRegular' | 'monitorMigration' | 'broker' | 'discount' | 'customizedLending';

export const Channel = {
    Credit: 'credit' as Channel,
    PartnerBambora: 'partnerBambora' as Channel,
    Partner: 'partner' as Channel,
    Referral: 'referral' as Channel,
    LoanApplication: 'loanApplication' as Channel,
    Default: 'default' as Channel,
    Factoring: 'factoring' as Channel,
    Campaign: 'campaign' as Channel,
    Manual: 'manual' as Channel,
    Onboarding: 'onboarding' as Channel,
    FrodaAccount: 'frodaAccount' as Channel,
    FrodaApplication: 'frodaApplication' as Channel,
    FrodaOffer: 'frodaOffer' as Channel,
    FaasObjectFinancing: 'faasObjectFinancing' as Channel,
    FaasMerchantCashAdvance: 'faasMerchantCashAdvance' as Channel,
    FaasRegular: 'faasRegular' as Channel,
    MonitorMigration: 'monitorMigration' as Channel,
    Broker: 'broker' as Channel,
    Discount: 'discount' as Channel,
    CustomizedLending: 'customizedLending' as Channel
};

