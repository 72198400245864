import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { LocalStorageService } from './local-storage.service';
import { SessionCacheService } from './session-cache.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(private sessionCache: SessionCacheService, private localStorage: LocalStorageService) {}

	setUser(user: User) {
		this.sessionCache.set('user', JSON.stringify(user));
		this.localStorage.set('user', JSON.stringify(user));
	}

	getUser(): User {
		let user = this.sessionCache.get('user');

		if (!user) {
			user = localStorage.getItem('user');
			if (user) this.sessionCache.set('user', user);
		}

		return JSON.parse(user);
	}

	clearUser() {
		this.sessionCache.remove('user');
		this.localStorage.remove('user');
	}
}
