import { Injectable } from '@angular/core';
import { AuthService } from 'app/shared/auth';
import { IdentityService } from 'app/shared/auth/identity.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class TokenExchangeService {
	constructor(private identityService: IdentityService, private authService: AuthService) {}

	exchangeToken(): Observable<any> {
		return this.identityService.exchangeAccessToken().pipe(
			tap(response => {
				this.authService.setToken(response.token);
				this.authService.setUserFromToken(response.token);
			})
		);
	}

	enrichToken(creditId: string, lineOfCredit = 'default'): Observable<any> {
		if (creditId === null || creditId === undefined) {
			throw new Error('Required parameter creditId was null or undefined when calling enrichToken');
		}
		return this.identityService.enrichAuthToken(creditId, lineOfCredit).pipe(
			tap(response => {
				this.authService.setToken(response.token);
				this.authService.setUserFromToken(response.token);
			})
		);
	}
}
