import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-info-banner',
	templateUrl: './info-banner.component.html',
	styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent {
	@Output() close = new EventEmitter<string>();
	@Input() errorMessage: string;

	constructor() {}

	closeBanner() {
		this.close.emit();
	}
}
