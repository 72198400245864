/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContractType = 'frodaWithdrawal' | 'bamboraWithdrawal' | 'withdrawalGuarantor' | 'paymentPlanChange' | 'autogiroConsent' | 'paymentPlanChangeV2';

export const ContractType = {
    FrodaWithdrawal: 'frodaWithdrawal' as ContractType,
    BamboraWithdrawal: 'bamboraWithdrawal' as ContractType,
    WithdrawalGuarantor: 'withdrawalGuarantor' as ContractType,
    PaymentPlanChange: 'paymentPlanChange' as ContractType,
    AutogiroConsent: 'autogiroConsent' as ContractType,
    PaymentPlanChangeV2: 'paymentPlanChangeV2' as ContractType
};

