import { ValidatorFn, FormGroup, Validators } from '@angular/forms';

export function atLeastOneCountryValidator(businessOutsideEU: () => boolean, noCountries: () => boolean): ValidatorFn {
    return (countries: FormGroup): Record<string, boolean> | null => {
      const validator: ValidatorFn = Validators.requiredTrue;

      if(!businessOutsideEU() || noCountries()) return null;
      const atLeastOneCountry =
      countries &&
      countries.controls &&
      Object.keys(countries.controls).some(k => !validator((countries.controls[k] as FormGroup).controls.checked));

      return atLeastOneCountry ? null : { atLeastOneCountry: true };
    };
}
