<div class="flex flex-col w-full">
	<form [formGroup]="selectSNIForm" class="flex flex-col w-full gap-2">
		<ng-container *ngFor="let option of defaultSNIOptions; let i = index">
			<app-radio-item class="flex" [value]="option" formControlName="selectedSNI" [text]="option.translationKey | translate" />
		</ng-container>
	</form>
	<app-button [type]="'small'" (action)="openMoreSNIOptions()" [iconId]="'plus-icon'" class="flex mt-6 w-fit sm:mt-4">
		{{ 'sni-selector.find-more' | translate }}
	</app-button>
	<app-sni-options-modal [visible]="moreOptionsVisible" [sniOptions]="moreSNIOptions" (closeModal)="closeMoreSNIOptions()" (addNewSniOption)="addNewSniOption($event)" />
</div>

