import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WithdrawalRequestDto } from 'app/api';
import { MEDIUM_BREAKPOINT } from 'app/app.contants';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

@Component({
	selector: 'app-rejected-status-card',
	templateUrl: './rejected-status-card.component.html',
	styleUrls: ['./rejected-status-card.component.scss'],
})
export class RejectedStatusCardComponent implements OnInit, OnDestroy {
	@Input() rejectedRequest: WithdrawalRequestDto;
	@Input() currency: string;
	@Input() widthClass = 'max-w-5xl';
	@Output() close = new EventEmitter<void>();
	collapsed: boolean;
	resizeSubscription: Subscription;

	ngOnInit(): void {
		this.collapsed = window.innerWidth < MEDIUM_BREAKPOINT ? true : false;
		this.subscribeToResizeEvents();
	}

	subscribeToResizeEvents() {
		this.resizeSubscription = fromEvent(window, 'resize')
			.pipe(debounceTime(200))
			.subscribe((event: Event) => {
				const width = (event.target as Window).innerWidth;
				if (width > MEDIUM_BREAKPOINT) this.collapsed = false;
			});
	}

	get hasPreviousLoan(): boolean {
		return this.rejectedRequest && this.rejectedRequest.current_principal_due.amount > 0;
	}

	ngOnDestroy() {
		if (this.resizeSubscription) {
			this.resizeSubscription.unsubscribe();
		}
	}
}
