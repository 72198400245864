import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-unavailable-page',
	templateUrl: './unavailable-page.component.html',
	styleUrls: ['./unavailable-page.component.scss'],
})
export class UnavailablePageComponent implements OnInit {
	ngOnInit(): void {}
}
