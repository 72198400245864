import { Injectable } from '@angular/core';
import { PurchaseFinancingHttpService, PurchaseFinancingOfferDto } from 'app/api';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FinancingOfferService {
	offer$ = new BehaviorSubject<PurchaseFinancingOfferDto>(null);
	offerId: string;

	constructor(private purchaseFinancingService: PurchaseFinancingHttpService) {}

	getOffer(offerId) {
		this.purchaseFinancingService.getPurchaseFinancingOffer(offerId).subscribe(offer => {
			this.offerId = offer.id;
			this.offer$.next(offer);
		});
	}

	clearOffer() {
		this.offerId = null;
		this.offer$.next(null);
	}
}
