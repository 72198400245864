import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
	constructor(public auth: AuthService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.auth.getToken();

		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `bearer ${token}`,
				},
			});
		}

		return next.handle(request);
	}
}
