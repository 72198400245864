<form *ngIf="hrcForm" [formGroup]="hrcForm">
	<div class="flex gap-4 lg:flex-col">
		<app-radio-item [value]="true" class="w-full" [selectedBlackBorder]="true" formControlName="businessOutsideEU" [text]=" 'onboarding.application.kyc.outside-eu.yes' | translate" />
		<app-radio-item [value]="false" class="w-full" [selectedBlackBorder]="true" formControlName="businessOutsideEU" data-testid="radio-outside-eu-no" [text]="'onboarding.application.kyc.outside-eu.no' | translate" />
	</div>
	<div *ngIf="businessOutsideEU.value" class="overflow-hidden" [@openClose]>
		<h2 class="mt-6 mb-4 text-base" translate="onboarding.application.kyc.hrc.choose-countries.title"></h2>
		<app-notification *ngIf="(highRiskCountries.dirty || highRiskCountries.touched) && highRiskCountries.errors?.atLeastOneCountry" type="error">
			{{ 'onboarding.application.kyc.ubo.errors.at-least-one.country' | translate }}
		</app-notification>
		<ng-container formArrayName="highRiskCountries" *ngFor="let country of highRiskCountries.controls; let i = index">
			<div [formGroupName]="i">
				<app-checkbox formControlName="checked" type="list">
					<span text>{{ country.value.name }}</span>
				</app-checkbox>
			</div>
		</ng-container>
		<div class="py-4 border-t border-light-grey">
			<app-checkbox formControlName="noCountries">
				<span text>{{ 'onboarding.application.kyc.hrc.non-of-above' | translate }}</span>
			</app-checkbox>
		</div>
	</div>
</form>
