/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CallbackStatus = 'processing' | 'completed' | 'failed';

export const CallbackStatus = {
    Processing: 'processing' as CallbackStatus,
    Completed: 'completed' as CallbackStatus,
    Failed: 'failed' as CallbackStatus
};

