import { Injectable } from '@angular/core';
import { BankIDDevice } from 'app/api';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  bankIDDevice: BankIDDevice;

  constructor() {
    this.bankIDDevice = this.isMobile() ? BankIDDevice.Same : BankIDDevice.Other;
  }

  switchDevice(){
    this.bankIDDevice = this.bankIDDevice === BankIDDevice.Same ? BankIDDevice.Other : BankIDDevice.Same;
  }

  isSameDevice() : boolean {
    return this.bankIDDevice === BankIDDevice.Same
  }

  isOtherDevice() : boolean {
    return this.bankIDDevice === BankIDDevice.Other
  }

  isMobile() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/Android/i)
    );
  }

}
