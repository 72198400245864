import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { MAINTENANCE_MODE } from 'app/app.contants';
import { FeatureFlagsService } from 'app/services/feature-flags.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
	constructor(private featureFlagService: FeatureFlagsService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.featureFlagService.isFlagEnabled(MAINTENANCE_MODE)) {
			this.router.navigate(['/maintenance']);
			return false;
		}
		return true;
	}
}
