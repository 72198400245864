<app-modal-card [visible]="visible" (close)="close.emit()">
	<form *ngIf="uboForm" class="flex flex-col w-full" [formGroup]="uboForm">
		<h3 class="mb-6" translate="kyc.add-ubo.title"></h3>
		<div class="flex flex-wrap mb-2 sm:-mx-3">
			<div class="w-full sm:px-3">
				<app-input
					[label]="'kyc.add-ubo.first-name' | translate"
					name="firstName"
					type="text"
					formControlName="firstName"
					[placeholder]="'ubo.first-name.placeholder' | translate"></app-input>
			</div>
			<div class="w-full sm:px-3">
				<app-input
					[label]="'kyc.add-ubo.last-name' | translate"
					name="lastName"
					type="text"
					formControlName="lastName"
					[placeholder]="'ubo.last-name.placeholder' | translate"></app-input>
			</div>
			<div class="w-full sm:px-3">
				<app-input [label]="'kyc.add-ubo.ssn' | translate" name="ssn" placeholder="" formControlName="ssn" [placeholder]="'ubo.ssn.placeholder' | translate"></app-input>
			</div>
		</div>
		<app-button class="w-full text-center" type="primary" (action)="addUBO()">{{ 'kyc.add-ubo.confirm' | translate }}</app-button>
	</form>
</app-modal-card>
