import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class BaseUrlService {
	constructor() {}

	getClientBaseUrl() {
		let url = window.location.protocol + '//' + window.location.hostname;
		if (window.location.port !== '' && window.location.port !== '80') url += ':' + window.location.port;
		return url;
	}

	determineQuerySeparator(url): string {
		return this.hasQueryParams(url) ? '&' : '?';
	}

	private hasQueryParams(url) {
		return url.includes('?');
	}
}
