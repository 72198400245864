<div class="max-w-lg mx-auto bg-white">
	<div class="flex items-stretch gap-4">
		<div class="flex flex-col items-center">
			<div class="flex items-center justify-center w-12 h-12 rounded-full {{ bgColor }}">
				<span *ngIf="(step && step > currentStep) || step && !currentStep" class="text-lg font-bold text-white">{{step}}</span>
				<app-svg-icon *ngIf="iconName" class="text-white" [iconName]="iconName" [iconPath]="iconPath" [iconId]="iconId"></app-svg-icon>
				<app-svg-icon *ngIf="step && step <= currentStep" class="text-white" [iconName]="'check.svg'" [iconId]="'check'"></app-svg-icon>
			</div>
			<div class="flex-1 w-1 {{ bgColor }}" *ngIf="!isLastStep"></div>
		</div>
		<div class="flex-1 mt-3" [class.mb-4]="!isLastStep">
			<h3 class="{{ textColor }}">{{ title | translate}}</h3>
			<div class="mt-4 font-medium {{ textColor }}">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
