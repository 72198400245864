import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PLACEHOLDER_SWEDISH_PHONE_NUMBER } from 'app/app.contants';
import { SanitationService } from 'app/services';
import { SWEDISH_PHONE_PREFIX } from 'app/white-label-client/white-label-client.constants';
import { InputComponent } from '../components/input/input.component';

@Directive({
	selector: '[formControlName][type=tel]',
})
export class PhoneMaskDirective implements OnInit {
	constructor(
		public ngControl: NgControl,
		private inputComponent: InputComponent,
		private sanitationService: SanitationService
	) {}

	@HostListener('input', ['$event'])
	onChange(event) {
		this.onInputChange(event.target.value);
	}

	@HostListener('keypress', ['$event'])
	preventNonNumeric(event) {
		const allowedRegex = /[0-9]/g;

		if (!event?.key.match(allowedRegex)) {
			event.preventDefault();
		}
	}

	ngOnInit(): void {
		this.setPhoneInputAttributes();
		this.onInputChange(this.ngControl.value);
	}

	setPhoneInputAttributes() {
		this.inputComponent.prefix = this.inputComponent.prefix || SWEDISH_PHONE_PREFIX;
		this.inputComponent.placeholder = this.inputComponent.placeholder || PLACEHOLDER_SWEDISH_PHONE_NUMBER;
		this.inputComponent.autocomplete = 'tel-national';
	}

	onInputChange(value) {
		const maskedPhoneValue = this.sanitationService.maskPhoneNumber(value, this.inputComponent.prefix);
		this.inputComponent.writeValue(maskedPhoneValue);
		this.inputComponent.onChanged(
			this.sanitationService.sanetizePhoneNumber(maskedPhoneValue, this.inputComponent.prefix)
		);
	}
}
