/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProviderStatus = 'ENABLED' | 'DISABLED' | 'TEMPORARILY_DISABLED';

export const ProviderStatus = {
    ENABLED: 'ENABLED' as ProviderStatus,
    DISABLED: 'DISABLED' as ProviderStatus,
    TEMPORARILYDISABLED: 'TEMPORARILY_DISABLED' as ProviderStatus
};

