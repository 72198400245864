import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, Input, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[enterViewportNotifier]'
})
export class EnterViewportNotifierDirective implements AfterViewInit, OnDestroy {
	@Output() visibilityChange : EventEmitter<any> = new EventEmitter<'visible' | 'hidden'>();
	//percentage of the target element which is visible as a value between 0 and 100
	//0 - as soon as one pixel is visible
	//100 - every pixel of the element is visible
	@Input() percentVisible = 0;
	private observer: IntersectionObserver;

  constructor(@Host() private elementRef: ElementRef) { }

	ngAfterViewInit(): void {
		const options : IntersectionObserverInit = { root: null, rootMargin: "0px", threshold: this.percentVisible / 100 };
    this.observer = new IntersectionObserver(this.callback, options);
    this.observer.observe(this.elementRef.nativeElement);
	}

	ngOnDestroy(): void {
		this.observer.disconnect()
	}

	callback : IntersectionObserverCallback = (entries, observer)  => {
		entries.forEach(entry => this.visibilityChange.emit(entry.isIntersecting ? 'visible' : 'hidden'))
	}

}
