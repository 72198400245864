import { NgModule } from '@angular/core';
import { AsMoneyPipe } from './as-money.pipe';
import { FacadeLangKeyPipe } from './facade-lang-key.pipe';
import { MoneyPipe } from './money.pipe';
import { ToArrayPipe } from './to-array.pipe';
import { SimpleMarkdownPipe } from './simple-markdown.pipe';
import { ConcealSSNPipe } from './conceal-ssn.pipe';
import { MonthsPipe } from './months.pipe';
import { WeeksPipe } from './weeks.pipe';
import { IntervalPipe } from './interval.pipe';
import { RenderMarkdownPipe } from './render-markdown.pipe';
import { LocalDatePipe } from './local-date.pipe';

@NgModule({
	imports: [],
	declarations: [
		AsMoneyPipe,
		FacadeLangKeyPipe,
		MoneyPipe,
		ToArrayPipe,
		SimpleMarkdownPipe,
		ConcealSSNPipe,
		MonthsPipe,
		WeeksPipe,
		IntervalPipe,
		RenderMarkdownPipe,
		LocalDatePipe,
	],
	exports: [
		AsMoneyPipe,
		FacadeLangKeyPipe,
		MoneyPipe,
		ToArrayPipe,
		SimpleMarkdownPipe,
		ConcealSSNPipe,
		MonthsPipe,
		WeeksPipe,
		IntervalPipe,
		RenderMarkdownPipe,
		LocalDatePipe,
	],
})
export class ApplicationPipesModule {}
