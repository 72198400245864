import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig implements IAppConfig {
	public apiBaseUrl: string;
	public authBaseUrl: string;
	public contractAppUrl: string;
	public clientBaseUrl: string;
	public clientBaseUrlAlt: string;
	public mockDelay: number;
	public scrapingBaseUrl: string;
	public identityBaseUrl: string;
	public unleashBaseUrl: string;
	public unleashClientKey: string;
	public smartlookClientKey: string;
	public checkoutPublicKey: string;
	public redirectUrl: string;
	public identityClientId: string;
	constructor() {}
}

export interface IAppConfig {
	apiBaseUrl: string;
	authBaseUrl: string;
	contractAppUrl: string;
	clientBaseUrl: string;
	clientBaseUrlAlt: string;
	mockDelay: number;
	scrapingBaseUrl: string;
	identityBaseUrl: string;
	unleashBaseUrl: string;
	unleashClientKey: string;
	smartlookClientKey: string;
	checkoutPublicKey: string;
	redirectUrl: string;
	identityClientId: string;
}
