<app-modal-card [visible]="visible" [closable]="false">
	<h1 class="mb-6 text-xl text-center" translate="home.refresh-bank.title"></h1>
	<div class="flex flex-col items-center" *ngIf="!(callbackStatus$ | async) || (callbackStatus$ | async) === 'processing' || (callbackStatus$ | async) === 'completed'">
		<app-loader></app-loader>
		<p class="mt-2 mb-6 text-sm font-medium text-almost-black" translate="home.refresh-bank.loading"></p>
	</div>
	<div *ngIf="(callbackStatus$ | async) === 'failed'" class="mt-6">
		<app-notification type="error">
			{{ 'home.refresh-bank.error' | translate }}
		</app-notification>
	</div>
	<div class="flex flex-col items-center" *ngIf="(callbackStatus$ | async) === 'failed'">
		<a class="mb-2 link" type="secondary" (click)="close.emit()">{{ 'home.refresh-bank.cancel' | translate }}</a>
	</div>
</app-modal-card>
