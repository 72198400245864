import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { FacadeService } from 'app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { themes } from './themes.const';

@Directive({
	selector: '[appTheme]',
})
export class ThemeDirective implements OnInit, OnDestroy {
	protected readonly destroy$ = new Subject();

	constructor(@Inject(DOCUMENT) private document: any, private facadeService: FacadeService) {}

	ngOnInit() {
		this.facadeService.facadeChanged$.pipe(takeUntil(this.destroy$)).subscribe(currentFacade => {
			const theme = themes[currentFacade.name];
			if (theme) {
				for (const key in theme) {
					this.document.body.style.setProperty(key, theme[key]);
				}
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
