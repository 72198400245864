/**
 * Froda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RepaymentType = 'autoGiro' | 'splitSettlement' | 'invoice' | 'manual' | 'checkoutBankCard';

export const RepaymentType = {
    AutoGiro: 'autoGiro' as RepaymentType,
    SplitSettlement: 'splitSettlement' as RepaymentType,
    Invoice: 'invoice' as RepaymentType,
    Manual: 'manual' as RepaymentType,
    CheckoutBankCard: 'checkoutBankCard' as RepaymentType
};

