import { Component, Input, OnInit } from '@angular/core';
import { expandRows, fadeInOut, smoothOpenClose } from 'app/animations/smooth.animations';
import { SharedFileHeaderDto, SharedFilesHttpService } from 'app/api';
import { openFileLink } from 'app/helpers/file-link.helper';
import { OrganizationService, SentryService, WhiteLabelClientSettingsService } from 'app/services';

@Component({
	selector: 'app-report-item',
	templateUrl: './report-item.component.html',
	styleUrls: ['./report-item.component.scss'],
	animations: [smoothOpenClose, expandRows, fadeInOut],
})
export class ReportItemComponent implements OnInit {
	@Input() report: SharedFileHeaderDto;
	partnerKey: string;

	constructor(
		private sharedFilesService: SharedFilesHttpService,
		private organizationService: OrganizationService,
		private clientSettingsService: WhiteLabelClientSettingsService,
		private sentryService: SentryService
	) {}

	ngOnInit(): void {
		this.partnerKey = this.clientSettingsService.getSettings()?.key ?? null;
	}

	getReport(event, fileId) {
		event.stopPropagation();
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		this.sharedFilesService.getSharedFileForOrganizationAndCredit(organizationId, creditId, fileId).subscribe(file => {
			this.sentryService.addBreadcrumb('Report URL fetched', 'files', {
				fileUrl: file.url,
			});
			this.sentryService.addComponentName('ReportItemComponent');
			this.sentryService.setTag('partner_key', this.partnerKey);
			this.sentryService.captureMessage('File URL fetched');
			openFileLink(file.url, this.partnerKey);
		});
	}
}
