import { Injectable } from '@angular/core';
import {
	BankDataRequest,
	FixedLoanPriceResponseDto,
	FlexLoanPriceResponseDto,
	LoanPurposeDto,
	OrganizationInvoiceSettingsRequestDto,
	PartnerOnboardingSessionDto,
	PaymentIntervalType,
	PricingHttpService,
	RepaymentType,
	SetKycDataRequest,
	SignatoryPersonRequest,
	WithdrawalRequestRequest,
} from 'app/api';
import { BehaviorSubject, Observable, from, of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import { ReferenceService } from './reference.service';
import { WhiteLabelClientSettingsService } from './white-label-client-settings.service';

@Injectable({
	providedIn: 'root',
})
export class LoanService {
	private amountSource$ = new BehaviorSubject<number>(null);
	private durationSource$ = new BehaviorSubject<number>(null);
	private offerSource$ = new BehaviorSubject<FixedLoanPriceResponseDto>(null);
	private invoiceSource$ = new BehaviorSubject<OrganizationInvoiceSettingsRequestDto>(null);
	private flexOfferSource$ = new BehaviorSubject<FlexLoanPriceResponseDto>(null);
	private flexStateSource$ = new BehaviorSubject<boolean>(null);
	amount$ = this.amountSource$.asObservable();
	invoice$ = this.invoiceSource$.asObservable();
	duration$ = this.durationSource$.asObservable();
	offer$ = this.offerSource$.asObservable();
	flexOffer$ = this.flexOfferSource$.asObservable();
	flexState$ = this.flexStateSource$.asObservable();
	private initialAmountSet = false;
	private initialDurationSet = false;
	private initialFlexStateSet = false;
	private overdrawEnabled = false;
	private overdrawAmounts = {
		1: 2000000,
		2: 1500000,
		3: 1000000,
		4: 500000,
		5: 150000,
	};
	paymentIntervals = [
		{
			value: PaymentIntervalType.Bankday,
			text: 'home.new-loan.payment-interval.bankday',
		},
		{
			value: PaymentIntervalType.Weekly,
			text: 'home.new-loan.payment-interval.weekly',
		},
	];
	private paymentIntervalSource$ = new BehaviorSubject<{
		value: PaymentIntervalType;
		text: string;
	}>(this.paymentIntervals[0]);
	paymentInterval$ = this.paymentIntervalSource$.asObservable();
	private purposeSource$ = new BehaviorSubject<LoanPurposeDto>(null);
	private kycSource$ = new BehaviorSubject<SetKycDataRequest>(null);
	private bankAccountQuestions$ = new BehaviorSubject<BankDataRequest>(null);
	purpose$ = this.purposeSource$.asObservable();
	kyc$ = this.kycSource$.asObservable();
	private signatoriesSource$ = new BehaviorSubject<SignatoryPersonRequest[]>(null);
	signatories$ = this.signatoriesSource$.asObservable();
	private paymentOption$ = new BehaviorSubject<RepaymentType>(null);

	constructor(
		private analyticsService: AnalyticsService,
		private referenceService: ReferenceService,
		private pricingService: PricingHttpService,
		private clientSettingsService: WhiteLabelClientSettingsService
	) {}

	get isOverdrawEnabled(): boolean {
		return this.overdrawEnabled;
	}

	setInitialAmount(value: number) {
		if (!this.initialAmountSet) {
			this.amountSource$.next(value);
			this.initialAmountSet = true;
		}
	}

	setInitialDuration(value: number) {
		if (!this.initialDurationSet) {
			this.durationSource$.next(value);
			this.initialDurationSet = true;
		}
	}

	setPaymentOption(value: RepaymentType) {
		this.paymentOption$.next(value);
	}

	getPaymentOption(): RepaymentType {
		return this.paymentOption$.getValue();
	}

	setInitialFlexState(value: boolean) {
		if (!this.initialFlexStateSet) {
			this.flexStateSource$.next(value);
			this.initialFlexStateSet = true;
		}
	}

	updateAmount(value: number) {
		this.amountSource$.next(value);
	}

	updateDuration(value: number) {
		this.durationSource$.next(value);
	}

	updateInvoice(value: OrganizationInvoiceSettingsRequestDto) {
		this.invoiceSource$.next(value);
	}

	updateOffer(value: FixedLoanPriceResponseDto) {
		this.offerSource$.next(value);
	}

	updateBankAccountQuestions(questions: BankDataRequest) {
		this.bankAccountQuestions$.next(questions);
	}

	updateFlexOffer(value: FlexLoanPriceResponseDto) {
		this.flexOfferSource$.next(value);
	}

	updateFlexState(value: boolean) {
		this.flexStateSource$.next(value);
	}

	updatePaymentInterval(value: string) {
		//if monthly interval is selected, add it to the list of available intervals
		if (value === PaymentIntervalType.Monthly) {
			this.addMonthlyPaymentInterval();
		} else if (value === PaymentIntervalType.WeeklySimple) {
			this.addWeeklySimplePaymentInterval();
		}

		const interval = this.paymentIntervals.find(i => i.value === value);
		this.paymentIntervalSource$.next(interval);
	}

	updatePurpose(value: LoanPurposeDto) {
		this.purposeSource$.next(value);
	}

	setKyc(value: SetKycDataRequest) {
		this.kycSource$.next(value);
	}

	updateSignatories(value: SignatoryPersonRequest[]) {
		this.signatoriesSource$.next(value);
	}

	addMonthlyPaymentInterval() {
		//check if monthly interval is already added
		if (this.paymentIntervals.findIndex(i => i.value === PaymentIntervalType.Monthly) === -1)
			this.paymentIntervals.push({
				value: PaymentIntervalType.Monthly,
				text: 'home.new-loan.payment-interval.monthly',
			});
	}

	addDailyPaymentInterval() {
		//check if daily interval is already added
		if (this.paymentIntervals.findIndex(i => i.value === PaymentIntervalType.Bankday) === -1)
			this.paymentIntervals.push({
				value: PaymentIntervalType.Bankday,
				text: 'home.new-loan.payment-interval.bankday',
			});
	}

	removeDailyPaymentInterval() {
		this.paymentIntervals = this.paymentIntervals.filter(interval => interval.value !== PaymentIntervalType.Bankday);
	}

	addWeeklySimplePaymentInterval() {
		//check if weekly interval is already added
		if (this.paymentIntervals.findIndex(i => i.value === PaymentIntervalType.WeeklySimple) === -1)
			this.paymentIntervals.push({
				value: PaymentIntervalType.WeeklySimple,
				text: 'home.new-loan.payment-interval.weekly',
			});
	}

	clearInfo() {
		this.bankAccountQuestions$.next(null);
		this.amountSource$.next(null);
		this.durationSource$.next(null);
		this.offerSource$.next(null);
		this.purposeSource$.next(null);
		this.kycSource$.next(null);
		this.signatoriesSource$.next(null);
		this.overdrawEnabled = false;
		this.initialAmountSet = false;
		this.initialDurationSet = false;
		this.invoiceSource$.next(null);
	}

	enableOverdraw() {
		this.overdrawEnabled = true;
	}

	getOverdrawAmount(credit_class: number): number {
		return this.overdrawAmounts[credit_class];
	}

	createWithdrawalRequestRequest(): Observable<WithdrawalRequestRequest> {
		return zip(
			this.bankAccountQuestions$,
			this.signatories$,
			this.purpose$,
			from(this.analyticsService.getTrackingData()),
			of(this.referenceService.getCurrentReference())
		).pipe(
			take(1),
			map(([bankAccountQuestions, signatories, purpose, tracking, reference]) => {
				const request: WithdrawalRequestRequest = {
					signatories: {
						persons: signatories,
						structure: 'currentUserUnknown',
					},
					purpose: purpose,
					bank_data_request: bankAccountQuestions,
					reference: {
						source: reference.source,
						channel: reference.channel,
					},
					tracking: {
						google_analytics_client_id: tracking.clientId,
						google_analytics_tracking_id: tracking.trackingId,
						google_ads_click_id: tracking.clickId,
					},
				};

				return request;
			})
		);
	}

	restoreLoanFromPartnerOnboardingSession(session: PartnerOnboardingSessionDto): Observable<string> {
		//restore loan state from partner onboarding session - use pricing service to get data for the offer
		return this.pricingService.getPriceCalculation(session.price_calculation_id).pipe(
			switchMap(priceCalculation => {
				this.updateAmount(priceCalculation.input.principal.amount);
				this.updateDuration(priceCalculation.input.duration_in_months);
				this.updatePaymentInterval(priceCalculation.input.payment_interval.type);
				const currency = this.clientSettingsService.getSettings().currency;
				this.updateOffer({
					price_calculation_id: session.price_calculation_id,
					principal_amount: priceCalculation.input.principal,
					cost: priceCalculation.output.interest,
					standard_payment: priceCalculation.output.standard_payment,
					price_point: {
						pricePoint: priceCalculation.output.price_point.price_point,
						interestPercentage: priceCalculation.output.price_point.interest_percentage,
					},
					price_point_new_capital: {
						pricePoint: priceCalculation.output.price_point_new_capital.price_point,
						interestPercentage: priceCalculation.output.price_point_new_capital.interest_percentage,
					},
					total_amount: {
						amount: priceCalculation.input.principal.amount + priceCalculation.output.interest.amount,
						currency: currency,
					},
				});
				this.updateSignatories(session.signatories.persons);
				this.updatePurpose(session.purpose);
				this.updateBankAccountQuestions(session.bank_data_request);
				this.setKyc(session.kycDataRequest);
				return of(session.id);
			})
		);
	}
}
