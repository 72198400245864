import { Injectable } from '@angular/core';
import { CustomerReferenceDto } from 'app/api';

@Injectable({
	providedIn: 'root',
})
export class ReferenceService {
	constructor() {}

	private key = 'reference';

	setReference(reference: CustomerReferenceDto): void {
		localStorage.setItem(
			this.key,
			JSON.stringify({
				source: reference.source.toLowerCase(),
				channel: reference.channel.toLowerCase(),
			})
		);
	}

	getCurrentReference(): CustomerReferenceDto {
		return JSON.parse(localStorage.getItem(this.key));
	}
}
