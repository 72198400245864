<div class="flex justify-center w-full">
	<div [ngClass]="widthClass" class="flex-1 p-4 rounded-2xl bg-system-yellow">
		<div class="relative flex flex-col w-full gap-2 sm:flex-row">
			<div class="flex flex-col pt-2 text-center sm:w-1/3 md:justify-center">
				<p class="text-black-60" translate="rejected-card.subtitle"></p>
				<h2 class="font-medium text-black" translate="rejected-card.title"></h2>
				<p class="pt-4 pb-2 md:pt-6 text-black-60" translate="rejected-card.pre"></p>
			</div>
			<div class="flex flex-col items-center pt-6 sm:w-1/3 md:pt-0 md:w-2/3" [ngClass]="{'pb-4' : collapsed}">
				<app-button
					class="max-w-fit sm:hidden"
					(action)="collapsed = !collapsed"
					[ngClass]="{'mb-common': !collapsed}"
					[type]="'small'"
					[iconId]="collapsed ? 'chevron-down' : 'close'"
					[contentPosition]="'left'">
					{{ collapsed ? ('rejected-card.read-more' | translate) : ('rejected-card.close' | translate) }}
				</app-button>
				<div
					*ngIf="!collapsed"
					class="flex flex-col pt-2 text-center bg-white rounded-lg md:text-left md:flex-row text-almost-black px-common pb-common"
					[ngClass]="{'mb-common' : collapsed}">
					<div class="md:w-1/2 md:p-4">
						<h3 class="pt-4 mb-2" translate="rejected-card.reasons.title"></h3>
						<p class="mb-common" translate="rejected-card.reasons.pre"></p>
					</div>
					<div class="md:w-1/2 md:p-4">
						<div class="flex justify-between py-3 border-b border-black-6">
							<p translate="application-summary.application-status"></p>
							<div class="flex justify-end text-system-red">
								<p class="mr-2 font-bold text-right text-system-red" translate="application-summary.rejected"></p>
								<app-svg-icon [iconName]="'prohibited.svg'" [iconId]="'prohibited'"></app-svg-icon>
							</div>
						</div>
						<div class="flex justify-between py-3 border-b border-black-6" *ngIf="hasPreviousLoan">
							<p translate="application-summary.new-loan"></p>
							<p class="font-bold text-right ">{{ (rejectedRequest?.new_principal_due.amount - rejectedRequest?.current_principal_due.amount) | asMoney:currency:-1  }}</p>
						</div>
						<div class="flex justify-between py-3 border-b border-black-6" *ngIf="hasPreviousLoan">
							<p translate="application-summary.existing-loan"></p>
							<p class="font-bold text-right">{{ (rejectedRequest?.current_principal_due.amount) | asMoney:currency:-1 }}</p>
						</div>
						<div class="flex justify-between py-3 border-b border-black-6" *ngIf="!hasPreviousLoan">
							<p translate="application-summary.loan-amount"></p>
							<p class="font-bold text-right">{{ rejectedRequest?.new_principal_due.amount | asMoney:currency:-1  }}</p>
						</div>
						<div class="flex justify-between py-3 border-b border-black-6" *ngIf="hasPreviousLoan">
							<p translate="application-summary.new-total-loan-amount"></p>
							<p class="font-bold text-right">{{ rejectedRequest?.new_principal_due.amount | asMoney:currency:-1  }}</p>
						</div>

						<div class="flex justify-between py-3 border-b border-black-6">
							<p translate="application-summary.duration"></p>
							<p class="font-bold text-right">{{ rejectedRequest?.duration_in_months }} {{'input.suffix.months' | translate}}</p>
						</div>
						<div class="flex justify-between py-3">
							<p translate="application-summary.cost"></p>
							<p class="font-bold text-right ">{{ rejectedRequest?.new_interest_due.amount | asMoney:currency:0  }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
