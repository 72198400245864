import { Injectable, Injector } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { PrefixTranslationKeysService } from './prefix-translation-keys.service';

@Injectable()
export class FrodaMissingTranslationHandler implements MissingTranslationHandler {
	private missingKey: string;
	private translation: TranslateService;

	constructor(private prefixTranslationKeysService: PrefixTranslationKeysService, private injector: Injector) {}

	handle(params: MissingTranslationHandlerParams): string {
		const key = params.key;
		const prefix = this.prefixTranslationKeysService.getPrefix();
		const partnerPrefix = this.prefixTranslationKeysService.getPartnerPrefix();
		if (this.translation == null) {
			this.translation = this.injector.get(TranslateService);
		}

		if (this.missingKey != null) {
			// console.log(`Missing key: ${key} (already handling missing key: ${this.missingKey})`);
			// already handling the missing translation
			// Return the key itself
			return this.missingKey;
		}

		this.missingKey = key;
		let prefixTranslation = null;
		let partnerTranslation = null;
		let partnerTranslationExists = false;
		let prefixTranslationExists = false;

		if (partnerPrefix) {
			partnerTranslation = this.translation.instant(`${partnerPrefix}.${prefix}.${key}`, params.interpolateParams);
			partnerTranslationExists = partnerTranslation !== key;
		}

		if (!partnerTranslationExists && prefix) {
			prefixTranslation = this.translation.instant(`${prefix}.${key}`, params.interpolateParams);
			prefixTranslationExists = prefixTranslation !== key;
		}

		this.missingKey = null;

		if (partnerTranslationExists) return partnerTranslation;
		else if (prefixTranslationExists) return prefixTranslation;
		else return key;
	}
}
