import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import smartlookClient from 'smartlook-client';

declare let ga: any;

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	constructor(private angulartics2: Angulartics2) {}

	private checkIfAnalyticsLoaded() {
		return new Promise((resolve, reject) => {
			const timeStart = Date.now();
			const TIMEOUT = 3000;

			const _isLoaded = function () {
				if (Date.now() - timeStart > TIMEOUT) {
					reject('Timeout. Google analytics not injected!');
					return;
				}
				if (window['ga'] && ga.create) {
					resolve(ga);
					return;
				} else {
					setTimeout(_isLoaded, 500);
				}
			};
			_isLoaded();
		});
	}

	private getCookie(name) {
		const cookies = document.cookie.split(';');
		let cookieFound = null;

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.indexOf(name + '=') === 0) {
				cookieFound = cookie.substring((name + '=').length, cookie.length);
			}
		}

		if (cookieFound === 'null') cookieFound = null;

		return cookieFound;
	}

	registerEvent(category: string, action?: string, label?: string) {
		this.angulartics2.eventTrack.next({
			action: action,
			properties: {
				category: category,
				label: label,
			},
		});
	}

	registerPageview(path: string) {
		this.angulartics2.pageTrack.next({
			path: '/$web-customer' + path,
		});
		if (smartlookClient.initialized()) smartlookClient.navigation(path);
	}

	async getTrackingData(): Promise<{ clientId: string; trackingId: string; clickId: string }> {
		return await this.checkIfAnalyticsLoaded()
			.then(_ => {
				return {
					clientId: ga.getAll()[0].get('clientId'),
					trackingId: ga.getAll()[0].get('trackingId'),
					clickId: this.getStoredGoogleClickId(),
				};
			})
			.catch(err => {
				return {
					clientId: null,
					trackingId: null,
					clickId: null,
				};
			});
	}

	getStoredGoogleClickId() {
		return this.getCookie('gclid');
	}
}
