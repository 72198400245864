import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EstimatedPaymentDto, PaymentsHttpService, TransactionDto } from 'app/api';
import { OrganizationService } from 'app/services';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
	selector: 'home-transactions',
	templateUrl: './home-transactions.component.html',
	styleUrls: ['./home-transactions.component.scss'],
})
export class HomeTransactionsComponent implements OnInit {
	@Input() transactions: TransactionDto[];
	@Output() clickedMore = new EventEmitter<void>();
	partner: string;
	nextPayment$: Observable<EstimatedPaymentDto>;
	@Input() hideTransactions = false;

	constructor(
		private route: ActivatedRoute,
		private paymentService: PaymentsHttpService,
		private organizationService: OrganizationService
	) {}

	ngOnInit(): void {
		const organisationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		this.route.parent.paramMap.subscribe(params => {
			this.partner = params.get('partner');
		});

		this.nextPayment$ = this.paymentService.getNextPaymentByCredit(organisationId, creditId).pipe(shareReplay(1));
	}

	abs(value) {
		return Math.abs(value);
	}
}
