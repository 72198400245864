import { LOCATION_INITIALIZED } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_APP_LANGUAGE, DEFAULT_PARTNER_LANGUAGE } from 'app/app.contants';
import { UserTrackingService } from './user-tracking.service';
import { WhiteLabelClientSettingsService } from './white-label-client-settings.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class LanguageInitializerService {
	constructor(
		private translate: TranslateService,
		private injector: Injector,
		private localStorage: LocalStorageService,
		private userTrackingService: UserTrackingService,
		private clientSettingsService: WhiteLabelClientSettingsService
	) {}

	readonly availableLangs = ['sv', 'en', 'fi', 'da', 'nb', 'de'];

	//used in app.module to initialize the language
	initLanguage(): Promise<string> {
		return new Promise<string>((resolve: any) => {
			const locationInitialized = this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
			locationInitialized.then(() => {
				const defaultLang = DEFAULT_APP_LANGUAGE;
				this.translate.addLangs(this.availableLangs);
				this.setFallbackLanguage(defaultLang);

				const cacheKey = 'chosenLocale';

				const queryParamLocale = this.getLocaleFromQueryParam();
				const cachedLang = this.localStorage.get(cacheKey);
				const langToSet = queryParamLocale ? queryParamLocale : cachedLang ? cachedLang : defaultLang;

				this.translate.use(langToSet).subscribe(
					() => {
						console.info(`Successfully initialized '${langToSet}' language.'`);
						this.localStorage.set(cacheKey, langToSet);
						this.userTrackingService.setUserVars({ locale: langToSet });
					},
					error => {
						console.error(`Problem with '${langToSet}' language initialization. `, error);
					},
					() => {
						resolve(langToSet);
					}
				);
			});
		});
	}

	//used in white-label-client.module to initialize the language
	initPartnerLanguage(): Promise<string> {
		return new Promise<string>((resolve: any) => {
			this.setFallbackLanguage(DEFAULT_PARTNER_LANGUAGE);
			const partnerSettings = this.clientSettingsService.getSettings();
			const defaultLang = partnerSettings?.defaultLanguage;
			const cachedLang = this.localStorage.get('chosenLocale');
			const supportedLanguages = partnerSettings.supportedLanguages;

			let langToSet = cachedLang;
			if (!supportedLanguages.find(lang => lang.key === cachedLang) || !cachedLang) {
				langToSet = defaultLang;
			}

			const queryLocale = this.getLocaleFromQueryParam();
			if (queryLocale && supportedLanguages.find(lang => lang.key === queryLocale)) {
				langToSet = queryLocale;
			}

			this.translate.use(langToSet).subscribe(
				() => {
					console.info(`Successfully initialized '${langToSet}' language.'`);
					this.localStorage.set('chosenLocale', langToSet);
					this.userTrackingService.setUserVars({ locale: langToSet });
				},
				error => {
					console.error(`Problem with '${langToSet}' language initialization. `, error);
				},
				() => {
					resolve(langToSet);
				}
			);
		});
	}

	private setFallbackLanguage(fallbackLanguage: string) {
		//use as fallback language, for translations that are not available in the selected language
		this.translate.setDefaultLang(fallbackLanguage);
	}

	private getLocaleFromQueryParam() {
		let queryParamLocale;
		const url = new URL(window.location.href);
		const locale = url.searchParams.get('locale');
		if (locale) {
			if (this.availableLangs.indexOf(locale) > -1) {
				queryParamLocale = locale;
			}
		}
		return queryParamLocale;
	}
}
