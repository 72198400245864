import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-login-failure-message',
	templateUrl: './login-failure-message.component.html',
	styleUrls: ['./login-failure-message.component.scss'],
})
export class LoginFailureMessageComponent {
	@Input() errorCode:
		| 'no-token'
		| 'no-company'
		| 'auth-failed'
		| 'user-not-found'
		| 'no-person-data'
		| 'no-signatory-rights'
		| 'auth-failed-no-user'
		| 'auth-failed-timeout'
		| 'auth-failed-canceled'
		| 'identity-token-error'
		| 'no-signatory-rights'
		| 'no-primary-credit'
		| 'not-eligible'
		| 'token-expired'
		| 'unknown'
		| 'server-error';
}
