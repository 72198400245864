export const vismaVariables = {
	'font-higlight': 'Ubuntu',
	'font-header': 'Ubuntu',
	'font-body': 'Open Sans',
	'primary-100': '#F1F2F5',
	'primary-200': '#F1F2F5',
	'primary-400': '#007ACA',
	'primary-500': '#007ACA',
	'primary-700': '#007ACA',
	'secondary-100': '#F1F2F5',
	'secondary-200': '#F1F2F5',
	'secondary-400': '#007ACA',
	'secondary-500': '#007ACA',
	'secondary-900': '#007ACA',

	'checkbox-100': '#F1F2F5',
	'checkbox-300': '#1A1A1A',
	'checkbox-500': '#1A1A1A',
	'radio-100': '#F1F2F5',
	'radio-500': '#1A1A1A',

	'rounded-box': '10px',
	'rounded-notification': '4px',

	'min-width': 'fit-content',

	'highlight-light': '#F1F2F5',
	'highlight-border': '#DDDDDD',
	'slider-color': '#FFFFFF',
	'slider-border-color': '#2E67C0',

	//Box card styles
	'box-background': '#F1F2F5',
	'box-info': '#007ACA',
	'box-border': '#F1F2F5',

	//Box card styles - secondary
	'box-background-light': '#FFFFFF',
	'box-info-light': '#007ACA',
	'box-border-light': '#FFFFFF',

	separator: '#EEEEEE',

	'input-phone-prefix': '#E4E4E4',
	spinner: '#BDBDBD',

	highlighted: '#007ACA',
	title: '#24163A',
	paragraph: '#24163A',

	dark: '#432578',
	brand: '#E36C67',

	'footer-background': '#FAFAFA',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#2E67C0',
	'button-color-hover': '#2E67C0',
	'button-text': '#FFFFFF',
	'button-text-hover': '#FFFFFF',
	'rounded-button': '6px',
	'padding-button': '12px 24px',
	'border-button-color': '#2E67C0',

	// secondary button
	'button-color-secondary': 'transparent',
	'button-text-secondary': '#2E67C0',
	'button-text-secondary-hover': '#2E67C0',
	'rounded-button-secondary': '6px',
	'padding-button-secondary-outlined': '10px 24px',
	'button-border-secondary-outlined': '#2E67C0',
	'button-border-secondary-hover': '#2E67C0',

	// --------------------------------------- //

	// loading animations
	'big-loader': [
		'#007ACA',
		'#007ACA',
		'#007ACA',
		'#007ACA',
		'#F1F2F5',
		'#F1F2F5',
		'#F1F2F5',
		'#F1F2F5',
		'#007ACA',
		'#007ACA',
		'#007ACA',
		'#007ACA',
		'#F1F2F5',
		'#F1F2F5',
		'#F1F2F5',
		'#F1F2F5',
		'#007ACA',
	],
	'small-loader': ['#007ACA', '#007ACA', '#007ACA', '#F1F2F5', '#F1F2F5', '#F1F2F5'],
};
