import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
	selector: 'app-ubo-list',
	templateUrl: './ubo-list.component.html',
	styleUrls: ['./ubo-list.component.scss'],
})
export class UboListComponent implements OnInit {
	@Input() ubos: FormArray;
	@Input() ubosLoading: boolean;
	@Output() onRemoveClick = new EventEmitter<number>();
	@Output() onAddClick = new EventEmitter<void>();

	constructor() {}

	ngOnInit(): void {}
}
