import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-enable-banking-error-modal',
	templateUrl: './enable-banking-error-modal.component.html',
	styleUrls: ['./enable-banking-error-modal.component.scss'],
})
export class EnableBankingErrorModalComponent implements OnInit, OnDestroy {
	@Output() back = new EventEmitter<void>();
	@Output() toManual = new EventEmitter<void>();
	@Input() allowSkipScraping = true;

	ngOnInit(): void {
		window.document.body.style.setProperty('overflow', 'hidden');
	}

	ngOnDestroy(): void {
		window.document.body.style.setProperty('overflow', 'auto');
	}
}
