import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

export const ssnFinnishValidator : ValidatorFn[] = [Validators.required, Validators.minLength(11), finnishValidator]

export function finnishValidator(control: AbstractControl): Record<string, boolean> | null {
	if (environment.production && !isValid(control.value)) {
		return { 'distinct': true };
	}
	return null;
}

function isValid(ssn : string) : boolean
{
  if(!ssn) return false;
  if(ssn.length !== 11) 
    return false;

  ssn = ssn.toLowerCase();
  const m = ssn.match(/(\d{6})(-|a)(\d{3})(\w){1}/);

  if (!m) {
    return false;
  }
  
  const c = []; 
  const base = 35;
  const n = parseInt(m[1] + m[3], 10) % 31;

  for (let i = 0; i < base; i++)  {
    const e = i.toString(base);
    if ('gioq'.indexOf(e) < 0) {
      c.push(e);
    }
  }

  return (c[n] == m[4]);

}