import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { OrganizationsHttpService } from 'app/api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function emailAsyncValidator(organizationHttpService: OrganizationsHttpService): AsyncValidatorFn {
	return (control: AbstractControl): Observable<ValidationErrors | null> => {
		return organizationHttpService.validateUserEmail({ email: control.value }).pipe(
			map((response: any) => {
				return response.success ? null : { email: true };
			}),
			catchError(() => {
				return of({ email: 'control.error.email' });
			}),
		);
	};
}
