import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { SentryService } from '@services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
@Injectable({
	providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
	constructor(public auth: AuthService, private router: Router, private sentryService: SentryService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(error => {
				if (error.status === 401) {
					this.sentryService.addBreadcrumb('JWT Interceptor 401 error', 'jwt-interceptor', {
						url: request.url,
						error: error.message,
					});
					this.sentryService.addHttpResponseMessage(error.message);
					this.auth.removeToken();
					if (this.router.url.includes('/p/')) {
						if (!this.router.url.includes('landing')) {
							const partnerUrlSegment = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET]?.segments[1]
								.path;
							this.router.navigate([`/p/${partnerUrlSegment}/login`], { queryParams: { errorCode: 'token-expired' } });
						}
					} else if (this.router.url.startsWith('/partner-portal')) {
						this.router.navigate(['/partner-portal/login'], { queryParams: { errorCode: 'token-expired' } });
					}
					//only redirect to login screen if we get 401 in My Froda
					else if (this.router.url.startsWith('/v2')) {
						this.router.navigate(['/login'], { queryParams: { errorCode: 'token-expired' } });
					}
				}
				return throwError(error);
			})
		);
	}
}
