<div class="inline-flex items-center justify-center w-full md:justify-between">
	<app-button
		[ngClass]="{ 'opacity-0 invisible': activeStep === 1 , 'opacity-100 visible': activeStep !== 1 }"
		class="hidden my-1 transition-opacity duration-700 ease-in-out md:block"
		type="small"
		(action)="stepBack()">
		<app-svg-icon [iconName]="'arrow-left.svg'" [iconId]="'arrow-left'"></app-svg-icon>
	</app-button>
	<div class="flex items-center justify-center gap-2">
		<div
			*ngFor="let step of [].constructor(numberOfSteps); let i = index;"
			class="transition-all duration-300 ease-in-out"
			[ngClass]="i + 1 === activeStep ? 'w-10 h-2 bg-black rounded' : 'w-2 h-2 bg-gray-200 rounded'">
			{{ index }}
		</div>
	</div>
	<app-button *ngIf="activeStep !== numberOfSteps" type="small" (action)="stepForward()" class="hidden my-1 md:block">
		<div class="flex items-center">
			<div class="px-3" translate="identification.stepper.next-button.text"></div>
			<app-svg-icon [iconName]="'arrow-right.svg'" [iconId]="'arrow-right'"></app-svg-icon>
		</div>
	</app-button>
	<app-button *ngIf="activeStep === numberOfSteps" (action)="lastStepButtonClicked.emit()" class="hidden md:block">
		<span>{{ buttonText  | translate }}</span>
	</app-button>
</div>
