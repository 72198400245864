export const lunarVariables = {
	'font-higlight': 'Sharp Sans',
	'font-header': 'Sharp Sans',
	'font-body': 'Sharp Sans',
	'primary-100': '#64C4FF',
	'primary-200': '#0A1E63',
	'primary-400': '#ffcb33',
	'primary-500': '#0A1E63',
	'primary-700': '#e99b15',
	'secondary-100': '#64C4FF',
	'secondary-200': '#0A1E63',
	'secondary-400': '#0000F9',
	'secondary-500': '#0A1E63',
	'secondary-900': '#DF910B',

	'checkbox-100': '#E6E8E8',
	'checkbox-300': '#AAA',
	'checkbox-500': '#333333',
	'radio-100': '#E6E8E8',
	'radio-500': '#333333',

	'rounded-box': '16px',
	'rounded-notification': '0px',

	'min-width': 'fit-content',

	'highlight-light': '#FFFFFF',
	'highlight-border': '#F2F2F2',
	'slider-color': '#000000',
	'slider-border-color': '#000000',

	//Box card styles
	'box-background': '#FFFFFF',
	'box-info': '#000000',
	'box-border': '#000000',

	//Box card styles - secondary
	'box-background-light': '#FFFFFF',
	'box-info-light': '#000000',
	'box-border-light': '#F2F2F2',

	separator: '#E4E4E4',

	'input-phone-prefix': '#E4E4E4',
	spinner: '#E4E4E4',

	highlighted: '#000000',
	title: '#000000',
	paragraph: '#000000',

	dark: '#000000',
	brand: '#FFFFFF',

	'footer-background': '#64C4FF',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#000000',
	'button-color-hover': '#000000',
	'button-text': '#FFFFFF',
	'button-text-hover': '#FFFFFF',
	'rounded-button': '24px',
	'padding-button': '12px 24px',
	'border-button-color': '#000000',

	// secondary button
	'button-color-secondary': '#E4E4E4',
	'button-text-secondary': '#000000',
	'button-text-secondary-hover': '#000000',
	'rounded-button-secondary': '24px',
	'padding-button-secondary-outlined': '10px 24px',
	'button-border-secondary-outlined': '#E4E4E4',
	'button-border-secondary-hover': '#E4E4E4',
	// --------------------------------------- //

	// loading animations
	'big-loader': [
		'#000000',
		'#000000',
		'#000000',
		'#000000',
		'#E4E4E4',
		'#E4E4E4',
		'#E4E4E4',
		'#E4E4E4',
		'#000000',
		'#000000',
		'#000000',
		'#000000',
		'#E4E4E4',
		'#E4E4E4',
		'#E4E4E4',
		'#E4E4E4',
		'#000000',
	],
	'small-loader': ['#000000', '#000000', '#000000', '#E4E4E4', '#E4E4E4', '#E4E4E4'],
};
