import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/sv';

import {
	generateStandardPayment,
	generateNewLoan,
	generateAmortizationPause,
	generateDecreasePayment,
	generateIncreaseLoan,
	generateIncreasePayment,
	generateFinalPayment,
	generateExtraPayment,
	adjustDateFormat,
	generateCounterOffer,
} from 'app/helpers/events.helper';

import { EventName } from 'app/my-froda/events/events.enum';
import { OrganizationEventDto } from 'app/api';

dayjs.extend(relativeTime);

const FAILED_EVENTS = ['Failed', 'Declined', 'Canceled'];

@Injectable({
	providedIn: 'root',
})
export class EventsService {
	constructor(private translateService: TranslateService) {}

	checkIsFailedStatus = (event: OrganizationEventDto) => FAILED_EVENTS.includes(event.status);

	formatEventsForDisplay = (events: OrganizationEventDto[]) => {
		return (events || [])
			.map((event: OrganizationEventDto) => {
				return {
					...event,
					eventDateFormatted: adjustDateFormat(event.eventDate, this.translateService.currentLang),
					failedEvent: this.checkIsFailedStatus(event),
				};
			})
			.map((event: OrganizationEventDto) => {
				const eventType = this.eventTypeMapper[event.eventName];

				if (!eventType) {
					return null;
				}

				return eventType({ event, translateService: this.translateService });
			})
			.filter(event => event !== null); // in case unhandled event type is returned - prevent app rendering error
	};

	eventTypeMapper = {
		[EventName.payment]: generateStandardPayment,
		[EventName.finalPayment]: generateFinalPayment,
		[EventName.extraPayment]: generateExtraPayment,
		[EventName.increaseLoan]: generateIncreaseLoan,
		[EventName.decreasePayment]: generateDecreasePayment,
		[EventName.increasePayment]: generateIncreasePayment,
		[EventName.amortizationPause]: generateAmortizationPause,
		[EventName.newLoan]: generateNewLoan,
		[EventName.counterOffer]: generateCounterOffer,
	};
}
