import { AbstractControl } from '@angular/forms';
import { environment } from 'environments/environment';

export function luhnValidator(control: AbstractControl): Record<string, boolean> | null {
	if (environment.production && !lunh(control.value)) {
		return { distinct: true };
	}
	return null;
}

function lunh(value: string): boolean {
	if (!value) return true;

	value = value.replace(/\D/g, '');

	if (value.length == 12) value = value.substring(2);

	if (/[^0-9-\s]+/.test(value)) return false;

	let nCheck = 0,
		bEven = false;
	for (let n = value.length - 1; n >= 0; n--) {
		let cDigit = value.charAt(n),
			nDigit = parseInt(cDigit, 10);
		if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
		nCheck += nDigit;
		bEven = !bEven;
	}
	return nCheck % 10 == 0;
}
