import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export function pepTypeValidator(): ValidatorFn {
  return (ubo: FormGroup): ValidationErrors | null => {
    const is_pep = ubo.get('is_pep');
    const pep_type = ubo.get('pep_type');

    if(is_pep.value && !pep_type.value){
      pep_type.setErrors({ required : true })
    }
    else {
      pep_type.setErrors(null)
    }
    return null;
  };
}
