<div class="flex w-full min-h-screen">
	<section class="flex flex-col justify-start w-full max-w-2xl mx-5 my-10 bg-white">
		<div class="w-full max-w-xl mx-auto">
			<ng-content></ng-content>
		</div>

		<section class="m-5 text-center">
			<img class="w-32 mx-auto opacity-50" src="/assets/froda/logos/froda-dark.svg" alt="Froda Logo" />
			<div class="flex flex-col items-center mt-3 font-semibold">
				<a class="mt-3 text-dark-grey" href="mailto:support@froda.se">support&#64;froda.se</a>
				<a class="mt-3 text-dark-grey" href="tel:+46850131100">{{supportPhoneNumber}}</a>
			</div>
		</section>
	</section>
	<section class="flex-1 bg-almost-white"></section>
</div>
