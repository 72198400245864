import { ComponentRef, Injectable } from '@angular/core';
import { ToastComponent } from 'app/shared/components/toast/toast.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	private componentRef: ComponentRef<any>;
	private viewContainerRef;
	private toasts$ = new Subject<any>();
	private toastVisible = false;

	constructor() {
		let removeToastTimeout;
		let addToastTimeout;
		this.toasts$.subscribe(toastData => {
			clearTimeout(addToastTimeout);
			clearTimeout(removeToastTimeout);
			if (!this.toastVisible) {
				this.createToastComponent(toastData);
				removeToastTimeout = setTimeout(() => {
					this.removeToastComponent();
				}, 4000);
			} else {
				this.removeToastComponent();
				addToastTimeout = setTimeout(() => {
					this.createToastComponent(toastData);
				}, 400);
				removeToastTimeout = setTimeout(() => {
					this.removeToastComponent();
				}, 4000);
			}
		});
	}

	private createToastComponent(toastData) {
		this.appendToastComponent();
		this.bindChildInput(toastData);
		this.bindChildOutput();
	}

	setRootViewContainerRef(toastContainer) {
		if (!this.viewContainerRef) this.viewContainerRef = toastContainer.viewContainerRef;
	}

	success(content) {
		this.toasts$.next({ type: 'success', content });
	}

	error(content) {
		this.toasts$.next({ type: 'error', content });
	}

	private appendToastComponent() {
		this.toastVisible = true;
		this.componentRef = this.viewContainerRef.createComponent(ToastComponent);
	}

	private removeToastComponent() {
		this.componentRef.instance.show = false;
		setTimeout(() => {
			this.viewContainerRef.clear();
			this.toastVisible = false;
		}, 200);
	}

	private bindChildInput(toast) {
		this.componentRef.instance.show = true;
		this.componentRef.instance.type = toast.type;
		this.componentRef.instance.content = toast.content;
	}

	private bindChildOutput() {
		const refComponentDestroy$ = new Subject();

		this.componentRef.instance.close.pipe(takeUntil(refComponentDestroy$)).subscribe(_ => {
			this.removeToastComponent();
		});

		this.componentRef.onDestroy(() => {
			refComponentDestroy$.next(undefined);
			refComponentDestroy$.complete();
		});
	}
}
