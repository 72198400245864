import { Component, EventEmitter, Input, Output } from '@angular/core';
import { smoothOpenClose } from 'app/animations/smooth.animations';
import { Provider } from 'app/api-scraping';

@Component({
	selector: 'app-search-bank-item',
	templateUrl: './search-bank-item.component.html',
	styleUrls: ['./search-bank-item.component.scss'],
	animations: [smoothOpenClose],
})
export class SearchBankItemComponent {
	@Input() isSelected = false;
	@Input() listItem: Provider;
	iconHeight = '24';

	@Output() optionClick = new EventEmitter<any>();

	constructor() {}
}
