import { Component, OnInit, Input, EventEmitter, Output, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, ControlValueAccessor {
	@Input() min: number;
	@Input() max: number;
	@Input() step: number;
	@Input() value: number;
	@Input() textColor = 'text-almost-black';
	@Output() valueChanged = new EventEmitter<number>();
	@Input() showLimits = false;
	@Input() minLimitText: string;
	@Input() maxLimitText: string;
	disabled = false;

	onTouched = () => {};
	onChanged = (_: any) => {};

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}

	ngOnInit() {
		this.getFillWidth();
	}

	public getFillWidth(): number {
		if (!this.value || this.value < this.min) return 0;
		else if (this.value > this.max) return 100;
		return ((this.value - this.min) * 100) / (this.max - this.min);
	}

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange(value) {
		this.value = value;
		this.onChanged(this.value);
	}
}
