<app-modal-card [visible]="true" (close)="close.emit()">
	<form *ngIf="lenderForm" class="flex flex-col w-full" [formGroup]="lenderForm">
		<h3 class="mb-6">{{ "add-lender-modal.title" | translate: {lenderName: selectedLender } }}</h3>
		<div class="flex flex-wrap mb-2 sm:-mx-3">
			<div class="w-full sm:px-3">
				<app-input [label]="'lender.amount' | translate" name="amount" type="number" [suffix]="currency" pattern="[0-9]*" formControlName="amount"></app-input>
			</div>
			<div class="w-full sm:px-3">
				<app-input
					[label]="'lender.duration' | translate"
					name="duration"
					type="text"
					type="number"
					[suffix]="'input.suffix.months' | translate"
					pattern="[0-9]*"
					formControlName="duration"
					[helperText]="'input.duration.helper-text' | translate"></app-input>
			</div>
		</div>
		<app-button class="w-full text-center" type="primary" (action)="addLender()">{{ 'add-lender.confirm' | translate }}</app-button>
	</form>
</app-modal-card>
