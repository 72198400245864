import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { CountryISOCode, OrganizationsHttpService } from "app/api";
import { ToastService } from 'app/services/toast.service';
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

export function soleTraderAsyncValidator(organizationHttpService: OrganizationsHttpService, partnerId: string, toastService: ToastService): AsyncValidatorFn {
	return (control: AbstractControl): Observable<ValidationErrors | null> => {
		return organizationHttpService
			.checkOrganizationEligibleForActivation(
				partnerId,
				CountryISOCode.SE,
				control.value
			)
			.pipe(
				map((response) =>
					response.is_eligible ? null : { soleTrader: true }
				),
				catchError(err => {
					toastService.error('activate.toast.sole-trade-error');
					return of(null)}
				)
			);
	};
}
