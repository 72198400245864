<div *ngIf="!frameReady" class="flex flex-col items-center justify-center w-full h-full">
	<app-loader></app-loader>
</div>
<div id="payment-form" [style.visibility]="frameReady ? 'visible' : 'hidden'">
	<app-notification *ngIf="errorMessageKey" type="alert" [closable]="true" [showIcon]="false">
		<span [translate]="'checkout-card.error.' + errorMessageKey"></span>
	</app-notification>
	<label for="card-number" translate="checkout-card.card-number.label"></label>
	<div class="input-container card-number">
		<div class="icon-container">
			<app-svg-icon
				[width]="20"
				[height]="20"
				iconId="card"
				iconName="card.svg"
				iconPath="assets/shared/icons/checkout/card-icons/"
				[style.color]="!ckoState['card-number'].inputFocused && ckoState['card-number'].showDefaultErrorIcon ? '#C9501C' : '#acb2bf'"></app-svg-icon>
		</div>
		<div class="card-number-frame"></div>
		<div class="icon-container payment-method" [class.show]="showPaymentMethod">
			<app-svg-icon
				[width]="26"
				[height]="16"
				[style.display]="showPaymentMethod ? 'block' : 'none'"
				[iconId]="paymentMethod"
				[iconName]="paymentMethod?.toLowerCase() + '.svg'"
				iconPath="assets/shared/icons/checkout/card-icons/"></app-svg-icon>
		</div>
		<div class="icon-container">
			<app-svg-icon
				[width]="20"
				[height]="20"
				iconId="error"
				iconName="error.svg"
				iconPath="assets/shared/icons/checkout/card-icons/"
				[style.display]="!ckoState['card-number'].inputFocused && ckoState['card-number'].showErrorIcon ? 'block' : 'none'"
				[style.color]="'#C9501C'"></app-svg-icon>
		</div>
	</div>
	<div class="date-and-code">
		<div>
			<label for="expiry-date" translate="checkout-card.expiry-date.label"></label>
			<div class="input-container expiry-date">
				<div class="icon-container">
					<app-svg-icon
						[width]="20"
						[height]="20"
						iconId="exp-date"
						iconName="exp-date.svg"
						iconPath="assets/shared/icons/checkout/card-icons/"
						[style.color]="!ckoState['expiry-date'].inputFocused && ckoState['expiry-date'].showDefaultErrorIcon ? '#C9501C' : '#acb2bf'"></app-svg-icon>
				</div>
				<div class="expiry-date-frame"></div>
				<div class="icon-container">
					<app-svg-icon
						[width]="20"
						[height]="20"
						iconId="error"
						iconName="error.svg"
						iconPath="assets/shared/icons/checkout/card-icons/"
						[style.display]="!ckoState['expiry-date'].inputFocused && ckoState['expiry-date'].showErrorIcon ? 'block' : 'none'"
						[style.color]="'#C9501C'"></app-svg-icon>
				</div>
			</div>
		</div>

		<div>
			<label for="cvv" translate="checkout-card.cvv.label"></label>
			<div class="input-container cvv">
				<div class="icon-container">
					<app-svg-icon
						[width]="20"
						[height]="20"
						iconId="cvv"
						iconName="cvv.svg"
						iconPath="assets/shared/icons/checkout/card-icons/"
						[style.color]="!ckoState['cvv'].inputFocused && ckoState['cvv'].showDefaultErrorIcon ? '#C9501C' : '#acb2bf'"></app-svg-icon>
				</div>
				<div class="cvv-frame"></div>
				<div class="icon-container">
					<app-svg-icon
						[width]="20"
						[height]="20"
						iconId="error"
						iconName="error.svg"
						iconPath="assets/shared/icons/checkout/card-icons/"
						[style.display]="!ckoState['cvv'].inputFocused && ckoState['cvv'].showErrorIcon ? 'block' : 'none'"
						[style.color]="'#C9501C'"></app-svg-icon>
				</div>
			</div>
		</div>
	</div>
	<div>
		<span [@openClose] *ngIf="showInputError('card-number')" class="error-message" translate="checkout-card.error.card-number"></span>
		<!-- Error for when card number is valid, but it is not an accepted payment method (Visa card) -->
		<span [@openClose] *ngIf="showCardSchemaError" class="error-message" translate="checkout-card.error.card-scheme"></span>
		<!-- Error for when card number is valid, but it is not an accepted card bin -->
		<span [@openClose] *ngIf="showCardBinError" class="error-message">
			{{ 'checkout-card.error.card-bin' | translate : { partner: partnerKey.toUpperCase() } }}
		</span>
		<span [@openClose] *ngIf="showInputError('expiry-date')" class="error-message" translate="checkout-card.error.expiry-date"></span>
		<span [@openClose] *ngIf="showInputError('cvv')" class="error-message" translate="checkout-card.error.cvv"></span>
	</div>
	<div class="my-4">
		<app-button
			[disabled]="(!isCardValid || !isCardSchemaAccepted || submittingCard || !isCardBinAccepted) && !enableResubmitOnError"
			[loading]="submittingCard"
			(action)="submitCard()">
			{{ buttonText | translate }}
		</app-button>
	</div>
	<p class="success-payment-message">{{ cardToken }}</p>
</div>
