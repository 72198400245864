import { Injectable } from '@angular/core';
import {
	OnboardingSelectedBankAccountRequest,
	PartnerOnboardingHttpService,
	PartnerOnboardingSessionDto,
	WithdrawalRequestDto,
} from 'app/api';
import { lessThanOneHourAgo } from 'app/helpers/date.helper';
import { Observable, of, zip } from 'rxjs';
import { catchError, filter, map, mergeMap, retry, switchMap, take, tap } from 'rxjs/operators';
import { LoanService } from './loan.service';
import { OrganizationService } from './organization.service';
import { WhiteLabelClientSettingsService } from './white-label-client-settings.service';

export type LastSuccessfulStep =
	| 'home'
	| 'kyc'
	| 'purpose'
	| 'card-details'
	| 'bank-scraping'
	| 'payout-account'
	| 'invoice'
	| 'completed';

@Injectable()
export class PartnerOnboardingService {
	_sessionId: string;
	constructor(
		private loanService: LoanService,
		private organizationService: OrganizationService,
		private partnerOnboardingHttpService: PartnerOnboardingHttpService,
		private clientSettingsService: WhiteLabelClientSettingsService
	) {}

	get sessionId() {
		if (!this._sessionId) {
			throw new Error('Partner onboarding session id is not set');
		}
		return this._sessionId;
	}

	set sessionId(sessionId: string) {
		this._sessionId = sessionId;
	}

	createSession(lastSuccessfulStep: LastSuccessfulStep): Observable<PartnerOnboardingSessionDto> {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		return this.preparePartnerOnboardingSession(lastSuccessfulStep).pipe(
			mergeMap(partnerOnboardingSession =>
				this.partnerOnboardingHttpService.createPartnerOnboardingSession(
					organizationId,
					creditId,
					partnerOnboardingSession
				)
			),
			retry(2),
			tap(partnerOnboardingSession => (this.sessionId = partnerOnboardingSession.id))
		);
	}

	createPurchaseFinancingSession(
		lastSuccessfulStep: LastSuccessfulStep,
		offerId: string
	): Observable<PartnerOnboardingSessionDto> {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		return this.preparePartnerOnboardingSession(lastSuccessfulStep).pipe(
			mergeMap(partnerOnboardingSession =>
				this.partnerOnboardingHttpService.createPurchaseFinancingPartnerOnboardingSession(
					organizationId,
					creditId,
					offerId,
					partnerOnboardingSession
				)
			),
			retry(2),
			tap(partnerOnboardingSession => (this.sessionId = partnerOnboardingSession.id))
		);
	}

	private preparePartnerOnboardingSession(
		lastSuccessfulStep: LastSuccessfulStep
	): Observable<PartnerOnboardingSessionDto> {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		const request$ = this.loanService.createWithdrawalRequestRequest();
		const kyc$ = this.loanService.kyc$;
		const priceCalculationId$ = this.loanService.offer$.pipe(map(offer => offer.price_calculation_id));

		return zip(request$, kyc$, priceCalculationId$).pipe(
			take(1),
			map(([request, kyc, priceCalculationId]) => {
				const { ...rest } = request;
				return {
					kycDataRequest: kyc,
					last_successful_step: lastSuccessfulStep,
					price_calculation_id: priceCalculationId,
					...rest,
				} as PartnerOnboardingSessionDto;
			}),
			map(request => {
				const partnerOnboardingSession: PartnerOnboardingSessionDto = {
					...request,
					organization_id: organizationId,
					credit_id: creditId,
				};
				return partnerOnboardingSession;
			})
		);
	}

	updateSession(lastSuccessfulStep: LastSuccessfulStep): Observable<PartnerOnboardingSessionDto> {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		const request$ = this.loanService.createWithdrawalRequestRequest();
		const kyc$ = this.loanService.kyc$;
		const priceCalculationId$ = this.loanService.offer$.pipe(map(offer => offer.price_calculation_id));

		return zip(request$, kyc$, priceCalculationId$).pipe(
			take(1),
			map(([request, kyc, priceCalculationId]) => {
				const { ...rest } = request;
				return {
					kycDataRequest: kyc,
					last_successful_step: lastSuccessfulStep,
					price_calculation_id: priceCalculationId,
					...rest,
				} as PartnerOnboardingSessionDto;
			}),
			map(request => {
				const partnerOnboardingSession: PartnerOnboardingSessionDto = {
					...request,
					organization_id: organizationId,
					credit_id: creditId,
				};
				return partnerOnboardingSession;
			}),
			mergeMap(partnerOnboardingSession =>
				this.partnerOnboardingHttpService.updatePartnerOnboardingSession(
					organizationId,
					this.sessionId,
					partnerOnboardingSession
				)
			),
			retry(2)
		);
	}

	cancelActiveSession(): Observable<{}> {
		const organizationId = this.organizationService.getOrganizationId();
		return this.partnerOnboardingHttpService.deletePartnerOnboardingSession(organizationId, this.sessionId);
	}

	createWithdrawalRequestFromSession(organizationId: string, sessionId: string): Observable<WithdrawalRequestDto> {
		this.sessionId = sessionId;
		return this.partnerOnboardingHttpService.createWithdrawalRequestFromSession(organizationId, sessionId);
	}

	restoreLoanState(organizationId: string, sessionId: string): Observable<{}> {
		this.sessionId = sessionId;
		return this.partnerOnboardingHttpService
			.getPartnerOnboardingSession(organizationId, sessionId)
			.pipe(switchMap(session => this.loanService.restoreLoanFromPartnerOnboardingSession(session)));
	}

	//get only sessions that were closed on card-details step
	//and are less than one hour old
	restoreActiveOnboardingSession(): Observable<string> {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		return this.partnerOnboardingHttpService.getActivePartnerOnboardingSession(organizationId, creditId).pipe(
			catchError(error => {
				if (error.status === 404) {
					return of(null);
				}
			}),
			filter(activeSession => !!activeSession),
			filter(activeSession => lessThanOneHourAgo(activeSession.date_created)),
			filter(activeSession => activeSession.last_successful_step === 'purpose'),
			tap(activeSession => (this.sessionId = activeSession.id)),
			switchMap(activeSession => this.loanService.restoreLoanFromPartnerOnboardingSession(activeSession))
		);
	}

	psd2RedirectUrl() {
		const partnerSettings = this.clientSettingsService.getSettings();
		return window.location.origin + '/p/' + partnerSettings.key + '/bank-info';
	}

	getPayoutAccounts(): any {
		const organizationId = this.organizationService.getOrganizationId();
		return this.partnerOnboardingHttpService.getPartnerCustomerBankAccounts(this.sessionId, organizationId);
	}

	setPartnerSelectedBankAccountId(request: OnboardingSelectedBankAccountRequest) {
		const organizationId = this.organizationService.getOrganizationId();
		return this.partnerOnboardingHttpService.setPartnerSelectedBankAccountId(this.sessionId, organizationId, request);
	}
}
