import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function freeTextValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value == '') {
			return { required: true };
		}

		if (control.value.length < 30) {
			return { 'freetext.minlength': true };
		}

		return null;
	};
}
