export const swedbankPayVariables = {
	'font-higlight': 'Swedbank Sans',
	'font-header': 'Swedbank Sans',
	'font-body': 'Swedbank Sans',
	'primary-100': '#FBF2EA',
	'primary-200': '#FDC129',
	'primary-400': '#ffcb33',
	'primary-500': '#fdc129',
	'primary-700': '#e99b15',
	'secondary-100': '#FFF7E1',
	'secondary-200': '#FDC129',
	'secondary-400': '#ffcb33',
	'secondary-500': '#fdc129',
	'secondary-900': '#DF910B',

	'checkbox-100': '#E6E8E8',
	'checkbox-300': '#AAA',
	'checkbox-500': '#ffcb33',
	'radio-100': '#E6E8E8',
	'radio-500': '#333333',

	'rounded-box': '16px',
	'rounded-notification': '0px',
	'min-width': 'fit-content',
	'highlight-light': '#FBF2EA',
	'highlight-border': '#1E1E1E',
	'slider-color': '#FDC129',
	'slider-border-color': '#1A1A1A',

	//Box card styles
	'box-background': '#FBF2EA',
	'box-info': '#1A1A1A',
	'box-border': '#1E1E1E',

	//Box card styles - secondary
	'box-background-light': '#EEEEEE',
	'box-info-light': '#1A1A1A',
	'box-border-light': '#FAFAFA',

	separator: '#BDBDBD',

	'input-phone-prefix': '#E4E4E4',

	spinner: '#DF910B',

	highlighted: '#1A1A1A',
	title: '#1A1A1A',
	paragraph: '#1A1A1A',

	dark: '#4C2D2C',
	brand: '#F4C34E',

	'footer-background': '#FBF2EA',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#ffcb33',
	'button-color-hover': '#fdc129',
	'button-text': '#33333',
	'button-text-hover': '#333333',
	'rounded-button': '24px',
	'padding-button': '12px 24px',
	'border-button-color': '#ffcb33',

	// secondary button
	'button-color-secondary': 'transparent',
	'button-text-secondary': '#000000',
	'button-text-secondary-hover': '#000000',
	'rounded-button-secondary': '24px',
	'padding-button-secondary-outlined': '10px 24px',
	'button-border-secondary-outlined': '#ffcb33',
	'button-border-secondary-hover': '#ffcb33',
	// --------------------------------------- //
};
