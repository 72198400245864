import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OtherLoanRequest } from 'app/api';

@Component({
	selector: 'app-lender-list',
	templateUrl: './lender-list.component.html',
	styleUrls: ['./lender-list.component.scss'],
})
export class LenderListComponent {
	@Input() lenders: OtherLoanRequest[];
	@Output() removeLender = new EventEmitter<OtherLoanRequest>();
	@Input() currency: string;
}
