import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { FeatureFlagsService } from 'app/services/feature-flags.service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
	CONTRACT_KILL_SWITCH,
	GENERAL_KILL_SWITCH,
	ONBOARDING_KILL_SWITCH,
	PARTNER_PORTAL_KILL_SWITCH,
	WL_KILL_SWITCH,
} from 'app/app.contants';

@Injectable({
	providedIn: 'root',
})
export class UnavailableGuard implements CanActivate {
	constructor(private featureFlagService: FeatureFlagsService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const path = route.pathFromRoot[1]?.routeConfig?.path;
		// show kill switch page for the white label pages
		if (this.featureFlagService.isFlagEnabled(WL_KILL_SWITCH) && route.pathFromRoot[1]?.params?.partner) {
			this.router.navigate(['/unavailable']);
			return false;
		}

		// show the kill switch page for the login page and my froda pages
		if (this.featureFlagService.isFlagEnabled(GENERAL_KILL_SWITCH) && ['login', 'v2', 'getstarted'].includes(path)) {
			this.router.navigate(['/unavailable']);
			return false;
		}

		// show the kill switch page for the partner portal pages
		if (this.featureFlagService.isFlagEnabled(PARTNER_PORTAL_KILL_SWITCH) && path === 'partner-portal') {
			this.router.navigate(['/unavailable']);
			return false;
		}

		// show the kill switch page for the onboarding pages
		if (this.featureFlagService.isFlagEnabled(ONBOARDING_KILL_SWITCH) && path === 'apply') {
			this.router.navigate(['/unavailable']);
			return false;
		}

		// show the kill switch page for the contracts
		if (
			this.featureFlagService.isFlagEnabled(CONTRACT_KILL_SWITCH) &&
			environment.bootstrapper.appConfig.contractAppUrl.includes(location.origin)
		) {
			this.router.navigate(['/unavailable']);
			return false;
		}
		return true;
	}
}
