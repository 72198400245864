<div [ngClass]="bgColor">
	<div
		class="flex items-center py-3"
		*ngFor="let signatory of signatories; let i = index"
		[ngClass]="{
			'justify-between': signatories.length > 1,
			'justify-center': signatories.length <= 1,
			'border-b border-white/10': i !== signatories.length - 1
		}">
		<div *ngIf="signatories.length > 1" class="flex flex-col items-start">
			<div [ngClass]="titleTextColor">{{signatory.first_name}} {{signatory.last_name}}</div>
			<div class="text-sm font-medium" [ngClass]="subTextColor">{{signatory.phone}}</div>
		</div>
		<ng-container *ngIf="!usesSignicatSigning">
			<app-button *ngIf="!signatory.signed && signatory.is_logged_in_user" [type]="signButtonType" (action)="openLink(signatory.signing_url)">
				{{ 'wrstatus.signatory.sign' | translate}}
			</app-button>
			<a
				*ngIf="!signatory.signed && !signatory.is_logged_in_user"
				(click)="resendSMS(signatory)"
				class="text-sm underline hover:opacity-75"
				[ngClass]="{'pointer-events-none opacity-70' : smsSent.has(signatory.id), 'text-system-blue': !whiteLinkColor, 'text-medium-grey': whiteLinkColor }"
				translate="wrstatus.signatory.resend"></a>
		</ng-container>
		<ng-container *ngIf="usesSignicatSigning">
			<a
				*ngIf="!signatory.signed"
				(click)="createContractLink()"
				class="text-sm underline hover:opacity-75"
				[ngClass]="{'text-system-blue': !whiteLinkColor, 'text-medium-grey': whiteLinkColor }"
				translate="wrstatus.signatory.sign"></a>
		</ng-container>

		<app-svg-icon *ngIf="signatory.signed" class="text-system-green" [iconName]="'check-circle-bg.svg'" [iconId]="'check-circle-bg'"></app-svg-icon>
	</div>
</div>
