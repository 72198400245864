<button
	#buttonId
	type="button"
	[disabled]="disabled || loading"
	class="w-full text-base transition-colors duration-200 min-w-button focus:outline-none"
	[class.opacity-50]="disabled || loading"
	[class.cursor-not-allowed]="disabled || loading"
	[class.pointer-events-none]="disabled || loading"
	[class.button-loading]="loading"
	[ngClass]="{
		'p-button rounded-button': type === 'primary' || type === 'outline',
		'border-2': type === 'outline' || type === 'secondary',
		'bg-button-color text-button-text  hover:bg-button-color-hover hover:text-button-text-hover': type === 'primary',
		'text-button-text-secondary rounded-button-secondary bg-button-color-secondary border-button-border-secondary-outlined p-button-secondary-outlined hover:text-button-text-secondary-hover hover:border-button-border-secondary-hover': type === 'secondary',
		'px-2 py-[7px] rounded-full font-normal': type === 'small',
		'bg-almost-white text-black border-1/2 border-light-grey hover:bg-light-grey': type === 'small',
		'bg-transparent text-white border-white': type === 'outline' && outlineVersion === 'dark',
		'bg-transparent text-dark border-dark': type === 'outline' && outlineVersion === 'light'
	}">
	<span
		#contentWrapper
		class="flex items-center gap-2 button-content"
		[ngClass]="{
				'justify-center': !contentPosition || contentPosition === 'center',
				'justify-between flex-row-reverse': contentPosition === 'stretched' || contentPosition === 'center-right',
				'justify-start': contentPosition === 'left'
			}">
		<app-svg-icon *ngIf="iconId" [width]="iconWidth" [height]="iconHeight" [iconName]="iconId + '.svg'" [iconId]="iconId"></app-svg-icon>
		<span
			[ngClass]="{
		 'pr-2' : (type === 'small' && !!iconId),
		 'text-sm' : type === 'small',
		}">
			<ng-content></ng-content>
		</span>
		<!-- empty element, used for e.g. identity button layout - text center, icon right -->
		<div *ngIf="contentPosition === 'center-right'"></div>
	</span>

	<div *ngIf="loading" class="flex items-center justify-center"><app-loader></app-loader></div>
</button>
