import { ValidatorFn, Validators, FormGroup } from '@angular/forms';

export function atLeastOnePEPValidator(isAnyOwnerPEP:(() => boolean)): ValidatorFn {
    return (ubos: FormGroup): Record<string, boolean> | null => {
      const validator: ValidatorFn = Validators.requiredTrue;

      if(!isAnyOwnerPEP()) return null;
      const atLeastOnePEP =
      ubos &&
      ubos.controls &&
      Object.keys(ubos.controls).some(k => !validator((ubos.controls[k] as FormGroup).controls.is_pep));

      return atLeastOnePEP ? null : { atLeastOnePEP: true };
    };
}
