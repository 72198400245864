<app-modal-card *ngIf="visible" [visible]="visible" [fullsizeMobile]="true" [closable]="true" (close)="close()">
	<h2>{{ 'sni-selector.more.header' | translate }}</h2>
	<div class="flex flex-col py-4">
		<ng-container *ngIf="!industrySelected">
			<ng-container *ngFor="let option of sniOptions; let i = index">
				<div class="flex justify-between w-full cursor-pointer p-common border-b-1/2 text-medium-grey hover:text-black" (click)="onSelectIndustry(i)">
					<span class="text-black">{{ option.industry.translationKey | translate }}</span>
					<div class="flex">
						<span>{{ option.subIndustries.length }}</span>
						<app-svg-icon id="chevron-right" width="24" height="24" [iconName]="'chevron-right.svg'" [iconId]="'chevron-right'"></app-svg-icon>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="industrySelected">
			<div class="flex flex-col gap-4">
				<app-back-button (back)="back()" class="flex w-fit"></app-back-button>
				<form [formGroup]="selectSubIndustryForm" class="flex flex-col w-full gap-2">
					<ng-container *ngFor="let subIndustry of selectedIndustrySubIndustries; let i = index">
						<app-radio-item [value]="subIndustry" formControlName="selectedSubIndustry" [text]="subIndustry.translationKey | translate" />
					</ng-container>
				</form>
				<app-button (action)="addSNIOption()">{{ 'sni-selector.more.add' | translate }}</app-button>
			</div>
		</ng-container>
	</div>
</app-modal-card>
