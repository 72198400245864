<div *ngIf="ubosLoading" class="flex justify-center">
	<app-loader></app-loader>
</div>

<div *ngIf="!ubosLoading" class="flex flex-col">
	<app-notification *ngIf="ubos.errors?.atLeastOneUBO" type="error">
		{{ 'kyc.ubo.errors.at-least-one.ubo' |
		translate }}
	</app-notification>
	<div *ngFor="let ubo of ubos.controls; let i = index">
		<app-ubo-list-item
			(onRemoveClick)="onRemoveClick.emit(i)"
			[title]="ubo.value.first_name + ' ' + ubo.value.last_name"
			[description]="ubo.value.ssn?.toUpperCase()"></app-ubo-list-item>
	</div>

	<app-button class="my-4 text-center w-fit" type="small" iconId="plus-icon" (action)="onAddClick.emit()">
		{{ 'kyc.ubo-list.add-owner' | translate }}
	</app-button>
</div>
