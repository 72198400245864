export const lessThanTwoWeeksAgo = (date): boolean => {
	const todayDate = new Date();
	const requestDate = new Date(date);
	const daysBetween = (todayDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60 * 24);
	return daysBetween <= 14;
};

export const lessThanOneHourAgo = (date): boolean => {
	const todayDate = new Date();
	const requestDate = new Date(date);
	const hoursBetween = (todayDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60);
	return hoursBetween <= 1;
};

export const lessThanOneMonthAgo = (date): boolean => {
	const todayDate = new Date();
	const requestDate = new Date(date);
	const daysBetween = (todayDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60 * 24);
	return daysBetween <= 30;
};
