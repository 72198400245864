import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
	@Input() value;
	@Input() disabled = false;
	@Input() type: 'single' | 'list' = 'single';
	@Input() description = null;
	@Input() collapsible = false;

	onTouched = () => {};
	onChanged = (_: any) => {};

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}

	ngOnInit() {}

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange(value) {
		this.value = value;
		this.onChanged(this.value);
	}

	hasErrors() {
		return (this.control.dirty || this.control.touched) && this.control.errors;
	}

	isChecked() {
		return this.value ? true : false;
	}
}
