import { Component, Input, OnInit } from '@angular/core';
import { FeatureFlagsService, OrganizationService, WhiteLabelClientSettingsService } from '@services';
import {
	ContractsHttpService,
	SignatoryDto,
	SignatoryNotificationRequest,
	WithdrawalRequestSignatoryDto,
} from 'app/api';
import { AppConfig } from 'app/bootstrapping/appconfig';
import { NEW_CONTRACT_HANDLING_FLAG } from 'app/contract/contract.constants';
import { ToastService } from 'app/services/toast.service';

@Component({
	selector: 'app-signatory-list',
	templateUrl: './signatory-list.component.html',
	styleUrls: ['./signatory-list.component.scss'],
})
export class SignatoryListComponent implements OnInit {
	@Input() signatories!: SignatoryDto[] | WithdrawalRequestSignatoryDto[];
	@Input() pendingRequestId: string;
	@Input() bgColor?: string = 'bg-white';
	@Input() titleTextColor?: string = 'text-almost-black';
	@Input() whiteLinkColor?: boolean = false;
	@Input() subTextColor?: string = 'text-dark-grey';
	@Input() signButtonType?: string = 'primary';
	smsSent = new Map<string, boolean>();
	usesSignicatSigning = false;

	constructor(
		private contractService: ContractsHttpService,
		private organizationService: OrganizationService,
		private toastService: ToastService,
		private appConfig: AppConfig,
		private featureFlagsService: FeatureFlagsService,
		private clientSettingsService: WhiteLabelClientSettingsService
	) {}

	ngOnInit(): void {
		const partnerSettings = this.clientSettingsService.getSettings();
		if (partnerSettings) {
			this.usesSignicatSigning = partnerSettings.usesSignicatSigning;
		}
	}

	resendSMS(signatory) {
		const request: SignatoryNotificationRequest = {
			notification_type: 'text',
		};

		this.contractService.sendSignatoryNotificationBySignatoryScope(signatory.scope_id, request).subscribe(
			_ => {
				this.smsSent.set(signatory.id, true);
				this.toastService.success('home.toast.sms-success');
			},
			err => this.toastService.error('home.toast.sms-error')
		);
	}

	replaceURL(url) {
		return url.replace(this.appConfig.clientBaseUrl, this.appConfig.clientBaseUrlAlt);
	}

	openLink(url: string) {
		if (this.featureFlagsService.isFlagEnabled(NEW_CONTRACT_HANDLING_FLAG)) {
			const currentOrigin = window.location.origin;
			//adjust contract url if on my.froda.se (alt)
			if (currentOrigin === this.appConfig.clientBaseUrlAlt) {
				url = this.replaceURL(url);
			}
			window.location.href = url;
		} else {
			window.open(url, '_blank');
		}
	}

	createContractLink() {
		const organizationId = this.organizationService.getOrganizationId();
		this.contractService.getContractSigningUrl(organizationId, this.pendingRequestId).subscribe(url => {
			window.location.href = url.signing_url;
		});
	}
}
