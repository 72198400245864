import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { AppConfig } from '../../bootstrapping/appconfig';
import { AuthToken } from '../../models/auth-token';
import { BankIdAuthSession } from '../../models/bankid-auth-session';

import { User } from '../../models/user';
import { UserService } from 'app/services';
import { b64DecodeUnicode } from 'app/services/helper-functions';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	constructor (
		private http: HttpClient,
		private authService: AuthService,
		private appConfig: AppConfig,
		private userService: UserService
	) {}

	async exchangeAuthToken (token: string): Promise<void> {
		const response = await this.http
			.post<AuthToken>(this.appConfig.authBaseUrl + '/auth/token', { token: token })
			.toPromise();

		this.authService.setToken(response.token);

		const splits = response.token.split('.');

		const data = JSON.parse(b64DecodeUnicode(splits[1]));

		const user = new User();

		user.email = data.email;
		user.first_name = data.firstName;
		user.last_name = data.lastName;
		user.id = data.sub;

		this.userService.setUser(user);
	}

	

	async initBankIdLogin (ssn: string): Promise<BankIdAuthSession> {
		const response = await this.http
			.post<BankIdAuthSession>(this.appConfig.authBaseUrl + '/auth/bankid', { ssn: ssn })
			.toPromise();

		return response;
	}

	async getTicket (sessionId: string): Promise<BankIdAuthSession> {
		const response = await this.http
			.get<BankIdAuthSession>(this.appConfig.authBaseUrl + '/auth/bankid/' + sessionId)
			.toPromise();

		return response;
	}

	logout () {
		this.authService.removeToken();
		this.userService.clearUser();
	}
}
