<div
	(click)="optionClick.emit(listItem)"
	class="flex items-center justify-start px-5 py-4 text-sm cursor-pointer
		{{itemStyle.border}} {{itemStyle.itemBorderRadius}}
		{{isSelected ? itemStyle.itemSelectedTextColor : itemStyle.itemTextColor}}
		{{isSelected ? itemStyle.itemSelectedBgColor : itemStyle.itemBgColor}}
		{{isSelected ? itemStyle.itemBorderColor : itemStyle.itemSelectedBorderColor}}">
	<app-svg-icon
		*ngIf="!listItem.image && listItem.code"
		[iconName]="listItem.code +'.svg'"
		[iconPath]="imageSourceUrl"
		[iconId]="listItem.code"
		[width]="iconSize"
		[height]="iconSize"></app-svg-icon>

	<ng-content></ng-content>

	<!-- Used mainly for bank images - check with Nils if he can provide better svgs;
	The current ones contain png inside and can't be used with the svg tag -->
	<img *ngIf="listItem.image" [src]="imageSourceUrl + listItem.image +'.svg'" [width]="iconSize" [height]="iconSize" />

	<div class="flex flex-col items-start justify-start ml-4 text-sm cursor-pointer">
		<span class="text-base font-bold">{{ listItem.text | translate }}</span>
		<span *ngIf="showDescription" class="text-sm">
			{{ listItem.description | translate }}
		</span>
	</div>
</div>
