import { Component, Input, SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';

@Component({
	selector: 'loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnChanges {

	private start: number;

	public isLoading = false;
	@Input() loading: boolean;
	@Input() message: string;

	ngOnChanges(changes: SimpleChanges) {

		// Ensure that the loading screen is shown for a minimum of 1 sec
		if (changes.loading) {

			// Loading = true
			if (changes.loading.currentValue) {
				this.start = performance.now();
				this.isLoading = true;
			}

			// Loading = false
			if (!changes.loading.currentValue) {
				const end = performance.now();

				const diff = (end - this.start);

				if (diff > 1000) {
					// The loading took longer than 1 sec,
					// OK to exit loading screen
					this.isLoading = false;
				} else {

					// Loading took less than 1 sec,
					// wait the remaining time before exiting
					// loading screen
					setTimeout(() => {
						this.isLoading = false;
					}, 1000 - diff);
				}
			}
		}
	}
}
