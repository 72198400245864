import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';
import { QRCodeModule } from 'angularx-qrcode';
import { ApplicationPipesModule } from 'app/pipes/application-pipes.module';

import { TranslateModule } from '@ngx-translate/core';

import { PpcrService } from 'app/services/ppcr.service';
import { AddLenderComponent } from './components/add-lender/add-lender.component';
import { AddUboModalComponent } from './components/add-ubo-modal/add-ubo-modal.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BankidIdentifyModalComponent } from './components/bankid-identify-modal/bankid-identify-modal.component';
import { BankIDScrapeModalComponent } from './components/bankid-scrape-modal/bankid-scrape-modal.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CheckoutCardComponent } from './components/checkout-card/checkout-card.component';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ContactLinksComponent } from './components/contact-links/contact-links.component';
import { ContractsComponent } from './components/contracts/contracts.component';
import { CounterOfferInfoComponent } from './components/counter-offer-info/counter-offer-info.component';
import { InfoItemComponent } from './components/counter-offer-info/info-item/info-item.component';
import { DeclineCounterOfferModalComponent } from './components/decline-counter-offer-modal/decline-counter-offer-modal.component';
import { DevSsnModalComponent } from './components/dev-ssn-modal/dev-ssn-modal.component';
import { EventsCardComponent } from './components/events-card/events-card.component';
import { EventItemComponent } from './components/events-list/event-item/event-item.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { FaqItemComponent } from './components/faq-item/faq-item.component';
import { FetchCreditTermsModalComponent } from './components/fetch-credit-terms-modal/fetch-credit-terms-modal.component';
import { HeaderBannerComponent } from './components/header-banner/header-banner.component';
import { HighRiskCountriesComponent } from './components/high-risk-countries/high-risk-countries.component';
import { HomeFaqsComponent } from './components/home-faqs/home-faqs.component';
import { HomeReportsComponent } from './components/home-reports/home-reports.component';
import { HomeTransactionsComponent } from './components/home-transactions/home-transactions.component';
import { InputComponent } from './components/input/input.component';
import { KycComponent } from './components/kyc/kyc.component';
import { LenderListComponent } from './components/lender-list/lender-list.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoanPurposeListComponent } from './components/loan-purpose-list/loan-purpose-list.component';
import { LoanSummaryComponent } from './components/loan-summary/loan-summary.component';
import { ModalCardComponent } from './components/modal-card/modal-card.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RemoveModalComponent } from './components/remove-modal/remove-modal.component';
import { ReportItemComponent } from './components/reports/report-item/report-item.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SaveContactModalComponent } from './components/save-contact-modal/save-contact-modal.component';
import { ScrapingCallbackModalComponent } from './components/scraping-callback-modal/scraping-callback-modal.component';
import { SelectComponent } from './components/select/select.component';
import { SignatoriesComponent } from './components/signatories/signatories.component';
import { SignatoryListComponent } from './components/signatory-list/signatory-list.component';
import { SliderComponent } from './components/slider/slider.component';
import { SplashLayoutComponent } from './components/splash-layout/splash-layout.component';
import { SplashLoaderComponent } from './components/splash-loader/splash-loader.component';
import { StatusStepComponent } from './components/status-step/status-step.component';
import { SubmitLoanModalComponent } from './components/submit-loan-modal/submit-loan-modal.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ThreeDotsComponent } from './components/three-dots/three-dots.component';
import { ToastComponent } from './components/toast/toast.component';
import { TransactionItemComponent } from './components/transactions/transaction-item/transaction-item.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { UboListItemComponent } from './components/ubo-list-item/ubo-list-item.component';
import { UboListComponent } from './components/ubo-list/ubo-list.component';
import { EnterViewportNotifierDirective } from './directives/enter-viewport-notifier.directive';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { FeatureVariantDirective } from './directives/feature-variant.directive';
import { InputChangeDirective } from './directives/input-change.directive';
import { LoadingDotsDirective } from './directives/loading-dots.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { SmoothHeightDirective } from './directives/smooth-height.directive';
import { ToastDirective } from './directives/toast.directive';
import { ThemeDirective } from './theme/theme.directive';

import { UnavailablePageComponent } from 'app/unavailable-page/unavailable-page.component';

import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

import { ModuleFederationToolsModule } from '@angular-architects/module-federation-tools';
import { MyHomeStatusCardComponent } from 'app/my-froda/shared/my-home-status-card/my-home-status-card.component';
import { ApplicationSummaryComponent } from './components/application-summary/application-summary.component';
import { AwaitingPayoutStatusCardComponent } from './components/awaiting-payout-status-card/awaiting-payout-status-card.component';
import { BankInfoComponent } from './components/bank-info/bank-info.component';
import { CustomerSatisfactionComponent } from './components/customer-satisfaction/customer-satisfaction.component';
import { EnableBankingErrorModalComponent } from './components/enable-banking-error-modal/enable-banking-error-modal.component';
import { EnableBankingLoaderComponent } from './components/enable-banking-loader/enable-banking-loader.component';
import { EnableBankingSuccessModalComponent } from './components/enable-banking-success-modal/enable-banking-success-modal.component';
import { FullscreenModalCardComponent } from './components/fullscreen-modal-card/fullscreen-modal-card.component';
import { GridIconItemComponent } from './components/grid-icon-item/grid-icon-item.component';
import { HelixComponentComponent } from './components/helix-component/helix-component.component';
import { InfoBannerComponent } from './components/info-banner/info-banner.component';
import { LoanPurposeDialogComponent } from './components/loan-purpose-dialog/loan-purpose-dialog.component';
import { LoginFailureMessageComponent } from './components/login-failure-message/login-failure-message.component';
import { OnboardingStepperComponent } from './components/onboarding-stepper/onboarding-stepper.component';
import { PendingApprovedStatusCardComponent } from './components/pending-approved-status-card/pending-approved-status-card.component';
import { RadioItemComponent } from './components/radio-item/radio-item.component';
import { RejectedStatusCardComponent } from './components/rejected-status-card/rejected-status-card.component';
import { SearchBankItemComponent } from './components/search-bank-item/search-bank-item.component';
import { SniOptionsModalComponent } from './components/sni-selector/sni-options-modal/sni-options-modal.component';
import { SniSelectorComponent } from './components/sni-selector/sni-selector.component';

export function playerFactory(): unknown {
	return import('lottie-web');
}

const sharedComponents = [
	BackButtonComponent,
	InputComponent,
	SliderComponent,
	ButtonComponent,
	SelectComponent,
	ProgressBarComponent,
	HeaderBannerComponent,
	CheckboxComponent,
	BankIDScrapeModalComponent,
	BankidIdentifyModalComponent,
	LoaderComponent,
	LoadingComponent,
	SplashLayoutComponent,
	NotificationComponent,
	CompanySelectorComponent,
	BadgeComponent,
	ToastComponent,
	ThreeDotsComponent,
	LoadingModalComponent,
	RemoveModalComponent,
	ConfirmationModalComponent,
	ScrapingCallbackModalComponent,
	AddUboModalComponent,
	ModalCardComponent,
	FetchCreditTermsModalComponent,
	ContactCardComponent,
	LoanSummaryComponent,
	KycComponent,
	SignatoriesComponent,
	HomeTransactionsComponent,
	HomeReportsComponent,
	TransactionsComponent,
	ReportsComponent,
	ContractsComponent,
	ReportItemComponent,
	TransactionItemComponent,
	SignatoryListComponent,
	SubmitLoanModalComponent,
	HomeFaqsComponent,
	ContactLinksComponent,
	TextareaComponent,
	HighRiskCountriesComponent,
	EventsCardComponent,
	EventsListComponent,
	EventItemComponent,
	DevSsnModalComponent,
	SaveContactModalComponent,
	LoanPurposeListComponent,
	ListItemComponent,
	SvgIconComponent,
	UboListComponent,
	UboListItemComponent,
	StatusStepComponent,
	CheckoutCardComponent,
	FaqItemComponent,
	CounterOfferInfoComponent,
	AddLenderComponent,
	LenderListComponent,
	SplashLoaderComponent,
	InfoBannerComponent,
	UnavailablePageComponent,
	LoanPurposeDialogComponent,
	LoginFailureMessageComponent,
	SearchBankItemComponent,
	GridIconItemComponent,
	EnableBankingLoaderComponent,
	EnableBankingSuccessModalComponent,
	FullscreenModalCardComponent,
	EnableBankingErrorModalComponent,
	OnboardingStepperComponent,
	BankInfoComponent,
	SniSelectorComponent,
	SniOptionsModalComponent,
	PendingApprovedStatusCardComponent,
	ApplicationSummaryComponent,
	RejectedStatusCardComponent,
	AwaitingPayoutStatusCardComponent,
	MyHomeStatusCardComponent,
	HelixComponentComponent,
	RadioItemComponent,
];

const directives = [
	SmoothHeightDirective,
	ToastDirective,
	LoadingDotsDirective,
	FeatureFlagDirective,
	FeatureVariantDirective,
	ThemeDirective,
	PhoneMaskDirective,
	InputChangeDirective,
	EnterViewportNotifierDirective,
	InfoItemComponent,
	DeclineCounterOfferModalComponent,
	CustomerSatisfactionComponent,
];

@NgModule({
	imports: [
		ApplicationPipesModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		QRCodeModule,
		Angulartics2Module,
		LottieComponent,
		ModuleFederationToolsModule,
	],
	declarations: [...sharedComponents, ...directives],
	exports: [
		...sharedComponents,
		...directives,
		FormsModule,
		ReactiveFormsModule,
		QRCodeModule,
		TranslateModule,
		ApplicationPipesModule,
		Angulartics2Module,
	],
	providers: [PpcrService, provideLottieOptions({ player: () => import('lottie-web') })],
})
export class SharedModule {}
