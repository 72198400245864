<div
	class="flex flex-col w-full max-w-6xl p-6 mx-auto rounded-2xl lg:shadow-md"
	[ngClass]="{
    'bg-system-yellow' : type === StatusCardType.Alert,
    'bg-light-grey text-black': type === StatusCardType.Info,
		'bg-system-red text-white': type === StatusCardType.Error
	}">
	<div class="relative flex w-full">
		<div>
			<ng-content select="[title]"></ng-content>
		</div>
		<div *ngIf="closable" class="pl-3 ml-auto">
			<div class="-mx-1.5 -my-1.5">
				<button
					(click)="close.emit()"
					class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 absolute -right-2 -top-2"
					[ngClass]="{
              'bg-system-yellow hover:brightness-95 focus:ring-offset-system-yellow focus:ring-alert-600' : type === StatusCardType.Alert,
							'bg-light-grey hover:bg-medium-grey text-almost-black': type === StatusCardType.Info}">
					<span class="sr-only">Dismiss</span>
					<app-svg-icon [iconName]="'close.svg'" [iconId]="'close'"></app-svg-icon>
				</button>
			</div>
		</div>
		<div *ngIf="collapsable" class="pl-20 ml-auto">
			<div class="-mx-1.5 -my-1.5">
				<button
					(click)="collapsed = !collapsed"
					class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 absolute -right-2 -top-1"
					[ngClass]="{
              'bg-system-yellow hover:brightness-90 focus:ring-offset-system-yellow' : type === StatusCardType.Alert,
							'bg-light-grey hover:bg-medium-grey text-almost-black': type === StatusCardType.Info}">
					<span class="sr-only">{{ collapsed ? ('home.status-card.action.read-more' | translate) : ('home.status-card.action.close' | translate) }}</span>
					<div class="font-bold">{{ collapsed ? ('home.status-card.action.read-more' | translate) : ('home.status-card.action.close' | translate) }}</div>
					<!-- <app-svg-icon [iconName]="'close.svg'" [iconId]="'close'"></app-svg-icon> -->
				</button>
			</div>
		</div>
	</div>

	<ng-content *ngIf="!collapsable || (collapsable && !collapsed)" select="[content]"></ng-content>

	<div
		class="font-bold tracking-wide"
		[ngClass]="{
			'hover:brightness-90' : type === StatusCardType.Alert,
			'text-black hover:brightness-90': type === StatusCardType.Info,
			'text-white hover:brightness-90': type === StatusCardType.Error
		}">
		<ng-content select="a"></ng-content>
	</div>
</div>
