import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CUSTOMIZED_LENDING_LIMIT } from 'app/app.contants';
import { AppConfig } from 'app/bootstrapping/appconfig';
import { environment } from 'environments/environment';
import { from, Observable } from 'rxjs';
import { IMutableContext, IVariant, UnleashClient } from 'unleash-proxy-client';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagsService {
	unleash: UnleashClient;

	constructor(private appConfig: AppConfig, public httpClient: HttpClient, private localStorage: LocalStorageService) {}

	init(): Promise<void> {
		this.unleash = new UnleashClient({
			url: this.appConfig.unleashBaseUrl,
			clientKey: this.appConfig.unleashClientKey,
			appName: 'web-customer',
			environment: environment.production ? 'production' : 'development',
		});

		return this.unleash.start();
	}

	updateContext(context: IMutableContext): Observable<any> {
		return from(this.unleash.updateContext(context));
	}

	getContext(): IMutableContext {
		return this.unleash.getContext();
	}

	flagsLoaded(): boolean {
		return this.unleash.getAllToggles().length > 0;
	}

	isFlagLoaded(flagName: string): boolean {
		return this.unleash.getAllToggles().some(flag => flag.name === flagName);
	}

	isFlagEnabled(flagName: string): boolean {
		return this.unleash.isEnabled(flagName);
	}

	getVariant(flagName: string): IVariant {
		return this.unleash.getVariant(flagName);
	}

	getTranslatedError(flagName: string): string {
		const error = this.unleash.getVariant(flagName);
		return JSON.parse(error.payload.value)[this.localStorage.get('chosenLocale')];
	}

	getCustomizedLendingLimits() {
		const limits = this.unleash.getVariant(CUSTOMIZED_LENDING_LIMIT);
		return JSON.parse(limits.payload.value);
	}
}
