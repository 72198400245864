import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CounterOfferDecision, CreditProductHttpService, MoneyDto, WithdrawalRequestDto } from 'app/api';
import { MoneyCalculatorService, OrganizationService, ToastService } from 'app/services';
import { BaseUrlService } from 'app/services/base-url.service';
import { AuthService } from 'app/shared/auth';

@Component({
	selector: 'app-counter-offer-info',
	templateUrl: './counter-offer-info.component.html',
	styleUrls: ['./counter-offer-info.component.scss'],
})
export class CounterOfferInfoComponent implements OnInit {
	@Input() counterOfferWR: WithdrawalRequestDto;
	organisationId: string;
	declineLoading = false;
	approveLoading = false;
	showErrorMessage = false;
	newLoanAmount: MoneyDto;
	declineOfferModal = false;
	isBackofficeUser: boolean;

	constructor(
		private creditProductService: CreditProductHttpService,
		private organizationService: OrganizationService,
		private router: Router,
		private route: ActivatedRoute,
		private toastService: ToastService,
		private moneyCalculator: MoneyCalculatorService,
		private baseUrlService: BaseUrlService,
		private authService: AuthService
	) {
		this.isBackofficeUser = this.authService.isBackOfficeUser();
	}

	get hasPreviousFixedLoan(): boolean {
		return this.counterOfferWR?.current_principal_due.amount > 0;
	}

	get hasPreviousFlexLoan(): boolean {
		return (
			this.counterOfferWR?.current_principal_due.amount === 0 && this.counterOfferWR?.current_interest_due.amount > 0
		);
	}

	get isFixedLoan(): boolean {
		return !this.counterOfferWR?.amortization_free;
	}

	ngOnInit(): void {
		this.organisationId = this.organizationService.getOrganizationId();
		this.newLoanAmount = this.moneyCalculator.subtract(
			this.counterOfferWR.new_principal_due,
			this.counterOfferWR.current_principal_due
		);
	}

	declineOffer(reject_reason: string | null) {
		this.declineLoading = true;
		this.creditProductService
			.setCounterOfferDecision(this.organisationId, this.counterOfferWR.id, {
				decision: CounterOfferDecision.Rejected,
				reject_reason,
			})
			.subscribe(
				() => {
					this.declineLoading = false;
					this.toastService.success('toast.decline-offer-success');
					this.router.navigate(['../home'], { relativeTo: this.route });
				},
				err => {
					this.declineLoading = false;
					this.declineOfferModal = false;
					this.showErrorMessage = true;
				}
			);
	}

	approve() {
		this.showErrorMessage = false;
		this.approveLoading = true;
		const signingUrl = this.counterOfferWR.signatories.find(signatory => signatory.is_logged_in_user).signing_url;
		if (signingUrl) {
			this.creditProductService
				.setCounterOfferDecision(this.organisationId, this.counterOfferWR.id, {
					decision: CounterOfferDecision.Accepted,
				})
				.subscribe(
					() => {
						this.approveLoading = false;
						const returnUrl = this.constructReturnUrl();
						window.location.href =
							signingUrl +
							this.baseUrlService.determineQuerySeparator(signingUrl) +
							'returnUrl=' +
							encodeURIComponent(returnUrl);
					},
					err => {
						this.approveLoading = false;
						this.declineOfferModal = false;
						this.showErrorMessage = true;
					}
				);
		} else {
			this.showErrorMessage = true;
			this.approveLoading = false;
			throw new Error('No signing url found');
		}
	}

	private constructReturnUrl(): string {
		const newLoanAmount = this.newLoanAmount;
		const returnUrl =
			this.baseUrlService.getClientBaseUrl() + '/v2/counter-offer-confirmation?amount=' + newLoanAmount.amount;
		if (this.counterOfferWR?.signatories?.length === 1) {
			return returnUrl + '&soleSignatory=true';
		}
		return returnUrl;
	}
}
