<form class="flex flex-col max-w-2xl mx-auto" [formGroup]="purposeForm">
	<div class="flex flex-col justify-between flex-1 bg-white sm:rounded-box">
		<div>
			<div class="flex flex-col">
				<div *ngFor="let purposeOption of (purposes$ | async); let i = index" class="flex flex-col">
					<app-radio-item formControlName="purpose" [value]="purposeOption" [collapsible]="true" (click)="handleOptionClick(purposeOption)" [text]="purposeOption.text | translate">
						<div body>
							<div class="text-sm text-black-60">{{purposeOption.description | translate}}</div>
							<div *ngIf="purposeOption === purposeCtrl?.value" class="mt-4">
								<div formArrayName="answers" *ngFor="let answer of answersCtrl?.controls; let i = index">
									<app-input
										[label]="('purpose.question.' + purposeCtrl.value.questions[i].code) | translate"
										[placeholder]="('purpose.' + purposeCtrl.value.questions[i].code) + '.placeholder' | translate"
										[type]="purposeCtrl.value.questions[i].code === 'credit-settlement-how-much' ? 'number' : 'text'"
										[suffix]="purposeCtrl.value.questions[i].code === 'credit-settlement-how-much' ? currencySuffix : ''"
										[name]="i"
										[formControlName]="i"></app-input>
								</div>
							</div>
						</div>
					</app-radio-item>
				</div>
			</div>
		</div>

		<div *ngIf="purposeCtrl?.value && showSubmitButton" class="mt-12 -mb-4">
			<app-button class="self-center w-full max-w-lg mt-2" (click)="buttonClicked()" [loading]="loading">
				{{ 'onboarding.partner-portal.create-session.send-application' | translate }}
			</app-button>
			<div class="mt-4">
				<app-notification *ngIf="hasErrors" type="error">
					{{ 'onboarding.partner-portal.prefill-session.has-errors' | translate }}
				</app-notification>
			</div>
		</div>
	</div>
</form>
