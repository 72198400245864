<div class="w-full overflow-hidden border-b border-light-grey" (click)="toggle()">
	<div class="flex items-center justify-between py-4 pl-4 pr-2 cursor-pointer ">
		<p
			class="transition-colors duration-300 translate"
			[ngClass]="open ? 'text-button-color' : 'text-almost-black'"
			[innerHTML]="question ? question : ('faq.' + key + '.title' | translate) | simpleMarkdown: { '*': 'link-underline' }"></p>
		<app-svg-icon [@rotate180deg]="open" [ngClass]="open ? 'text-button-color' : 'text-almost-black'" [iconName]="'chevron-down.svg'" [iconId]="'chevron-down'"></app-svg-icon>
	</div>
	<p
		*ngIf="open"
		[@openClose]
		class="pb-4 pl-4 pr-2 sm:bg-transparent sm:shadow-none "
		[innerHTML]="answer ? answer : ('faq.' + key + '.text' | translate) | simpleMarkdown: { '*': 'link-underline' }"></p>
</div>
