<div (click)="optionClick.emit(listItem)" class="flex flex-row items-center justify-between h-[72px] border-b border-light-grey cursor-pointer hover:border-system-blue">
	<div *ngIf="listItem.logo" class="flex flex-col justify-center max-w-[120px] max-h-6">
		<img [src]="listItem.logo" class=" max-w-[120px] max-h-6" cover />
	</div>

	<span *ngIf="!listItem.logo" class="font-medium text-center whitespace-normal text-almost-black">{{ listItem.financial_institution_name }}</span>

	<div class="flex flex-row items-center justify-center gap-1">
		<div class="px-3 py-2 bg-almost-white rounded-[7px] text-sm">
			{{listItem.authentication_user_type === "BUSINESS" ? ('enable-banking.user-type.business' | translate) : ('enable-banking.user-type.private' | translate) }}
		</div>
		<app-svg-icon
			id="chevron-right"
			width="24"
			height="24"
			[iconName]="'chevron-right.svg'"
			[iconId]="'chevron-right'"
			class="text-light-grey hover:text-system-blue"></app-svg-icon>
	</div>
</div>
