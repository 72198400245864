import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EstimatedPaymentDto, PaymentsHttpService, TransactionDto } from 'app/api';
import { OrganizationService } from 'app/services';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
	selector: 'transactions',
	templateUrl: './transactions.component.html',
	styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnChanges {
	@Input() transactions: (TransactionDto | EstimatedPaymentDto)[];
	@Input() loading: boolean;
	@Input() canLoadMore: boolean;
	@Output() load = new EventEmitter<void>();
	nextPayment$: Observable<EstimatedPaymentDto>;

	constructor(private paymentService: PaymentsHttpService, private organizationService: OrganizationService) {}

	ngOnInit(): void {
		const organisationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		this.nextPayment$ = this.paymentService.getNextPaymentByCredit(organisationId, creditId).pipe(shareReplay(1));
		this.mapTransactions();
	}

	ngOnChanges() {
		this.mapTransactions();
	}

	mapTransactions() {
		if (!this.loading) {
			this.nextPayment$.subscribe(
				nextPayment => {
					if (!(this.transactions[0] as EstimatedPaymentDto)?.estimated_date) {
						this.transactions.unshift(nextPayment);
					}
					this.transactions = this.transactions?.map((item, index) => {
						return {
							...item,
							expanded: index === 0 ? true : false,
						};
					});
				},
				err => {
					this.transactions = this.transactions?.map((item, index) => {
						return {
							...item,
							expanded: index === 0 ? true : false,
						};
					});
				}
			);
		}
	}
}
