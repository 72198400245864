import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganizationEventDto } from 'app/api';

@Component({
	selector: 'app-events-list',
	templateUrl: './events-list.component.html',
	styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit {
	@Input() events: OrganizationEventDto[];
	@Input() loading: boolean;
	@Input() canLoadMore: boolean;
	@Output() load = new EventEmitter<void>();

	constructor() {}

	ngOnInit(): void {}
}
