import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeclineDataDto } from 'app/api';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-decline-counter-offer-modal',
	templateUrl: './decline-counter-offer-modal.component.html',
	styleUrls: ['./decline-counter-offer-modal.component.scss'],
})
export class DeclineCounterOfferModalComponent implements OnInit {
	@Input() visible = false;
	@Input() declineLoading = false;
	@Output() close = new EventEmitter<void>();
	@Output() decline = new EventEmitter<any>();
	declineReasons$: Observable<any[]>;
	selectedReason: { value: string; orderNumber: number };
	declineInfoForm: FormGroup;

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.initializeForm();
	}

	initializeForm() {
		this.declineInfoForm = this.formBuilder.group({
			reject_reason: [
				'',
				{
					validators: [Validators.required, Validators.minLength(5)],
					updateOn: 'blur',
				},
			],
		});
	}

	get rejectReason(): AbstractControl {
		return this.declineInfoForm.get('reject_reason');
	}

	declineOffer() {
		this.declineInfoForm.markAllAsTouched();
		if (this.declineInfoForm.valid) {
			this.decline.emit(this.rejectReason.value);
		}
	}
}
