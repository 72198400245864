<div class="flex flex-col items-center">
	<h1 class="text-xl">{{ 'company-selector.title' | translate }}</h1>
	<form class="w-full my-6" [formGroup]="form">
		<div *ngFor="let company of companies">
			<app-radio-item [value]="company.identifier || company.org_number" formControlName="companyOrg">
				<div label>
					<span>{{company.name || company.organization_name }}</span>
					<span class="inline-flex items-center ml-2 px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-light-grey text-almost-black">
						{{company.registration_number || company.org_number}}
					</span>
				</div>
			</app-radio-item>
		</div>
	</form>
	<app-button [loading]="loading" class="w-full mb-4 text-center" [disabled]="!selectedIdentifier" (action)="companySelected.emit(selectedIdentifier)">
		{{ 'company-selector.button' | translate }}
	</app-button>
</div>
