import { Injectable } from '@angular/core';
import { MoneyDto } from 'app/api';

@Injectable({
	providedIn: 'root',
})
export class MoneyCalculatorService {
	private numberOfDecimalsSupported = 2;
	private precision: number = Math.pow(10, this.numberOfDecimalsSupported);
	constructor() {}

	public add(left: MoneyDto, right: MoneyDto): MoneyDto {
		return this.calcResult(left, right, (l, r) => l + r);
	}

	public subtract(left: MoneyDto, right: MoneyDto): MoneyDto {
		return this.calcResult(left, right, (l, r) => l - r);
	}

	public multiply(left: MoneyDto, right: MoneyDto): MoneyDto {
		return this.calcResult(left, right, (l, r) => l * r);
	}

	public divide(left: MoneyDto, right: MoneyDto): MoneyDto {
		return this.calcResult(left, right, (l, r) => l + r);
	}

	private calcResult(left: MoneyDto, right: MoneyDto, numericalOperation: Function) {
		const leftAmount = left.amount;
		const rightAmount = right.amount;
		return {
			amount: parseFloat(numericalOperation(leftAmount, rightAmount).toFixed(this.numberOfDecimalsSupported)),
			currency: this.getCurrency(left, right),
		};
	}

	public getFloatingPointErrorSafeAmount(money: number): number {
		return money * this.precision;
	}

	public toFloatAmount(money: number): number {
		return money / this.precision;
	}

	private getCurrency(...money: MoneyDto[]): string {
		const expectedCurrency = money[0].currency;
		if (!expectedCurrency) throw new Error('CurrencyError: no currency was given');
		for (let i = 0; i < money.length; i++) {
			if (expectedCurrency !== money[i].currency) {
							throw new Error(
					'CurrencyError: tried to make money calculations but the currencies differed expected ' +
						expectedCurrency +
						' but got ' +
						(money[i] || money[i].currency ? 'null' : money[i].currency)
				);
			}
		}
		return expectedCurrency;
	}
}
