import { TranslateService } from '@ngx-translate/core';
import { OrganizationEventDto, PaymentIntervalDto, PaymentIntervalType } from 'app/api';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/sv';

dayjs.extend(relativeTime);

interface EventProps {
	event: OrganizationEventExtendedDto;
	translateService: TranslateService;
}

interface EventFields {
	label: string;
	value: string | number;
	formatNumber?: boolean;
}

interface OrganizationEventExtendedDto extends OrganizationEventDto {
	failedEvent: boolean;
}

const eventStatus = {
	request: 'Request',
	accepted: 'Accepted',
	approved: 'Approved',
	success: 'Succeeded',
	canceled: 'Canceled',
	failed: 'Failed',
	declined: 'Declined',
	approvedOld: 'ApprovedOld',
};

const failureCategory = {
	Canceled: {
		value: 'Canceled',
		translationKey: 'events.details.canceled',
	},
	Failed: {
		value: 'Failed',
		translationKey: 'events.details.failed',
	},
	Declined: {
		value: 'Declined',
		translationKey: 'events.details.declined',
	},
};

const getPaymentInterval = (translateService: TranslateService, paymentInterval?: PaymentIntervalDto) => {
	if (!paymentInterval) {
		return '';
	}
	const { type } = paymentInterval;

	if (type === PaymentIntervalType.Monthly) {
		return `/${translateService.instant('events.details.per-month')}`;
	}
	if (type === PaymentIntervalType.Weekly || type === PaymentIntervalType.WeeklySimple) {
		return `/${translateService.instant('events.details.per-week')}`;
	}
	if (type === PaymentIntervalType.Daily || type === PaymentIntervalType.Bankday) {
		return `/${translateService.instant('events.details.per-day')}`;
	}
};

const insertItem = (array: EventFields[], index: number, newItem: EventFields) => [
	...array.slice(0, index),
	newItem,
	...array.slice(index),
];

const getDurationKeyMonths = (duration: number) =>
	duration === 1 ? 'events.details.month.one' : 'events.details.month.other';

const getDurationKeyWeeks = (duration: number) =>
	duration === 1 ? 'events.details.week.one' : 'events.details.week.other';

const isEventRequest = (status: string) => status === eventStatus.request;

export const adjustDateFormat = (date: string, language: string) => {
	if (!date) {
		return '-';
	}

	const currentYear = dayjs().year();
	const eventYear = dayjs(date).year();

	if (currentYear !== eventYear) {
		return dayjs(date).format('DD MMM YYYY');
	}

	if (dayjs(date).isAfter(dayjs().subtract(3, 'days')) && !dayjs(date).isAfter(new Date())) {
		return dayjs(date).locale(language).fromNow();
	}

	return dayjs(date).locale(language).format('DD MMM');
};

const generateExtraPaymentFields = (event: OrganizationEventExtendedDto, translateService: TranslateService) => {
	const extraPaymentFields = [
		{
			label: 'events.details.cost',
			value: event.interest?.amount,
			formatNumber: true,
		},
		{
			label: 'events.details.date',
			value: adjustDateFormat(event.paymentRequestedDate, translateService.currentLang),
		},
	];
	if (event.failedEvent || isEventRequest(event.status)) {
		return [
			{
				label: 'events.details.amortization',
				value: event.amortization?.amount,
				formatNumber: true,
			},
			...extraPaymentFields,
		];
	}
	return extraPaymentFields;
};

const generatePaymentFields = (event: OrganizationEventExtendedDto, isRegularPayment?: boolean) => {
	if (event.failedEvent && isRegularPayment) {
		return [
			{
				label: 'events.details.amount',
				value: event.amount?.amount || 0,
				classes: 'text-almost-black',
				formatNumber: true,
			},
		];
	}
	return [
		{
			label: 'events.details.amortization',
			value: event.amortization?.amount || 0,
			classes: isRegularPayment ? 'text-almost-black' : '',
			formatNumber: true,
		},
		{
			label: 'events.details.interest',
			value: event.interest?.amount,
			classes: isRegularPayment ? 'text-almost-black' : '',
			formatNumber: true,
		},
	];
};

const generateIncreaseLoanFields = (event: OrganizationEventExtendedDto, translateService: TranslateService) => {
	// Different felds for flex and fixed loans
	const isFlexLoan = event.isAmortizationFree || event.paybackDuration === 0;

	const increaseLoanFields = [
		{
			label: isFlexLoan ? 'events.details.interest-rate' : 'events.details.cost',
			value: isFlexLoan ? `${event.interestRatePercentage}%` : event.cost?.amount,
			formatNumber: !isFlexLoan,
		},
		{
			label: 'events.details.total-loan',
			value: isFlexLoan ? event.amount?.amount : event.amount?.amount + event.cost?.amount,
			formatNumber: true,
		},
	];

	if (isEventRequest(event.status) || event.failedEvent) {
		return [
			{
				label: 'events.details.amount',
				value: event.amount?.amount,
				formatNumber: true,
			},
			...increaseLoanFields,
		];
	}
	return [
		...increaseLoanFields,
		{
			label:
				isEventRequest(event.status) || event.failedEvent
					? 'events.details.payback-duration'
					: 'events.details.duration',
			value: isFlexLoan
				? translateService.instant('events.details.non-amortizing')
				: `${event.paybackDuration} ${translateService.instant(getDurationKeyMonths(event.paybackDuration))}`,
		},
	];
};

// used for increase and decrease
// when decrease is accepted it has same details as increase
const generateChangePaymentFields = (event: OrganizationEventExtendedDto, translateService: TranslateService) => {
	const decreasePaymentFields = [
		{
			label: 'events.details.old-payment',
			value: event.oldPayment?.amount,
			additionalInfo: getPaymentInterval(translateService, event.paymentInterval),
			formatNumber: true,
		},
		{
			label: 'events.details.loan-paid-back-by',
			value: adjustDateFormat(event.loanPaidBackBy, translateService.currentLang),
		},
	];

	if (isEventRequest(event.status) || event.failedEvent) {
		return [
			{
				label: 'events.details.new-payment',
				value: event.newPayment?.amount,
				additionalInfo: getPaymentInterval(translateService, event.paymentInterval),
				formatNumber: true,
			},
			...decreasePaymentFields,
		];
	}

	const additionalField = {
		label: 'events.details.cost-difference',
		value: event.costDifference?.amount,
		formatNumber: true,
	};

	return insertItem(decreasePaymentFields, 1, additionalField);
};

const generateNewLoanFields = (event: OrganizationEventExtendedDto, translateService: TranslateService) => {
	// Different felds for flex and fixed loans
	const isFlexLoan = event.isAmortizationFree || event.paybackDuration === 0;

	const newLoanFields = [
		{
			label: isFlexLoan ? 'events.details.interest-rate' : 'events.details.cost',
			value: isFlexLoan ? `${event.interestRatePercentage}%` : event.cost?.amount,
			formatNumber: !isFlexLoan,
		},
		{
			label:
				isEventRequest(event.status) || event.failedEvent
					? 'events.details.payback-duration'
					: 'events.details.duration',
			value: isFlexLoan
				? translateService.instant('events.details.non-amortizing')
				: `${event.paybackDuration} ${translateService.instant(getDurationKeyMonths(event.paybackDuration))}`,
		},
	];

	return [
		{
			label: 'events.details.amount',
			value: event.amount?.amount,
			formatNumber: true,
		},
		...newLoanFields,
	];
};

const generateAmortizationPauseFields = (event: OrganizationEventExtendedDto, translateService: TranslateService) => {
	if (isEventRequest(event.status) || event.failedEvent) {
		return [
			{
				label: 'events.details.start',
				value: adjustDateFormat(event.start, translateService.currentLang),
			},
			{
				label: 'events.details.duration',
				value: `${event.pauseDurationInWeeks} ${translateService.instant(
					getDurationKeyWeeks(event.pauseDurationInWeeks)
				)}`,
			},
			{
				label: 'events.details.cost',
				value: event.cost?.amount,
				formatNumber: true,
			},
		];
	}

	return [
		{
			label: 'events.details.payment-during-pause',
			value: event.paymentDuringPause?.amount,
			formatNumber: true,
			additionalInfo: getPaymentInterval(translateService, event.paymentInterval),
		},
		{
			label: 'events.details.cost-of-pause',
			value: event.cost?.amount,
			formatNumber: true,
		},
		{
			label: 'events.details.end-of-pause',
			value: adjustDateFormat(event.end, translateService.currentLang),
		},
	];
};

const generateHeader = (
	event: OrganizationEventExtendedDto,
	translateService: TranslateService,
	value?: number | string
) => {
	if (event.failedEvent) {
		return {
			value: translateService.instant(failureCategory[event.status].translationKey),
		};
	}

	if (isEventRequest(event.status)) {
		return { value: translateService.instant('events.details.request') };
	}

	return { value, formatNumber: typeof value === 'number' };
};

export const generateStandardPayment = (props: EventProps) => {
	const { event, translateService } = props;
	const headerValue = event.amount?.amount || 0;
	const isRegularPayment = !event.isLastPlannedPayment;

	return {
		...event,
		fields: [
			{
				label: isRegularPayment ? 'events.details.regular-payment' : 'events.details.last-payment',
				classes: isRegularPayment ? 'text-black' : '',
				...generateHeader(event, translateService, headerValue),
			},
			{
				label: event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.amount',
				classes: isRegularPayment ? 'text-black' : '',
				hiddenOnCard: true,
				...generateHeader(event, translateService, headerValue),
			},
			...generatePaymentFields(event, isRegularPayment),
		],
	};
};

export const generateFinalPayment = (props: EventProps) => {
	const { event, translateService } = props;
	const headerValue = event.amount?.amount || (event.amortization?.amount || 0) + event.interest?.amount;

	return {
		...event,
		fields: [
			{
				label: 'events.details.final-payment',
				classes: !event.failedEvent ? 'text-secondary-400' : '',
				...generateHeader(event, translateService, headerValue),
			},
			{
				label: event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.amount',
				hiddenOnCard: true,
				...generateHeader(event, translateService, headerValue),
			},
			...generatePaymentFields(event),
		],
	};
};

export const generateExtraPayment = (props: EventProps) => {
	const { event, translateService } = props;
	const headerValue = event.amortization?.amount;

	return {
		...event,
		fields: [
			{
				label: 'events.details.extra-amortization',
				classes: !event.failedEvent ? 'text-secondary-400' : '',
				...generateHeader(event, translateService, headerValue),
			},
			{
				label:
					event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.amortization',
				hiddenOnCard: true,
				...generateHeader(event, translateService, headerValue),
			},
			...generateExtraPaymentFields(event, translateService),
		],
	};
};

export const generateIncreaseLoan = (props: EventProps) => {
	const { event, translateService } = props;
	const headerValue = event.amount?.amount;
	const isIncreaseAccepted = event.status === eventStatus.approved;

	return {
		...event,
		fields: [
			{
				label: isIncreaseAccepted ? 'events.details.increased-loan' : 'events.details.increase-loan',
				...generateHeader(event, translateService, headerValue),
			},
			{
				label: event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.amount',
				hiddenOnCard: true,
				...generateHeader(event, translateService, headerValue),
			},
			...generateIncreaseLoanFields(event, translateService),
		],
	};
};

export const generateDecreasePayment = (props: EventProps) => {
	const { event, translateService } = props;
	const isDecreaseAccepted = event.status === eventStatus.approved;
	const headerValue = event.newPayment?.amount;

	return {
		...event,
		fields: [
			{
				label: isDecreaseAccepted ? 'events.details.decreased-payment' : 'events.details.decrease-payment',
				...generateHeader(event, translateService, headerValue),
				additionalInfo: getPaymentInterval(translateService, event.paymentInterval),
			},
			{
				label:
					event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.new-payment',
				hiddenOnCard: true,
				additionalInfo: !event.failedEvent && getPaymentInterval(translateService, event.paymentInterval),
				...generateHeader(event, translateService, headerValue),
			},
			...generateChangePaymentFields(event, translateService),
		],
	};
};

export const generateIncreasePayment = (props: EventProps) => {
	const { event, translateService } = props;
	const isIncreaseAccepted = event.status === eventStatus.approved;

	return {
		...event,
		fields: [
			{
				label: isIncreaseAccepted ? 'events.details.increased-payment' : 'events.details.increase-payment',
				additionalInfo: getPaymentInterval(translateService, event.paymentInterval),
				...generateHeader(event, translateService, event.newPayment?.amount),
			},
			...generateChangePaymentFields(event, translateService),
		],
	};
};

export const generateNewLoan = (props: EventProps) => {
	const { event, translateService } = props;
	const headerValue = event.amount?.amount;

	const isLoanPayoutInitiated = event.status === eventStatus.approved;
	const isApprovedOldEvent = event.status === eventStatus.approvedOld;

	return {
		...event,
		fields: [
			{
				label: isApprovedOldEvent
					? 'events.details.new-loan'
					: isLoanPayoutInitiated
					? 'events.details.payout-initiated'
					: 'events.details.new-loan-application',
				...generateHeader(event, translateService, headerValue),
			},
			{
				label: event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.amount',
				hiddenOnCard: true,
				...generateHeader(event, translateService, headerValue),
			},
			...generateNewLoanFields(event, translateService),
		],
	};
};

export const generateAmortizationPause = (props: EventProps) => {
	const { event, translateService } = props;
	const headerValue = `${event.pauseDurationInWeeks} ${translateService.instant(
		getDurationKeyWeeks(event.pauseDurationInWeeks)
	)}`;

	return {
		...event,
		fields: [
			{
				label:
					event.status === eventStatus.approved
						? 'events.details.paused-amortization'
						: 'events.details.pause-amortization',
				classes: !event.failedEvent ? 'text-secondary-400' : '',
				...generateHeader(event, translateService, headerValue),
			},
			{
				label: event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.duration',
				hiddenOnCard: true,
				...generateHeader(event, translateService, headerValue),
			},
			...generateAmortizationPauseFields(event, translateService),
		],
	};
};

export const generateCounterOffer = (props: EventProps) => {
	const { event, translateService } = props;

	return {
		...event,
		fields: [
			{
				label: event.isTopup ? 'events.details.increase-loan' : 'events.details.new-loan-application',
				value: translateService.instant('events.details.updated'),
				classes: 'text-secondary-400',
			},
			{
				label: event.failedEvent || isEventRequest(event.status) ? 'events.details.status' : 'events.details.amount',
				hiddenOnCard: true,
				value: translateService.instant('events.details.updated'),
			},
			// Should have the same fields like a new loan
			...generateNewLoanFields(event, translateService),
		],
	};
};
