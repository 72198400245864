<app-fullscreen-modal-card>
	<div class="flex flex-col items-center justify-between h-full max-w-sm gap-common p-common xs:p-0">
		<div class="h-20"></div>
		<div class="flex flex-col items-center gap-4 text-center">
			<div class="flex items-center justify-center sm:bg-almost-white sm:rounded-full sm:w-16 sm:h-16">
				<app-svg-icon class="text-system-red" width="24" height="24" [iconName]="'alert.svg'" [iconId]="'alert'"></app-svg-icon>
			</div>
			<h2 translate="enable-banking.modals.error.header"></h2>
			<p translate="enable-banking.modals.error.body"></p>
		</div>
		<div *ngIf="allowSkipScraping" class="text-center">
			<p translate="enable-banking.modals.go-to-manual" class="pb-2"></p>
			<app-button type="secondary" (click)="toManual.emit()">{{ "enable-banking.modals.button.continue" | translate }}</app-button>
		</div>
		<div class="pb-20">
			<app-back-button text="enable-banking.modals.error.back" (back)="back.emit()"></app-back-button>
		</div>
	</div>
</app-fullscreen-modal-card>
