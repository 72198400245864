import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'months',
})
export class MonthsPipe implements PipeTransform {
	constructor(private translateService: TranslateService) {}

	transform(value: number): string {
		if (value === 1) return value + ' ' + this.translateService.instant('input.suffix.month');
		else if (value > 1) return value + ' ' + this.translateService.instant('input.suffix.months');

		return '-';
	}
}
