import { Injectable } from '@angular/core';
import { DEFAULT_APP_LANGUAGE } from 'app/app.contants';
import { LocalStorageService } from 'app/services';
import * as i18nIsoCountries from 'i18n-iso-countries';

// Static imports for JSON locales
import da from 'i18n-iso-countries/langs/da.json';
import de from 'i18n-iso-countries/langs/de.json';
import en from 'i18n-iso-countries/langs/en.json';
import fi from 'i18n-iso-countries/langs/fi.json';
import nb from 'i18n-iso-countries/langs/nb.json';
import sv from 'i18n-iso-countries/langs/sv.json';

@Injectable({
	providedIn: 'root',
})
export class CountryNameService {
	chosenLocale: string;

	constructor(private localStorage: LocalStorageService) {
		i18nIsoCountries.registerLocale(en);
		i18nIsoCountries.registerLocale(sv);
		i18nIsoCountries.registerLocale(da);
		i18nIsoCountries.registerLocale(fi);
		i18nIsoCountries.registerLocale(nb);
		i18nIsoCountries.registerLocale(de);
		this.chosenLocale = this.localStorage.get('chosenLocale') || DEFAULT_APP_LANGUAGE;
	}

	getNameByCode(countryCode: string) {
		return i18nIsoCountries.getName(countryCode, this.chosenLocale, { select: 'alias' });
	}
}
