<div class="max-w-lg mb-4">
	<input type="range" [min]="min" [max]="max" [step]="step" [ngModel]="value" (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}" [disabled]="disabled" />
	<div class="tw-slider-fill" [class.disabled]="disabled" [style.width.%]="getFillWidth()"></div>
	<ng-container *ngIf="showLimits">
		<div class="flex justify-between w-full pt-3 text-sm">
			<span [ngClass]="[textColor]">{{ minLimitText ? minLimitText : (min | number:'1.0-0') }}</span>
			<span [ngClass]="[textColor]">{{ maxLimitText ? maxLimitText: (max | number:'1.0-0') }}</span>
		</div>
	</ng-container>
</div>
