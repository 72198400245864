export const themes = {
	bambora: {
		'--font-header': 'Inter UI',
		'--font-body': 'Inter UI',
		'--color-primary': '#2D8C8C',
		'--color-secondary': '#58d2b5',
		'--color-primary-light': '#58d2b580',
		'--color-primary-dark': '#277c7c',
	},
};
